<template>
  <div
    class="wrapper"
    :class="{ 'videojs-wrapper': videoPlayerName === 'videojs', embed: embed }"
    @mouseover="playerHover = true"
    @mouseleave="playerHover = false"
  >
    <v-progress-linear
      :active="loading"
      absolute
      indeterminate
    ></v-progress-linear>
    <div
      v-if="embed && showBranding"
      :class="{ visible: showBranding }"
      class="embed-gradient branding"
    ></div>
    <div
      v-if="embed && showBranding"
      :class="{ visible: showBranding }"
      class="embed-show flex-column branding"
      style="display:flex;"
    >
      <router-link
        :to="
          isPublic
            ? { name: 'VideoPost', params: { slug: video.id } }
            : { name: 'Home' }
        "
        class="ml-auto px-1 embed-logo text-decoration-none white--text"
        target="_blank"
        @click.native="$refs.videoPlayer.pause()"
      >
        <div class="protected-by">
          {{ $tc('label.protected-by') | capitalize }}
        </div>
        <picture class="d-flex" style="pointer-events: none;margin-top: -3px;">
          <source
            sizes="150px"
            srcset="/images/logo-crowdbunker.webp 400w"
            type="image/webp"
          />
          <img
            alt="CrowdBunker"
            src="/images/logo-crowdbunker.png"
            style="height: 27px"
          />
        </picture>
      </router-link>
      <v-btn
        :to="{ name: 'Donation' }"
        class="ml-auto px-1"
        target="_blank"
        text
        x-small
      >
        {{ $t('button.make-a-donation') }}
        <v-icon right x-small>mdi-open-in-new</v-icon>
      </v-btn>
    </div>
    <div
      v-if="embed && isInternal && isPublic"
      :class="{ visible: showBranding }"
      class="embed-title align-center branding"
      style="display:flex;"
    >
      <router-link
        :tag="isPublic ? 'a' : 'div'"
        :to="
          isPublic
            ? { name: 'VideoPost', params: { slug: video.id } }
            : { name: 'Home' }
        "
        class="text-decoration-none white--text d-flex align-center"
        style="width: 100%;"
        target="_blank"
        @click.native="$refs.videoPlayer.pause()"
      >
        <channel-avatar
          :bg-color="
            video.channel.organization.avatarColor
              ? video.channel.organization.avatarColor
              : 'primary'
          "
          :channel-name="video.channel.organization.name"
          :size="35"
          :thumbnails="video.channel.organization.thumbnails"
          class="mr-2"
        ></channel-avatar>
        <div class="text-truncate">{{ video.title }}</div>
      </router-link>
    </div>
    <div
      v-if="video.price === 'paid' && !video.canWatchVideo"
      class="selling-block"
    >
      <v-img height="100%" width="100%" contain :src="thumbnail">
        <v-overlay absolute z-index="0"></v-overlay>
        <div class="fill-height d-flex justify-center align-center">
          <v-card
            v-if="!video.paid && !successfulPayment"
            max-width="350px"
            min-width="250px"
            color="secondary"
          >
            <v-card-title
              class="text-uppercase d-none d-sm-block success text-center"
              >Disponible <span v-if="presalePeriod">en précommande</span>
            </v-card-title>
            <v-card-text class="pb-0">
              <div v-if="presalePeriod" class="my-2 text-sm-body-1 white--text">
                Précommandez dès maintenant cette vidéo pour soutenir ses
                créateurs et soyez parmi les premiers à pouvoir la visionner le
                jour de sa sortie.
              </div>
              <div class="d-flex flex-column mt-2">
                <v-btn color="primary" @click="startPayment('purchase')"
                  >Acheter ({{ video.sellingPriceCents / 100 }}€)</v-btn
                >
                <v-btn
                  color="primary"
                  class="mt-3"
                  v-if="video.rentalPriceCents"
                  @click="startPayment('rental')"
                  >Louer 48h ({{ video.rentalPriceCents / 100 }}€)</v-btn
                >
                <v-btn
                  text
                  small
                  color="primary"
                  class="py-0"
                  @click="startPayment('voucher')"
                  >J'ai un code</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
          <v-card v-else max-width="350px" min-width="250px" color="secondary">
            <v-card-text>
              <div v-if="presalePeriod">
                <div class="success--text body-1">
                  Vous pourrez visionner cette vidéo dès sa sortie.
                </div>
                <div>
                  <span class="font-weight-bold">Date de disponibilité :</span>
                  {{ getReleaseDate() }}
                </div>
              </div>
              <div v-else class="d-flex justify-center">
                <v-btn color="primary" @click="$root.$emit('reload')"
                  >Voir la vidéo</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-img>
      <v-dialog
        v-model="paymentDialog"
        max-width="500"
        persistent
        :key="paymentDialogKey"
      >
        <payment
          v-if="post !== null"
          @close="closePaymentDialog"
          @successfulPayment="successfulPayment = true"
          :post="post"
          :type="paymentType"
          :key="paymentDialogKey"
        ></payment>
      </v-dialog>
    </div>
    <div
      v-if="video.visibility === 'membership' && !video.canWatchVideo"
      class="membership-block"
    >
      <v-img height="100%" width="100%" contain :src="thumbnail">
        <v-overlay absolute z-index="0"></v-overlay>
        <div class="fill-height d-flex justify-center align-center">
          <v-card
            v-if="!memberOrganization"
            max-width="350px"
            min-width="250px"
            color="secondary"
          >
            <v-card-title
              class="text-uppercase d-none d-sm-block success text-center"
              >Disponible
            </v-card-title>
            <v-card-text>
              <div class="d-flex flex-column mt-2">
                <v-btn color="primary" @click="becomeMember()">{{
                  $tc('button.become-member')
                }}</v-btn>
              </div>
            </v-card-text>
          </v-card>
          <v-card v-else max-width="350px" min-width="250px" color="secondary">
            <v-card-text>
              <div class="d-flex justify-center">
                <v-btn color="primary" @click="$root.$emit('reload')"
                  >Voir la vidéo</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-img>
    </div>
    <div v-else class="video-block">
      <div
        v-if="isWaitingLiveStart"
        class="loading-placeholder justify-end align-center flex-column d-flex secondary overflow-hidden"
        style="position: relative"
        :class="{ 'rounded-lg': $vuetify.breakpoint.smAndUp }"
      >
        <v-img
          v-if="thumbnail"
          :src="thumbnail"
          contain
          height="100%"
          style="position: absolute"
          width="100%"
        ></v-img>
        <div class="d-flex justify-center align-center waiting-block my-2">
          <v-icon color="primary" large class="mr-2">mdi-access-point</v-icon>
          <div>{{ $tc('live.waiting-live-start') + '...' }}</div>
        </div>
      </div>
      <div
        v-else-if="isLiveConnecting"
        class="loading-placeholder justify-end align-center flex-column secondary overflow-hidden"
        style="position: relative"
        :class="{ 'rounded-lg': $vuetify.breakpoint.smAndUp }"
      >
        <v-img
          v-if="thumbnail"
          :src="thumbnail"
          contain
          height="100%"
          style="position: absolute;opacity: 0.7;"
          width="100%"
        ></v-img>
        <div class="d-flex justify-center align-center waiting-block my-2">
          <div class="d-flex mr-2">
            <v-progress-circular
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-if="hasMediaError && !finished">
            <span v-if="video.isChild">{{
              $tc('live.resurrecting-live-start') + '...'
            }}</span>
            <span v-else>{{ $tc('live.waiting-live-start') + '...' }}</span>
          </div>
          <div v-else>{{ ($tc('live.connection') + '...') | capitalize }}</div>
        </div>
      </div>
      <component
        :is="videoPlayerComponent"
        v-if="
          isInternal ||
            !video.externallyPlayable ||
            ($store.state.isModernBrowser && !isIphone() && !embed)
        "
        :class="{ 'player-invisible': !playerCanBeShown }"
        ref="videoPlayer"
        :autoplay="mustAutoplay"
        :finished="finished"
        :hasMediaError.sync="hasMediaError"
        :isLiveActive="!!video.isLiveActive"
        :live="live"
        :mediaReady.sync="mediaReady"
        :muted="muted"
        :sources="sources"
        :stat-timeout.sync="statTimeout"
        :thumbnail="thumbnail"
        :video="video"
        :start-position="startPosition"
        :key="playerKey"
        :embed="embed"
        @controlHide="controlsVisible = false"
        @controlShow="controlsVisible = true"
        @ended="ended"
        @loading="loading = $event"
        @playerInitialized="playerInitialized = $event"
        @playerReady="playerReady = $event"
        @sendViewStat="sendViewStat"
        @updatePosition="lastVideoPosition = $event"
        @playing="onPlaying($event)"
      ></component>
      <component
        :is="'Youtube'"
        v-else-if="!isInternal && video.externallyPlayable"
        :externalId="video.externalId"
        :loading.sync="loading"
        :stat-timeout.sync="statTimeout"
        :embed="embed"
        @error="embedError"
        @sendViewStat="sendViewStat"
      ></component>
      <v-alert
        v-show="alertMessage.show"
        :dismissible="alertMessage.dismissible"
        :type="screenMessageType"
        class="video-alert text-caption text-md-body-1"
        :color="alertMessage.color"
        dense
      >
        <div v-html="alertMessage.message"></div>
      </v-alert>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/plugins/firebase'
import { doc, onSnapshot, getFirestore } from 'firebase/firestore'
import { requestService } from '@/services/request.service'
import Clappr from '@/components/player/Clappr'
import Youtube from '@/components/player/YouTube'
import axios from 'axios'
import ChannelAvatar from '@/components/ChannelAvatar'
// import VideoJs from '@/components/player/VideoJs'
// import Vime from '@/components/player/vime/Vime'
// import VideoJsNuevo from '@/components/player/VideoJsNuevo'
import Payment from '@/components/Payment'
// import Vidstack from '@/components/player/Vidstack.vue'
import bowser from 'bowser'

const db = getFirestore(firebaseApp)

export default {
  name: 'PlayerWrapper',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    Payment,
    // VideoJs,
    ChannelAvatar,
    Youtube,
    // Clappr,
    // Vidstack,
    // VideoJsNuevo,
    // Vime,
  },
  props: {
    video: Object,
    post: { type: Object, default: null },
    inPost: { type: Boolean, default: false },
    embed: { type: Boolean, default: false },
    muted: { type: Boolean, default: false },
    liveFinished: { type: Boolean, default: false },
    live: { type: Boolean, default: false },
    autoplay: { type: Boolean, default: true },
  },
  data() {
    return {
      player: null,
      playerReady: false,
      mediaReady: false,
      hasMediaError: false,
      retryTimeout: null,
      playerInitialized: false,
      // liveFinished: false,
      loading: false,
      statTimeout: null,
      updatePositionTimeout: null,
      firestoreUnsubscribe: null,
      isInternal: this.video.platform !== 'youtube',
      controlsVisible: true,
      lastVideoPosition: 0,
      screenMessage: '',
      screenMessageType: 'info',
      showScreenMessage: false,
      playing: false,
      paymentDialog: false,
      paymentType: 'purchase',
      successfulPayment: false,
      paymentDialogKey: 0,
      playerKey: 1,
      playerHover: false,
      lastIsLiveType: false,
      videoPlayerComponent: null,
      videoPlayerName: null,
      wasPlayed: false,
    }
  },
  computed: {
    hlsManifest() {
      return this.video.hlsManifest ? this.video.hlsManifest.url : ''
    },
    dashManifest() {
      return this.video.dashManifest ? this.video.dashManifest.url : ''
    },
    finished() {
      if (!this.video.liveStreamingDetails) {
        return false
      }
      return (
        this.liveFinished ||
        !!this.video.liveStreamingDetails.actualEndTime ||
        ['live_processing', 'ready'].includes(this.video.status)
      )
    },
    thumbnail() {
      if (typeof this.video.thumbnails === 'string') {
        return this.video.thumbnails
      }
      const thumbnail = this.video?.thumbnails?.slice().sort((a, b) => {
        return b.height - a.height
      })[0]
      return thumbnail ? thumbnail.url : ''
    },
    mustAutoplay() {
      return (
        this.autoplay ||
        (this.$route.query.autoplay !== undefined &&
          parseInt(this.$route.query.autoplay) === 1)
      )
    },
    sources() {
      const sources = [
        {
          src: this.hlsManifest,
          // src:
          //   'https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8',
          type: 'application/vnd.apple.mpegurl',
        },
      ]
      // if (this.dashManifest) {
      //   sources.unshift({
      //     src: this.dashManifest,
      //     type: 'application/dash+xml',
      //   })
      // }
      return sources
    },
    isPublic() {
      return this.video.visibility === 'public'
    },
    alertMessage() {
      if (this.post === null) {
        return ''
      }
      const message = this.screenMessage || this.post.alertMessage
      return {
        message: message,
        show:
          message &&
          (this.showScreenMessage ||
            this.video.status === 'live_created' ||
            (!this.video.isLiveType && this.post.alertMessage)),
        dismissible: !this.video.isLiveType,
        color: this.screenMessageType === 'info' ? 'primary' : undefined,
      }
    },
    startPosition() {
      if (
        this.video.isLiveActive ||
        (this.video.children && this.video.children.length) ||
        this.video.isChild
      ) {
        return undefined
      }
      if (this.$route.query.t) {
        return parseInt(this.$route.query.t)
      } else if (
        this.video.lastStop &&
        this.video.lastStop <= this.video.duration - 5 &&
        this.video.lastStop > 5
      ) {
        return this.video.lastStop
      }
      return 0
    },
    presalePeriod() {
      return (
        this.video.price === 'paid' &&
        this.video.presaleMode &&
        (typeof this.video.releaseDate !== 'string' ||
          new Date() < new Date(this.video.releaseDate))
      )
    },
    showBranding() {
      return (this.playerHover || this.controlsVisible) && !this.wasPlayed
    },
    isWaitingLiveStart() {
      return (
        this.live &&
        !this.mediaReady &&
        !this.video.isLiveActive &&
        !this.loading &&
        !this.finished
      )
    },
    isLiveConnecting() {
      return (
        this.live &&
        ((!this.mediaReady && !this.playerReady) || this.hasMediaError)
      )
    },
    playerCanBeShown() {
      return !this.isWaitingLiveStart && !this.isLiveConnecting
    },
    memberOrganization() {
      return this.$store.state.account.memberOrganizations.find(
        elt => elt.organizationUid === this.post.channel.organization.uid,
      )
    },
  },
  methods: {
    loadVideoPlayer() {
      const storedPlayer = localStorage.getItem('videoPlayer')

      if (storedPlayer && this.isInternal) {
        this.loadPlayer(storedPlayer)
      }
      // const oldIsModernBrowser = this.$store.state.isModernBrowser
      //
      // const browser = bowser.getParser(window.navigator.userAgent)
      // this.$store.state.isModernBrowser = browser.satisfies({
      //   chrome: '>94',
      //   firefox: '>94',
      //   safari: '>16',
      //   edge: '>94',
      // })
      // if (this.$store.state.isModernBrowser === oldIsModernBrowser) {
      //   return
      // }
      // console.log(
      //   'new isModernBrowser',
      //   this.$store.state.isModernBrowser,
      //   oldIsModernBrowser,
      // )

      if (this.$store.state.isModernBrowser) {
        this.loadVidstackPlayer()
      } else {
        this.loadVideoJsPlayer()
      }
    },
    loadPlayer(player) {
      switch (player) {
        case 'vidstack':
          this.loadVidstackPlayer()
          break
        case 'videojs':
          this.loadVideoJsPlayer()
          break
        default:
          this.loadVidstackPlayer()
      }
    },
    loadVidstackPlayer() {
      import('@/components/player/Vidstack.vue').then(module => {
        this.videoPlayerComponent = module.default
        this.videoPlayerName = 'vidstack'
        localStorage.setItem('videoPlayer', 'vidstack')
      })
    },
    loadVideoJsPlayer() {
      import('@/components/player/VideoJs.vue').then(module => {
        this.videoPlayerComponent = module.default
        this.videoPlayerName = 'videojs'
        localStorage.setItem('videoPlayer', 'videojs')
      })
    },
    isIphone() {
      return /iPhone/.test(window.navigator.userAgent)
    },
    initPlayer() {
      // console.log('initPlayer')
      // if (
      //   this.video.canWatchVideo &&
      //   this.video.platform === 'internal' &&
      //   this.$refs.videoPlayer !== undefined
      // ) {
      //   this.$refs.videoPlayer.initPlayer()
      // }
      // this.playerKey++
    },
    destroy(soft = false) {
      if (
        this.video &&
        this.video.platform === 'internal' &&
        this.$refs.videoPlayer !== undefined
      ) {
        this.$refs.videoPlayer.destroy()
      }
      clearTimeout(this.statTimeout)
      if (!soft && typeof this.firestoreUnsubscribe === 'function') {
        this.firestoreUnsubscribe()
      }
      this.removeEventListeners()
      this.sendUpdatePosition()
      this.mediaReady = false

      if (this.videoPlayerName === 'videojs') {
        this.playerKey++
      }
    },
    initEventListeners() {
      document.addEventListener('keypress', this.eventKeyPress)
      document.addEventListener('keydown', this.eventKeyDown)
    },
    removeEventListeners() {
      document.removeEventListener('keypress', this.eventKeyPress)
      document.removeEventListener('keydown', this.eventKeyDown)
    },
    eventKeyPress(event) {
      if (['textarea', 'input'].includes(event.target.tagName.toLowerCase())) {
        return
      }
      if (event.keyCode === 32) {
        event.preventDefault()
        if (this.playing) {
          this.$refs.videoPlayer.pause()
        } else {
          this.$refs.videoPlayer.play()
        }
      }
    },
    eventKeyDown(event) {
      return // keyboard shortcuts are handled by the player
      if (['textarea', 'input'].includes(event.target.tagName.toLowerCase())) {
        return
      }
      if (event.keyCode === 37) {
        // left
        event.preventDefault()
        this.$refs.videoPlayer.rewind()
      }
      if (event.keyCode === 39) {
        // right
        event.preventDefault()
        this.$refs.videoPlayer.fastForward()
      }
    },
    watchVideoDetails() {
      const video = this.post ? this.post.video : this.video
      this.firestoreUnsubscribe = onSnapshot(
        doc(db, 'live_details', video.id),
        doc => {
          // const source = doc.metadata.hasPendingWrites ? 'Local' : 'Server'
          const videoDetails = doc.data()
          if (videoDetails === undefined) {
            return
          }
          video.title = videoDetails.title
          video.description = videoDetails.description
          video.viewersCount = videoDetails.viewers
          video.liveStreamingDetails.chatEnabled = videoDetails.chatEnabled
          video.liveStreamingDetails.slowChatModeActive =
            videoDetails.slowChatModeActive
          video.isLiveActive = videoDetails.broadcasting
          video.isChild = !!videoDetails.isChild
          video.status = videoDetails.status
          this.screenMessage = videoDetails.screenMessage || null
          this.screenMessageType = videoDetails.screenMessageType || 'error'
          this.showScreenMessage = !!videoDetails.showScreenMessage
          if (this.post) {
            this.post.likesCount = videoDetails.likes
            if (videoDetails.votes) {
              // this.post.relevanceVoteTotal = videoDetails.votes
              this.$set(this.post, 'relevanceVoteTotal', videoDetails.votes)
            }
          }
          // if (videoDetails.finished && !this.liveFinished) {
          //   this.$emit('finished')
          // }
          // this.liveFinished = videoDetails.finished
          this.$emit('update:liveFinished', videoDetails.finished)

          if (
            videoDetails.hlsManifestUrl &&
            (video.hlsManifest === null ||
              videoDetails.hlsManifestUrl !== video.hlsManifest.url) &&
            (!Array.isArray(video.children) ||
              !video.children.length ||
              video.id === video.children[video.children.length - 1].id)
          ) {
            console.log('new manifest', video.hlsManifest)
            // change manifest only if different from before and is last child
            video.hlsManifest = { url: videoDetails.hlsManifestUrl }
            if (this.playerReady) {
              setTimeout(() => {
                this.destroy(true)
                this.initPlayer()
              }, 1000)
            }
          }

          if (videoDetails.scheduledStartTime) {
            video.liveStreamingDetails.scheduledStartTime = new Date(
              videoDetails.scheduledStartTime,
            )
          } else {
            video.liveStreamingDetails.scheduledStartTime = null
          }

          if (videoDetails.finishedAt) {
            video.liveStreamingDetails.actualEndTime = new Date(
              videoDetails.finishedAt,
            )
          } else {
            video.liveStreamingDetails.actualEndTime = null
          }
          if (!this.playerInitialized && videoDetails.broadcasting) {
            this.$nextTick(() => this.initPlayer())
          }
        },
      )
    },
    sendViewStat() {
      if (this.post === null) {
        return
      }
      const videoId = this.post.embedUid ? this.post.embedUid : this.post.uid
      requestService.post(
        `/video/${videoId}/viewed`,
        {
          key: this.video.viewKey,
          inSecondLastStop: this.lastVideoPosition,
        },
        { catchErrors: true },
        { apx: true },
      )
      clearTimeout(this.statTimeout)
      this.statTimeout = setTimeout(() => {
        this.sendViewStat()
      }, 15000)
    },
    sendUpdatePosition() {
      if (this.video.viewKey === undefined) {
        return
      }
      const videoId = this.post.embedUid ? this.post.embedUid : this.post.uid
      clearTimeout(this.updatePositionTimeout)
      this.updatePositionTimeout = setTimeout(() => {
        requestService.post(
          `/video/update-position`,
          {
            key: this.video.viewKey,
            uid: videoId,
            inSecondLastStop: this.lastVideoPosition,
          },
          { catchErrors: true },
        )
      }, 200)
    },
    embedError() {
      console.log('embedError')
      if (this.isInternal) {
        return
      }
      this.isInternal = true
      this.$nextTick(() => this.initPlayer())
      this.handleEmbedError()
      this.$emit('embedError')
    },
    handleEmbedError() {
      axios
        .get(
          process.env.VUE_APP_APX_BASE_URL +
            `/post/${this.video.id}/check-visibility`,
        )
        .catch(error => {
          console.log('error catch handleEmbedError', error)
        })
    },
    ended() {
      if (this.video.children && this.video.children.length) {
        const currentIndex = this.video.children.findIndex(
          videoPart => videoPart.id === this.video.id,
        )
        if (
          currentIndex !== -1 &&
          currentIndex + 1 < this.video.children.length
        ) {
          this.$emit('update:video', this.video.children[currentIndex + 1])
        }
      }
    },
    startPayment(type) {
      if (!this.$store.state.account.status.loggedIn) {
        this.$router.push({ name: 'Login' })
      }
      this.paymentType = type
      this.paymentDialog = true
    },
    becomeMember() {
      this.$emit('becomeMember')
    },
    getReleaseDate() {
      if (!this.post.video.presaleMode) {
        return 'Immédiatement'
      }
      if (
        Object.prototype.hasOwnProperty.call(this.post.video, 'releaseDate') &&
        this.post.video.releaseDate !== null
      ) {
        const date = new Date(this.post.video.releaseDate)
        return date.toLocaleString()
      }

      return 'Prochainement'
    },
    closePaymentDialog() {
      this.paymentDialog = false
      if (this.successfulPayment) {
        this.$root.$emit('reload')
      } else {
        this.paymentDialogKey++
      }
    },
    onPlaying(isPlaying) {
      this.playing = isPlaying
      this.wasPlayed = true
    },
  },
  watch: {
    'video.id'() {
      this.isInternal = this.video.platform !== 'youtube'
      this.$nextTick(() => {
        this.destroy(true)
        this.initPlayer()
        // scroll to top
        window.scrollTo(0, 0)
      })
    },
    'video.platform'() {
      const isInternal = this.video.platform !== 'youtube'
      if (isInternal === this.isInternal) {
        return
      }
      this.isInternal = isInternal
      this.$nextTick(() => {
        this.destroy(true)
        this.initPlayer()
        // scroll to top
        window.scrollTo(0, 0)
      })
    },
    'video.isLiveType'() {
      if (
        this.video.isLiveType === undefined ||
        this.video.isLiveType === this.lastIsLiveType
      ) {
        return
      }
      this.$emit('reload')
    },
  },
  created() {
    this.loadVideoPlayer()
    if (this.video.children && this.video.children.length) {
      this.video.children.unshift(this.video)
      if (this.video.isLiveType) {
        this.$emit(
          'update:video',
          this.video.children[this.video.children.length - 1],
        )
      }
    }
  },
  mounted() {
    this.isInternal = this.video.platform !== 'youtube'
    if (this.live) {
      this.watchVideoDetails()
      if (['live_created', 'live_scheduled'].includes(this.video.status)) {
        this.statTimeout = setTimeout(() => {
          this.sendViewStat()
        }, 3000)
      }
    }
    this.initEventListeners()
    if (this.video.isLiveType !== undefined) {
      this.lastIsLiveType = this.video.isLiveType
    }
    if (!this.inPost && !this.embed) {
      window.scrollTo(0, 0)
    }
  },
  beforeRouteLeave() {
    this.destroy()
  },
  beforeDestroy() {
    this.destroy()
  },
  beforeRouteUpdate() {
    this.destroy()
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  &.videojs-wrapper {
    height: auto;
    width: 100%;
  }
}

//.video-block {
//  aspect-ratio: 16/9;
//  max-height: calc(100vh - 70px);
//  min-height: 330px;
//}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .video-block {
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 2;
  }
}

.embed {
  .video-block,
  .loading-placeholder {
    min-height: 100% !important;
    height: 100% !important;
  }
}

.loading-placeholder {
  display: flex;
  height: 100%;
  width: 100%;
  background-position: center center;
  background-size: contain;
  aspect-ratio: 16/9;
  max-height: calc(100vh - 70px);
  min-height: 330px;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .loading-placeholder {
    min-height: 200px;
  }
}

.waiting-block {
  z-index: 4;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}

.embed-show {
  position: absolute;
  top: 0.4rem;
  right: 0.8rem;
  width: 200px;
  height: 43px;
  z-index: 10;

  &:hover {
    display: flex !important;
  }

  .embed-logo {
    //background-color: rgb(1 1 1 / 50%);
    border-radius: 3px;

    .protected-by {
      font-size: 0.6rem;
    }

    .v-image {
      margin-top: -7px;
    }
  }
}

.embed-title {
  border-radius: 3px;
  position: absolute;
  top: 0.4rem;
  left: 0.8rem;
  max-width: calc(100% - 210px);
  height: 43px;
  z-index: 10;
  padding: 5px 5px;
  font-size: 18px;

  &:hover {
    display: flex !important;
  }
}

.video-alert {
  position: absolute;
  bottom: 50px;
  left: 10px;
  z-index: 2;
  right: 10px;

  ::v-deep {
    a {
      color: #fff;
    }
  }
}

.selling-block {
  position: relative;
}

.membership-block {
  position: relative;
}

.player-invisible {
  max-height: 0 !important;
  min-height: 0 !important;
  max-width: 0 !important;
  display: block;
}

.embed-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 9;
}

.branding {
  display: none; /* Initialement non affiché */
  opacity: 0; /* Initialement transparent */
  transition: opacity 0.3s ease, display 0s 0.3s; /* Transition de l'opacité et du display */
}

.branding.visible {
  display: flex; /* Afficher l'élément */
  opacity: 1; /* Rendre l'élément opaque */
  transition-delay: 0s; /* Pas de délai pour l'apparition */
}
</style>
