<template>
  <v-card width="100%" color="transparent" elevation="0">
    <v-card-title class="text-overline pb-1 pt-2">
      <span v-if="post?.childrenCount > 1" class="mr-1">{{
        post.childrenCount
      }}</span>
      {{ $tc('chat.commentary', post.childrenCount) | capitalize }}
      <v-menu
        v-model="menuOpen"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-overflow
        offset-y
        content-class="rounded-lg overflow-hidden sort-menu"
        max-width="200"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-tooltip
            bottom
            :disabled="!$vuetify.breakpoint.mdAndUp"
            color="rgba(0, 0, 0, 0.9)"
          >
            <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
              <v-btn
                v-bind="{ ...attrs, ...tooltipAttrs }"
                v-on="{ ...on, ...tooltipOn }"
                icon
                small
                id="sort-by"
                class="ml-2"
              >
                <v-icon medium>mdi-filter-variant</v-icon>
              </v-btn>
            </template>
            {{ $tc('chat.filter-comments') }}
          </v-tooltip>
        </template>

        <v-list dense style="z-index: 999">
          <v-list-item
            v-for="(item, index) in sortOptions"
            :key="index"
            @click="selectSortOption(item)"
            :class="{ 'active-sort-option': currentSortOption === item.text }"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text
      v-if="post.channel?.organization?.commentsDisabled"
      class="text-center"
    >
      {{ $tc('chat.comments-disabled') }}
    </v-card-text>
    <template v-else>
      <v-card-text class="pb-0">
        <comment-form
          :parent-post="post"
          @created="commentCreated"
        ></comment-form>
      </v-card-text>
      <v-card-text>
        <comment-list
          :parent-post="parentPost"
          :comments.sync="comments"
          @ready="listReady = true"
          style="min-height: 20px"
        ></comment-list>
        <div class="text-center" v-if="listReady && comments.length === 0">
          {{ $tc('chat.still-no-comment') }}
        </div>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import CommentForm from '@/components/CommentForm'
import CommentList from '@/components/CommentList'
import post from '@/views/Post.vue'

export default {
  name: 'CommentArea',
  components: { CommentList, CommentForm },
  props: {
    parentPost: { type: Object, required: true },
  },
  data() {
    return {
      post: {},
      comments: [],
      listReady: false,
      menuOpen: false,
      sortByRelevance: true,
      sortByPublishedAt: false,
      sortByArchivedAt: false,
      currentSortOption: 'sortByRelevance',
      sortOptions: [
        {
          title: this.$tc('chat.most-relevant-first'),
          text: 'sortByRelevance',
          value: true,
        },
        {
          title: this.$tc('chat.most-recent-first'),
          text: 'sortByPublishedAt',
          value: false,
        },
        {
          title: this.$tc('chat.archived-comments'),
          text: 'sortByArchivedAt',
          value: false,
        },
      ],
      unsubscriptionComment: () => {
        /* */
      },
    }
  },
  methods: {
    loadComments(sortBy) {
      this.$root.$emit('showCommentList', sortBy)
    },
    commentCreated(comment) {
      this.comments.unshift(comment)
    },
    selectSortOption(item) {
      this.loadComments(item.text)
      this.currentSortOption = item.text
      this.menuOpen = false
    },
  },
  watch: {
    parentPost: function() {
      this.post = this.parentPost
    },
  },
  created() {
    this.unsubscriptionComment = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === 'account/lastDonationComment' &&
        !this.post.video?.isLiveType
      ) {
        this.comments.unshift(mutation.payload)
      }
    })
  },
  mounted() {
    this.post = this.parentPost
  },
  beforeDestroy() {
    this.$root.$off('showCommentList', this.fetchComments)
    this.unsubscriptionComment()
  },
}
</script>

<style scoped lang="scss">
.v-list-item.active-sort-option,
.v-list-item.active-sort-option::before {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
</style>
