<template>
  <div style="min-width:0;position: relative;">
    <div
      v-if="commentsLoading"
      class="d-flex justify-center align-center"
      style="position: absolute;width: 100%"
    >
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <div
      v-for="(comment, index) in commentsFormatted"
      :key="comment.uid"
      class="d-flex my-4 comment-line"
      :class="{ 'is-pinned': comment.pinnedAt !== null }"
    >
      <div>
        <channel-tooltip
          :uid="comment.sender.uid || comment.sender.username"
          :name="comment.sender.name"
          :thumbnails="comment.sender.thumbnails"
          :is-user="!comment.sender.uid"
        >
          <template #activator="{ on }">
            <component
              :is="comment.link ? 'router-link' : 'span'"
              :to="comment.link || undefined"
              class="text-decoration-none grey--text text--lighten-1"
              v-on="on"
              @mouseenter.native="on.mouseenter"
              @mouseleave.native="on.mouseleave"
            >
              <channel-avatar
                :thumbnails="
                  comment.sender.thumbnails ? comment.sender.thumbnails : []
                "
                :size="sub ? 30 : 45"
                :channel-name="comment.sender.name"
                :bg-color="
                  comment.sender.avatarColor
                    ? comment.sender.avatarColor
                    : 'primary'
                "
                class="mr-3"
              ></channel-avatar>
            </component>
          </template>
        </channel-tooltip>
      </div>
      <div class="flex-grow-1" style="min-width:0;">
        <div class="d-flex">
          <div class="flex-grow-1" style="min-width:0;">
            <div class="comment-headline d-flex">
              <div class="flex-grow-1" style="min-width:0;">
                <div v-if="comment.pinnedAt !== null" style="cursor: text">
                  <v-icon size="12">
                    mdi-pin
                  </v-icon>
                  {{
                    $t('label.pinned-by') + ' ' + comment.originalSender.name
                  }}
                </div>
                <channel-tooltip
                  :uid="comment.sender.uid || comment.sender.username"
                  :name="comment.sender.name"
                  :thumbnails="comment.sender.thumbnails"
                  :is-user="!comment.sender.uid"
                >
                  <template #activator="{ on }">
                    <component
                      :is="comment.link ? 'router-link' : 'span'"
                      :to="comment.link || undefined"
                      class="text-decoration-none grey--text text--lighten-1 d-inline-flex align-center"
                      v-on="on"
                      @mouseenter.native="on.mouseenter"
                      @mouseleave.native="on.mouseleave"
                    >
                      <span
                        class="font-weight-bold"
                        :class="{
                          'original-sender': comment.isOriginalSender,
                          author: comment.isAuthor,
                        }"
                        >{{ comment.sender.name }}</span
                      >
                      <span v-if="comment.sender.uid" class="ml-1"
                        >@{{ comment.sender.uid }}</span
                      >
                      &nbsp;
                      <template v-if="!comment.sender.uid">
                        <v-icon
                          v-if="comment.platformSubscription"
                          :color="
                            getColor(comment.platformSubscription.planLevel)
                          "
                          small
                          >mdi-seal</v-icon
                        >
                        <v-icon
                          v-if="
                            comment.memberships.find(
                              e =>
                                e.organizationUid ===
                                comment.originalSender.uid,
                            )
                          "
                          color="grey"
                          small
                          >mdi-account-group-outline</v-icon
                        >
                      </template>
                      <template v-else>
                        <v-icon
                          v-if="!!comment.ownerMembershipLevel"
                          :color="getColor(comment.ownerMembershipLevel)"
                          small
                          >mdi-seal</v-icon
                        >
                      </template>
                    </component>
                  </template>
                </channel-tooltip>
                <span class="ml-1"
                  >&bull;
                  <router-link
                    :to="{
                      name: 'Post',
                      params: { slug: comment.uid, post: comment.original },
                    }"
                    class="grey--text"
                    >{{ comment.createdAt | capitalize }}</router-link
                  ></span
                >
                <v-chip
                  v-if="comment.isArchived"
                  small
                  color="white"
                  text-color="#e64a19"
                  class="transparent-white-background ml-2"
                >
                  {{ $tc('label.comment-archived') }}
                </v-chip>
              </div>
            </div>
            <div
              class="comment-content text-pre-wrap flex-grow-1 overflow-hidden text-break"
            >
              <!-- <div class="d-inline">
                <span v-if="comment.isDonation" class="ml-1">
                  &bull;
                  <span
                    class="text-overline teal--text text--accent-4 font-weight-medium"
                    >{{ $tc('donation.donation') }}</span
                  >
                </span>
              </div> -->
              <div class="d-flex align-center mb-1">
                <v-chip
                  v-if="comment.isDonation"
                  color="success"
                  class="mr-1"
                  small
                >
                  <v-icon left class="text-overline">
                    mdi-hand-coin
                  </v-icon>
                  <span class="font-weight-medium"
                    >{{ comment.donation }}€</span
                  >
                </v-chip>
                <div class="my-auto" v-html="comment.textContent"></div>
              </div>
              <div v-if="comment.tooLong">
                <v-btn
                  text
                  small
                  @click="
                    commentExpended =
                      commentExpended !== comment.uid ? comment.uid : null
                  "
                  color="grey lighten-1"
                  >{{
                    commentExpended !== comment.uid
                      ? $tc('label.read-more')
                      : $tc('label.show-less')
                  }}
                </v-btn>
              </div>
            </div>
          </div>
          <post-menu
            :post="comment.original"
            :isComment="true"
            @deleted="deleteComment(index)"
            @blocked="blockedComment(index)"
          ></post-menu>
        </div>

        <div class="d-flex">
          <post-voter :post="comment" comment></post-voter>
          <pin-button
            v-if="comment.isFirstChildrenLevel && post.isOwner"
            :post="comment"
            @pinned="$set(comment, 'pinnedAt', $event)"
            text-right
            small
          ></pin-button>
          <love-button
            :post="comment"
            :parent="post"
            text-right
            small
          ></love-button>
          <v-btn
            color="grey"
            text
            small
            class="ml-1"
            @click="
              depth >= depthLimit ? undefined : addResponseForm(comment.uid)
            "
            :to="
              depth >= depthLimit
                ? {
                    name: 'Post',
                    params: { slug: comment.uid, post: comment.original },
                  }
                : undefined
            "
            :disabled="
              comment.didPostUserBlockUser || comment.didUserBlockPostUser
            "
            target="_blank"
          >
            {{ $tc('chat.answer') }}
            <v-icon right small v-if="depth >= depthLimit"
              >mdi-open-in-new
            </v-icon>
          </v-btn>
        </div>
        <div v-if="showResponseForm.includes(comment.uid)" class="ml-2">
          <comment-form
            :parent-post="comment"
            sub
            @created="commentCreated(comment, $event)"
          ></comment-form>
          <div class="d-flex">
            <v-btn
              small
              text
              class="ml-auto mt-1"
              @click="hideResponseForm(comment.uid)"
              >{{ $tc('button.cancel') }}
            </v-btn>
          </div>
        </div>
        <div
          v-if="shownChildren.includes(comment.uid)"
          class="children-wrapper"
        >
          <comment-list
            :parent-post="comment"
            :comments.sync="comment.original.children"
            sub
          ></comment-list>
        </div>
        <div v-if="comment.original.childrenCount > 0">
          <v-btn
            v-if="!shownChildren.includes(comment.uid)"
            text
            small
            color="primary"
            @click="depth >= depthLimit ? undefined : showChildren(comment.uid)"
            :to="
              depth >= depthLimit
                ? {
                    name: 'Post',
                    params: { slug: comment.uid, post: comment.original },
                  }
                : undefined
            "
            target="_blank"
          >
            {{
              $tc('label.show-responses', comment.original.childrenCount, {
                count: comment.original.childrenCount,
              })
            }}
            <v-icon right small v-if="depth >= depthLimit">
              mdi-open-in-new
            </v-icon>
            <v-icon right v-else>mdi-chevron-down</v-icon>
          </v-btn>
          <v-btn
            v-else
            text
            small
            color="primary"
            @click="hideChildren(comment.uid)"
          >
            {{ $tc('label.hide-responses', comment.original.childrenCount) }}
            <v-icon right>mdi-chevron-up</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div
      v-if="!endReached && !hasError && children.length"
      class="d-flex mb-2"
      :class="{ 'justify-center': !sub }"
    >
      <v-btn
        x-small
        depressed
        color="primary"
        :loading="commentsLoading"
        @click="fetchComments"
      >
        <v-icon left x-small>mdi-message-plus</v-icon>
        {{ $tc('label.load-more-of') }}
        {{ sub ? $tc('label.response', 2) : $tc('chat.commentary', 2) }}
      </v-btn>
    </div>
    <div
      v-if="!sub && children.length"
      v-intersect="{
        handler: onIntersectEnd,
        options: {
          rootMargin: '-50% 0px 500px 0px',
          threshold: [0, 1],
        },
      }"
      style="min-height: 10px"
    ></div>
  </div>
</template>

<script>
import ChannelAvatar from './ChannelAvatar'
import LikeButton from './LikeButton'
import PostMenu from './PostMenu'
import { utilsService } from '@/services/utils.service'
import { requestService } from '@/services/request.service'
import { subscriptionService } from '@/services/subscription.service'
import CommentForm from '@/components/CommentForm'
import PinButton from '@/components/PinButton'
import LoveButton from '@/components/LoveButton'
import PostVoter from '@/components/post/PostVoter.vue'
import ChannelTooltip from '@/components/ChannelTooltip.vue'

export default {
  name: 'CommentList',
  components: {
    ChannelTooltip,
    PostVoter,
    PinButton,
    LoveButton,
    CommentForm,
    PostMenu,
    LikeButton,
    ChannelAvatar,
  },
  props: {
    parentPost: { type: Object, required: true },
    comments: { type: Array, required: false },
    sub: { type: Boolean },
  },
  data() {
    return {
      commentExpended: null,
      children: [],
      post: null,
      queryLast: null,
      endReached: false,
      hasError: false,
      commentsLoading: false,
      shownChildren: [],
      showResponseForm: [],
      depthLimit: 3,
    }
  },
  computed: {
    pinnedBy() {
      return this.$t('label.pinned-by')
    },
    parentSender() {
      if (this.post.channel && this.post.channel.organization) {
        return this.post.channel.organization
      } else if (this.post.organization) {
        return this.post.organization
      } else {
        return this.post.user
      }
    },
    commentsFormatted() {
      return this.children.map(comment => {
        let sender
        let link = null
        if (comment.organization) {
          sender = comment.organization
          link = {
            name: 'Organization',
            params: { slug: sender.uid, noBanner: !sender.hasBanner },
          }
        } else {
          sender = comment.user
          sender.name = comment.user.username
        }
        const textFormatted = this.formatText(
          comment.textContent,
          comment.uid !== this.commentExpended,
        )
        let isAuthor = false
        if (
          this.post.original &&
          ((this.post.original.channel &&
            this.post.original.channel.organization.uid === sender.uid) ||
            (this.post.original.organization &&
              this.post.original.organization.uid === sender.uid) ||
            (this.post.original.user &&
              this.post.original.user.username === sender.username))
        ) {
          isAuthor = true
        }

        const originalSender =
          this.post && this.post.originalSender
            ? this.post.originalSender
            : this.parentSender

        const formattedComment = {
          sender: sender,
          likesCount: comment.likesCount,
          liked: comment.liked,
          uid: comment.uid,
          isOwner: comment.isOwner,
          pinnedAt: comment.pinnedAt,
          lovedAt: comment.lovedAt,
          textContent: textFormatted.text,
          createdAt: utilsService.formatDateRelative(comment.createdAt),
          tooLong: textFormatted.tooLong,
          original: comment,
          showChildren: false,
          link: link,
          isAuthor: isAuthor,
          originalSender: originalSender,
          isDonation: comment.donation && comment.donation > 0 ? true : false,
          donation: comment.donation,
          platformSubscription: comment.user?.platformSubscription ?? null,
          memberships: comment.user?.organizationMemberships?.length
            ? comment.user.organizationMemberships
            : [],
          ownerMembershipLevel: comment.ownerMembershipLevel ?? null,
          isOriginalSender: !!(
            (originalSender.uid && originalSender.uid === sender.uid) ||
            (originalSender.username &&
              originalSender.username === sender.username)
          ),
          isFirstChildrenLevel: this.parentPost.parent === null,
          userVote: comment.userVote,
          relevanceVoteTotal: comment.relevanceVoteTotal,
          canEdit: comment.canEdit,
          canDelete: comment.canDelete,
          isArchived: comment.isArchived,
          didPostUserBlockUser: comment.didPostUserBlockUser ?? false,
          didUserBlockPostUser: comment.didUserBlockPostUser ?? false,
        }
        if (formattedComment.original.children === undefined) {
          formattedComment.original.children = []
        }
        return formattedComment
      })
    },
    depth() {
      let depth = 0
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let component = this
      while (component.$parent.$options.name === 'CommentList') {
        depth++
        component = component.$parent
      }
      return depth
    },
  },
  methods: {
    getColor(level) {
      return subscriptionService.subscriptionLevelColor(level)
    },
    formatText(text, short = true) {
      if (text === null || text === undefined) {
        return ''
      }
      text = text
        .replace(/[\u00A0-\u9999<>&]/g, i => {
          return '&#' + i.charCodeAt(0) + ';'
        })
        .replace(
          /(https?:\/\/\S+)/gim,
          '<a rel="nofollow" class="user-link" target="_blank" href="$1">$1</a>',
        )
        .replace(/(\r\n|\r|\n){3,}/gm, `\n\n`)
        .replace(
          /(((\d{1,2}):)?(\d{1,2}):(\d{2}))/gm,
          (match, p1, p2, p3, p4, p5) => {
            let seconds = parseInt(p4) * 60 + parseInt(p5)
            if (p3) {
              seconds += parseInt(p3) * 3600
            }
            const url =
              window.location.origin +
              this.$router.resolve({
                name: 'VideoPost',
                params: { slug: this.post.uid, post: this.post },
                query: { t: seconds },
              }).href
            return `<a class="timecode" data-time="${seconds}" href="${url}">${p1}</a>`
          },
        )
      const linesSplit = text.split(/\r\n|\r|\n/)
      const linesCount = linesSplit.length
      let tooLong = false
      if (linesCount > 3) {
        tooLong = true
        if (short) {
          const max =
            linesSplit[0].length + linesSplit[1].length + linesSplit[2].length
          text = utilsService.truncate(text, max + 2)
        }
      }
      if (text.length > 350) {
        tooLong = true
        if (short) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          text = utilsService.truncate(text, 350)
        }
      }
      return {
        text,
        tooLong,
      }
    },
    async fetchComments(sortBy) {
      this.commentsLoading = true
      this.hasError = false
      requestService
        .get(`/post/${this.post.uid}/children?limit=30`, {
          params: {
            after: this.queryLast,
            sortBy: sortBy,
          },
        })
        .then(response => {
          if (this.children.length && this.queryLast !== null) {
            // Array.prototype.push.apply(this.children, response.posts)
            this.children = this.children.concat(response.posts)
          } else {
            this.children = response.posts
          }
          this.$emit('update:comments', this.children)
          this.$emit('ready')

          if (response.last !== null) {
            this.queryLast = response.last
          } else {
            this.endReached = true
          }
          this.listenTimecodeClick()
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.commentsLoading = false
        })
    },
    showChildren(postUid) {
      const index = this.shownChildren.indexOf(postUid)
      if (index === -1) {
        this.shownChildren.push(postUid)
      }
    },
    hideChildren(postUid) {
      const index = this.shownChildren.indexOf(postUid)
      if (index !== -1) {
        this.shownChildren.splice(index, 1)
      }
    },
    addResponseForm(postUid) {
      const index = this.showResponseForm.indexOf(postUid)
      if (index === -1) {
        this.showResponseForm.push(postUid)
      }
    },
    hideResponseForm(postUid) {
      const index = this.showResponseForm.indexOf(postUid)
      if (index !== -1) {
        this.showResponseForm.splice(index, 1)
      }
    },
    commentCreated(parent, comment) {
      parent.original.children.unshift(comment)
      this.showChildren(parent.uid)
      this.hideResponseForm(parent.uid)
    },
    onIntersectEnd() {
      if (this.commentsLoading || this.endReached || this.hasError) {
        return
      }
      this.fetchComments()
    },
    log(value) {
      console.log(value)
    },
    listenTimecodeClick() {
      this.$nextTick(() => {
        document.querySelectorAll('.timecode').forEach(item => {
          item.addEventListener('click', event => {
            event.preventDefault()
            if (this.$route.query.t === event.target.dataset.time) {
              return
            }
            this.$router.replace({
              name: 'VideoPost',
              params: {
                slug: this.post.uid,
                post: this.post,
                preventReload: true,
              },
              query: { t: event.target.dataset.time },
            })
            if (this.$vuetify.breakpoint.smAndUp) {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }
          })
        })
      })
    },
    deleteComment(index) {
      this.children.splice(index, 1)
      // this.comments.splice(index, 1) this line was used before this commit
    },
    blockedComment(index) {
      this.fetchComments()
    },
  },
  watch: {
    parentPost: function() {
      this.post = this.parentPost
      this.fetchComments()
    },

    comments(comments) {
      if (comments.length !== this.children.length) {
        this.children = comments
      }
    },
    '$store.state.postEdited': function(newV, oldV) {
      if (newV && typeof newV === 'object') {
        const index = this.children.findIndex(e => e.uid === newV.uid)
        if (index !== -1) {
          const tmp = this.children
          tmp[index] = newV
          this.children = [...tmp]
        }
      }
    },
  },
  beforeMount() {
    this.post = this.parentPost
  },
  mounted() {
    this.fetchComments()
    if (this.$vuetify.breakpoint.xs) {
      this.depthLimit = 1
    }
    this.$root.$on('showCommentList', sortBy => {
      this.queryLast = null
      this.fetchComments(sortBy)
    })
  },
}
</script>

<style scoped lang="scss">
.comment-headline {
  font-size: 0.8rem;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.comment-content {
  color: #fff;
}

.children-wrapper {
  border-left: 1px var(--v-secondary-lighten1) solid;
  padding-left: 5px;
  margin-left: -5px;
}

.original-sender {
  background-color: var(--v-primary-base);
  padding: 2px 5px;
  border-radius: 3px;
}

.author:not(.original-sender) {
  background-color: var(--v-secondary-lighten1);
  padding: 2px 5px;
  border-radius: 3px;
}

.is-pinned::before {
  /*border-left: 2px var(--v-warning-base) solid;
  padding: 2px 0 2px 5px;
  margin-left: -5px;
  max-height: 72px;
  width: 5px;
  content: ' ';*/
}
.transparent-white-background {
  background-color: rgba(255, 255, 255, 0.1) !important;
  font-weight: bold;
}
</style>
