<template>
  <div>
    <p class="text-caption">
      Vous allez bloquer cet utilisateur. Il ne pourra plus ni interagir avec
      vos posts ni avec vidéos.
    </p>

    <v-list v-if="userOrganizations.length > 0" subheader two-line flat>
      <v-subheader class="text-subtitle-2">
        Choisir les organisations pour lesquelles vous souhaitez aussi bloquer
        cet utilisateur
      </v-subheader>

      <v-list-item-group v-model="organizations" multiple>
        <v-list-item
          v-for="org in userOrganizations"
          :key="org.uid"
          :value="org.uid"
        >
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active" color="primary"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                {{ org.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ org.uid }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-select
      :items="possibleSanctions"
      v-model="selectedSanction"
      item-text="label"
      item-value="value"
      :label="$tc('block.block-duration')"
      :rules="sanctionRules"
      outlined
    ></v-select>
    <v-select
      :items="reasons"
      v-model="selectedReason"
      item-text="label"
      item-value="value"
      :label="$tc('moderate-form.select-reason')"
      :rules="rules"
      outlined
    ></v-select>
    <v-textarea
      v-if="selectedReason === 'other'"
      v-model="moreDetails"
      :label="$tc('moderate-form.additional-details')"
      outlined
      rows="3"
    ></v-textarea>
    <p class="mb-5 font-weight-bold">
      {{ $tc('moderate-form.all-org-members-will-be-notified') }}
    </p>
    <v-btn color="primary" @click="promptDelete" :loading="isBlocking">
      {{ $tc('block.block') }}
    </v-btn>
    <v-btn class="ml-2" text @click="cancelBlock" :disabled="isBlocking">
      {{ $tc('moderate-form.cancel') }}
    </v-btn>
    <v-dialog v-model="confirmDelete" max-width="500">
      <v-card>
        <v-card-title class="headline">
          {{ $tc('moderate-form.confirmation') }}
        </v-card-title>
        <v-card-text>
          {{ $tc('block.would-you-really-like-to-block-user') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmDelete = false">
            {{ $tc('moderate-form.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="proceedToBlock">
            {{ $tc('block.block') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'
import { utilsService } from '@/services/utils.service'
export default {
  name: 'BlockUserForm',
  props: ['post'],
  data() {
    return {
      isBlocking: false,
      utilsService: utilsService,
      organizations: [],
      selectedReason: '',
      moreDetails: '',
      confirmDelete: false,
      reasons: [
        {
          label: this.$tc('moderate-form.reason.spam-form'),
          value: 'spam',
        },
        {
          label: this.$tc('moderate-form.reason.violence_promotion-form'),
          value: 'violence_promotion',
        },
        {
          label: this.$tc('moderate-form.reason.harassment-form'),
          value: 'harassment',
        },
        {
          label: this.$tc('moderate-form.reason.pornography-form'),
          value: 'pornography',
        },
        {
          label: this.$tc('moderate-form.reason.intellectual_property-form'),
          value: 'intellectual_property',
        },
        {
          label: this.$tc('moderate-form.reason.other'),
          value: 'other',
        },
      ],
      rules: [
        value => !!value || this.$tc('moderate-form.reason-must-be-selected'),
      ],
      selectedSanction: '',
      possibleSanctions: [
        {
          label: '1 ' + this.$tc('moderate-form.sanction.days'),
          value: 1,
        },
        {
          label: '3 ' + this.$tc('moderate-form.sanction.days'),
          value: 3,
        },
        {
          label: '7 ' + this.$tc('moderate-form.sanction.days'),
          value: 7,
        },
        {
          label: '30 ' + this.$tc('moderate-form.sanction.days'),
          value: 30,
        },
        {
          label: '3 ' + this.$tc('moderate-form.sanction.months'),
          value: 90,
        },
        {
          label: '1 ' + this.$tc('moderate-form.sanction.years'),
          value: 365,
        },
        {
          label: this.$tc('moderate-form.sanction.permanent'),
          value: -1,
        },
      ],
      sanctionRules: [
        value => !!value || this.$tc('moderate-form.sanction.must-be-selected'),
      ],
    }
  },
  computed: {
    userOrganizations() {
      return this.$store.state.account.organizations.filter(
        org => org.owned || org.currentUserIsModerator,
      )
    },
  },
  methods: {
    async submitBlock() {
      if (!this.selectedReason) {
        this.$store.dispatch(
          'alert/error',
          this.$tc('moderate-form.reason-must-be-selected'),
        )
        return
      }

      if (!this.selectedSanction) {
        this.$store.dispatch(
          'alert/error',
          this.$tc('block.duration-must-be-selected'),
        )
        return
      }

      const formData = {
        organizations: this.organizations,
        reason: this.selectedReason,
        duration: this.selectedSanction,
      }

      if (this.selectedReason === 'other') {
        if (!this.moreDetails) {
          this.$store.dispatch(
            'alert/error',
            this.$tc('block.additional-details-must-be-filled'),
          )
          return
        } else {
          formData = {
            ...formData,
            additionalInfo: this.moreDetails,
          }
        }
      }
      this.isBlocking = true

      try {
        await requestService.post(`/post/${this.post.uid}/user/ban`, formData)

        this.$store.dispatch(
          'alert/success',
          this.$tc('block.user-blocked-successfully'),
        )
        this.$emit('blocked')
        this.clean()
        this.$emit('closeBlockUserForm')
      } catch (error) {
        this.$store.dispatch(
          'alert/error',
          this.$tc('moderate-form.unable-to-moderate-or-delete'),
        )
      } finally {
        this.isBlocking = false
      }
    },

    promptDelete() {
      this.confirmDelete = true
    },

    proceedToBlock() {
      this.submitBlock()
      this.confirmDelete = false
    },

    cancelBlock() {
      this.clean()
      this.$emit('closeBlockUserForm')
    },
    clean() {
      this.selectedReason = undefined
      this.moreDetails = ''
      this.confirmDelete = false
      this.organizations = []
    },
  },
}
</script>

<style scoped lang="scss">
.v-list--two-line .v-list-item,
.v-list-item--two-line {
  min-height: 48px !important;
  height: 48px !important;
}
</style>
