<template>
  <v-row>
    <v-col v-if="onlyCensored" :cols="12" class="text-h5 pt-0 pb-0">
      <v-alert dense outlined text type="info" class="rounded-xl">{{
        $t('organization.censored-videos-message')
      }}</v-alert>
    </v-col>
    <post-item
      v-for="post in posts"
      :key="post.uid"
      :post="post"
      cols="12"
      sm="6"
      md="4"
    ></post-item>
    <template v-if="videosLoading">
      <!--      <v-col v-for="item in 8" :key="item" cols="6" sm="4" md="3">
        <v-sheet color="secondary" rounded>
          <v-responsive :aspect-ratio="320 / 180">
            <v-skeleton-loader height="100%" type="image"></v-skeleton-loader>
          </v-responsive>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </v-sheet>
      </v-col>-->
      <post-item-loader
        v-for="item in 8"
        :key="item"
        cols="12"
        sm="6"
        md="4"
      ></post-item-loader>
    </template>
    <v-col
      :cols="12"
      sm="4"
      md="3"
      v-intersect="{
        handler: onIntersectEnd,
        options: {
          rootMargin: '0px 0px 500px 0px',
        },
      }"
    ></v-col>
  </v-row>
</template>

<script>
import { requestService } from '@/services/request.service'
import PostItem from '@/components/PostItem'
import PostItemLoader from '@/components/PostItemLoader'
export default {
  name: 'PostList',
  components: { PostItemLoader, PostItem },
  props: {
    organizationId: {
      type: String,
      default: null,
    },
    ownerMembershipLevel: {
      type: Number,
      default: null,
    },
    onlyCensored: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rawPosts: [],
      videosLoading: true,
      queryLast: null,
      queryTotal: 0,
      hasError: false,
      endReached: false,
    }
  },
  computed: {
    posts() {
      return this.rawPosts.map(post => {
        return {
          ...post,
          channel: {
            ...post.channel,
            organization: {
              ...post.channel.organization,
              uid: this.organizationId,
              ownerMembershipLevel: this.ownerMembershipLevel,
            },
          },
        }
      })
    },
  },
  methods: {
    getLastVideos() {
      this.videosLoading = true
      requestService
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/organization/${this.organizationId}/posts?onlyCensored=${this.onlyCensored}`,
          {
            params: {
              after: this.queryLast,
            },
          },
        )
        .then(response => {
          // Array.prototype.push.apply(this.rawPosts, response.items)
          this.rawPosts.push(...response.items)
          // const posts = this.rawPosts.map(post => {
          //   return {
          //     ...post,
          //     channel: {
          //       ...post.channel,
          //       organization: {
          //         ...post.channel.organization,
          //         uid: this.organizationId,
          //       },
          //     },
          //   }
          // })
          // console.log(posts)
          if (response.last !== null) {
            this.queryLast = response.last
          } else {
            this.endReached = true
          }
          this.queryTotal = response.total
        })
        .catch(error => {
          this.endReached = true
          if (error.response && error.response.status === 404) {
            this.notFound = true
          } else {
            this.hasError = true
          }
        })
        .finally(() => {
          this.videosLoading = false
        })
    },
    onIntersectEnd() {
      if (this.videosLoading || this.endReached) {
        return
      }
      this.getLastVideos()
    },
    watchNewPosts() {
      this.$root.$on('postCreated', post => {
        if (post.channel?.organization?.uid === this.organizationId) {
          this.rawPosts.unshift(post)
        }
      })
    },
  },
  mounted() {
    this.getLastVideos()
    this.watchNewPosts()
  },
  watch: {
    '$store.state.postEdited': function(newV, oldV) {
      if (newV && typeof newV == 'object') {
        if (newV.images && newV.images.length > 0) {
          setTimeout(() => {
            this.queryLast = null
            this.getLastVideos()
          }, 3000)
        } else {
          const index = this.rawPosts.findIndex(post => post.uid === newV.uid)
          if (index !== -1) {
            this.rawPosts.splice(index, 1, newV)
          }
        }
      }
    },
  },
}
</script>

<style scoped></style>
