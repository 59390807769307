<template>
  <v-navigation-drawer
    app
    clipped
    floating
    touchless
    :color="$vuetify.breakpoint.lgAndUp ? 'transparent' : 'background'"
    style="position: fixed;z-index: 105;height: 100vh"
    :value.sync="open"
    @input="$emit('update:open', $event)"
    class="navigation-drawer"
  >
    <v-list dense class="main-menu" rounded>
      <v-list-item-group v-model="activeItem">
        <v-list-item
          :to="{ name: 'Home' }"
          exact
          @click="maybeRefresh"
          :title="$tc('menu.this-time') | capitalize"
        >
          <v-list-item-icon class="mr-5">
            <v-icon
              v-text="$route.name === 'Home' ? 'mdi-home' : 'mdi-home-outline'"
            ></v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{
            $tc('menu.this-time') | capitalize
          }}</v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'Censored' }"
          :title="$tc('censored.censored', 2) | capitalize"
        >
          <template v-slot:default="{ active }">
            <v-list-item-icon class="mr-5">
              <v-icon
                v-text="
                  active ? 'mdi-alert-octagon' : 'mdi-alert-octagon-outline'
                "
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{
              $tc('censored.censored', 2) | capitalize
            }}</v-list-item-content>
          </template>
        </v-list-item>
        <v-list-item
          :to="{ name: 'Subscribed' }"
          @click="maybeRefresh"
          :title="$tc('label.my-subscriptions') | capitalize"
        >
          <template v-slot:default="{ active }">
            <v-list-item-icon class="mr-5">
              <!--            <v-icon>mdi-youtube-subscription</v-icon>-->
              <v-icon
                v-text="
                  active ? 'mdi-heart-multiple' : 'mdi-heart-multiple-outline'
                "
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{
              $tc('label.my-subscriptions') | capitalize
            }}</v-list-item-content>
          </template>
        </v-list-item>
        <v-list-item
          :to="{ name: 'List', params: { slug: 'watch-later' } }"
          v-show="this.$store.state.account.status.loggedIn"
        >
          <template v-slot:default="{ active }">
            <v-list-item-icon class="mr-5">
              <v-icon
                v-text="active ? 'mdi-clock' : 'mdi-clock-outline'"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{
              $tc('list.watch-later')
            }}</v-list-item-content>
          </template>
        </v-list-item>
        <v-list-item
          :to="{ name: 'UserLists' }"
          v-show="this.$store.state.account.status.loggedIn"
        >
          <template v-slot:default="{ active }">
            <v-list-item-icon class="mr-5">
              <v-icon
                v-text="
                  active
                    ? 'mdi-play-box-multiple'
                    : 'mdi-play-box-multiple-outline'
                "
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              {{ $tc('list.my-lists') | capitalize }}
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <template v-if="$store.state.account.status.loggedIn">
      <div v-if="myOrganizations.length > 0">
        <v-subheader>
          {{ $tc('organization.my-organizations') | capitalize }}
          <v-btn
            class="ml-auto"
            icon
            small
            :title="$tc('button.create-organization') | capitalize"
            @click="$root.$emit('createOrganization')"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </v-subheader>
        <p
          class="px-4 text-caption text-center"
          v-if="!organizationsLoading && myOrganizations.length === 0"
        >
          {{
            $tc('organization.organization-group-several-channels') | capitalize
          }}
          <br />
          <router-link :to="{ name: 'MyOrganizations' }"
            >{{ $tc('organization.create-first-organization') | capitalize }}
          </router-link>
        </p>
        <v-list
          dense
          style="overflow: hidden"
          class="pt-0 mt-0"
          :style="{ 'max-height': showAllOrganizations ? 'none' : '160px' }"
          rounded
        >
          <v-item-group
            v-for="organization in formattedUserOrganizations"
            :key="organization.uid"
          >
            <v-list-item
              :to="{
                name: 'Organization',
                params: {
                  slug: organization.uid,
                  noBanner: organization.banner === null,
                },
              }"
              :title="organization.name"
            >
              <v-list-item-icon class="mr-5">
                <channel-avatar
                  :thumbnails="organization.thumbnails"
                  :size="26"
                  :channel-name="organization.name"
                  :bg-color="organization.avatarColor || 'primary'"
                >
                </channel-avatar>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ organization.name }}
                  <v-icon
                    v-if="organization.currentUserIsModerator"
                    small
                    class="select-behalf-icon mb-2 ml-1"
                    style="color: var(--v-warning-base);"
                    >mdi-shield-sword-outline
                  </v-icon>
                  <v-icon
                    v-if="!!organization.ownerMembershipLevel"
                    :color="getColor(organization.ownerMembershipLevel)"
                    dense
                    >mdi-seal</v-icon
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-item-group>
        </v-list>
        <v-item-group v-if="myOrganizations.length > 5">
          <v-list-item
            dense
            link
            @click.prevent="showAllOrganizations = !showAllOrganizations"
          >
            <v-list-item-icon class="mr-5 justify-center">
              <div>{{ showAllOrganizations ? '▲' : '▼' }}</div>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $tc('label.show') }}
                {{
                  showAllOrganizations ? $tc('button.less') : $tc('button.more')
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-item-group>
      </div>
      <div v-if="subscribedOrganizations.length > 0">
        <v-subheader>
          {{ $tc('label.my-subscriptions') | capitalize }}
        </v-subheader>
        <v-list
          dense
          class="mb-0 pb-0 mt-0 pt-0"
          style="overflow: hidden;"
          :style="{
            'max-height': showAllSubscribedOrganizations ? 'none' : '200px',
          }"
          rounded
        >
          <v-item-group
            v-for="organization in formattedUserSubscribedOrganizations"
            :key="organization.uid"
          >
            <v-list-item
              :to="{
                name: 'Organization',
                params: { slug: organization.uid },
              }"
              :title="organization.name"
            >
              <v-list-item-icon class="mr-5">
                <channel-avatar
                  :thumbnails="organization.thumbnails"
                  :size="26"
                  :channel-name="organization.name"
                  :bg-color="organization.avatarColor || 'primary'"
                >
                </channel-avatar>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ organization.name }}
                  <v-icon
                    v-if="organization.currentUserIsModerator"
                    small
                    class="select-behalf-icon ml-1"
                    style="color: var(--v-warning-base);"
                    >mdi-shield-sword-outline
                  </v-icon>
                  <v-icon
                    v-if="!!organization.ownerMembershipLevel"
                    :color="getColor(organization.ownerMembershipLevel)"
                    dense
                    >mdi-seal</v-icon
                  >
                  <v-icon
                    v-if="
                      memberOrganizations.find(
                        org => org.organizationUid === organization.uid,
                      )
                    "
                    color="grey"
                    dense
                    >mdi-account-group-outline</v-icon
                  >
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-badge
                  v-if="
                    memberOrganizations.find(
                      org =>
                        org.organizationUid === organization.uid &&
                        org.status === 'past_due',
                    )
                  "
                  bordered
                  color="warning"
                  icon="mdi-alert-octagon"
                  overlap
                >
                </v-badge>
              </v-list-item-action>
            </v-list-item>
          </v-item-group>
        </v-list>
        <v-item-group v-if="subscribedOrganizations.length > 5">
          <v-list-item
            link
            dense
            @click.prevent="toggleShowAllSubscribedOrganizations"
            :title="showAllSubscribedOrganizationsTitle"
          >
            <v-list-item-icon class="mr-5 justify-center">
              <div>{{ showAllSubscribedOrganizations ? '▲' : '▼' }}</div>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ showAllSubscribedOrganizationsTitle }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-item-group>
      </div>
    </template>
    <template v-slot:append>
      <div class="pa-2 text-center">
        <!--        <v-btn
          color="primary"
          depressed
          rounded
          class="px-8 mb-5"
          @click="publish"
          :title="$tc('button.publish') | capitalize"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $tc('button.publish') }}
        </v-btn>-->
        <div>
          <v-btn
            text
            small
            :to="{ name: 'Donation' }"
            :title="$tc('button.make-a-donation') | capitalize"
          >
            <v-icon small left color="primary">mdi-heart</v-icon>
            {{ $tc('button.make-a-donation') }}
          </v-btn>
        </div>
        <div v-if="isLoggedIn">
          <v-badge
            bordered
            color="warning"
            icon="mdi-alert-circle"
            overlap
            :value="plateformSubscriptionIssue"
          >
            <v-btn
              text
              small
              :to="{ name: 'PlateformSubscription' }"
              :title="$tc('button.subscribe') | capitalize"
            >
              <v-icon small left color="primary">mdi-seal</v-icon>
              {{ $tc('donation.become-contributor') }}
            </v-btn>
          </v-badge>
        </div>

        <div>
          <v-btn
            text
            small
            href="https://help.crowdbunker.com"
            target="_blank"
            :title="$tc('menu.help') | capitalize"
            >{{ $tc('menu.help') }}</v-btn
          >
          <!--          <v-btn text small :to="{ name: 'Fund' }">{{
            $tc('label.about')
          }}</v-btn>-->
          <v-btn
            text
            small
            exact
            :to="{ name: 'CGU' }"
            :title="$tc('menu.CGU') | capitalize"
            >{{ $tc('menu.CGU') }}
          </v-btn>
        </div>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { requestService } from '@/services/request.service'
import ChannelAvatar from '@/components/ChannelAvatar'
import store from '@/store'
import { subscriptionService } from '@/services/subscription.service'

export default {
  name: 'LeftMenu',
  components: {
    ChannelAvatar,
  },
  props: {
    open: { default: null },
  },
  data() {
    return {
      organizationsLoading: false,
      internalChannelsLoading: false,
      subscribedOrganizationsLoading: false,
      myOrganizations: [],
      showAllOrganizations: false,
      showAllSubscribedOrganizations: false,
      showAllInternalChannels: false,
      myInternalChannels: [],
      lastRefresh: Date.now(),
      lastPageChange: Date.now(),
      activeItem: null,
      subscribedPosts: [],
      subscribedOrganizations: [],
      totalSubscribedOrganization: 0,
      plateformSubscriptionIssue: false,
    }
  },
  computed: {
    formattedUserOrganizations() {
      return this.myOrganizations.map(item => ({
        id: item.id,
        uid: item.uid,
        thumbnails: item.thumbnails,
        name: item.name,
        subscribersCount: item.subscribersCount,
        status: item.status,
        avatarColor: item.avatarColor,
        banner: item.banner,
        currentUserIsModerator: item.currentUserIsModerator,
        ownerMembershipLevel: item.ownerMembershipLevel ?? 0,
      }))
    },
    formattedUserSubscribedOrganizations() {
      return this.subscribedOrganizations.map(item => ({
        uid: item.uid,
        thumbnails: item.thumbnails,
        name: item.name,
        avatarColor: item.avatarColor,
        currentUserIsModerator: item.currentUserIsModerator,
        ownerMembershipLevel: item.ownerMembershipLevel ?? 0,
      }))
    },
    loggedIn() {
      return this.$store.state.account.status.loggedIn
    },
    loading() {
      return (
        this.organizationsLoading ||
        this.internalChannelsLoading ||
        this.subscribedOrganizationsLoading
      )
    },
    showAllSubscribedOrganizationsTitle() {
      if (this.showAllSubscribedOrganizations) {
        return this.$tc('button.less')
      }
      const additionalElements = this.totalSubscribedOrganization - 5
      return `${this.$tc('label.show')} ${additionalElements} ${this.$tc(
        'button.additional-elements',
        additionalElements,
      )}`
    },
    isLoggedIn() {
      return store.state.account.status.loggedIn
    },
    platformSubscription() {
      return this.$store.state.account.plateFormSubscription
    },
    memberOrganizations() {
      return this.$store.state.account.memberOrganizations
    },
  },
  methods: {
    getColor(level) {
      return subscriptionService.subscriptionLevelColor(level)
    },
    async fetchUserOrganizations() {
      this.organizationsLoading = true
      try {
        const response = await requestService.get('/organization/mine')
        this.myOrganizations = response.items
        this.$store.state.account.organizations = response.items
      } finally {
        this.organizationsLoading = false
      }
    },
    async fetchSubscribedPosts() {
      this.postsLoading = true
      this.hasError = false
    },
    publish() {
      if (this.loggedIn) {
        this.$root.$emit('createPost')
      } else {
        this.$router.push({ name: 'Login' })
      }
    },
    maybeRefresh() {
      if (Date.now() - this.lastRefresh > 5000 && window.scrollY < 300) {
        this.$root.$emit('reload')
        this.lastRefresh = Date.now()
      } else if (Date.now() - this.lastPageChange > 500) {
        this.$vuetify.goTo(0)
      }
    },
    async fetchSubscribedOrganizations() {
      this.subscribedOrganizationsLoading = true
      try {
        const response = await requestService.get(
          '/organization/get-list-organisation-subscribed',
        )
        this.subscribedOrganizations = response.items
        this.totalSubscribedOrganization = response.total
      } finally {
        this.subscribedOrganizationsLoading = false
      }
    },
    toggleShowAllSubscribedOrganizations() {
      this.showAllSubscribedOrganizations = !this.showAllSubscribedOrganizations
    },
  },
  watch: {
    loggedIn(value) {
      if (value) {
        this.fetchUserOrganizations()
        this.fetchSubscribedOrganizations()
      }
    },
    platformSubscription(value) {
      if (value?.status === 'past_due') {
        this.plateformSubscriptionIssue = true
      }
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.fetchUserOrganizations()
      this.fetchSubscribedOrganizations()
      this.$root.$on('channelCreated', this.fetchUserOrganizations)
      this.$root.$on('organizationCreated', this.fetchUserOrganizations)
      this.$root.$on(
        'subscribedOrganizationUpdated',
        this.fetchSubscribedOrganizations,
      )
    }
    this.$router.beforeEach((to, from, next) => {
      next()
      this.lastPageChange = Date.now()
    })
    this.$root.$on('refreshOrGoTop', this.maybeRefresh)
  },
}
</script>

<style scoped>
.main-menu .v-list-item__content:first-letter,
.v-subheader:first-letter {
  text-transform: capitalize;
}
.navigation-drawer.v-navigation-drawer--is-mobile {
  max-height: 100%;
}
</style>
