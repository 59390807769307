var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-10 ml-0"},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-subtitle-1 mb-4 text-capitalize"},[_vm._v("\n        "+_vm._s(_vm.$t('label.description'))+"\n      ")]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.organization.description))])])],1),_vm._v(" "),_c('v-divider',{staticClass:"my-5"}),_vm._v(" "),_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-subtitle-1 mb-4"},[_vm._v("\n        "+_vm._s(_vm.$t('channel.channel-information'))+"\n      ")]),_vm._v(" "),_c('v-list',{attrs:{"dense":"","rounded":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-web")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_c('a',{attrs:{"href":'/@' + _vm.organization.uid}},[_vm._v("crowdbunker.com/@"+_vm._s(_vm.organization.uid))])])],1)],1),_vm._v(" "),(_vm.organization.subscribersCount)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-multiple-outline")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n              "+_vm._s(_vm.formatShortNumber(_vm.organization.subscribersCount))+"\n              "+_vm._s(_vm.$tc('organization.subscriber', _vm.organization.subscribersCount))+"\n            ")])],1)],1):_vm._e(),_vm._v(" "),(_vm.organization.totalNumberOfVideos > 0)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-video-outline")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("\n              "+_vm._s(_vm.formatShortNumber(_vm.organization.totalNumberOfVideos))+"\n              "+_vm._s(_vm.$tc('video.video', _vm.organization.totalNumberOfVideos))+"\n              "),(_vm.organization.totalNumberOfVideosCensored > 0)?_c('span',[_vm._v("\n                ("+_vm._s(_vm.formatShortNumber(_vm.organization.totalNumberOfVideosCensored))+"\n                "+_vm._s(_vm.$tc(
                    'censored.censored',
                    _vm.organization.totalNumberOfVideosCensored,
                  ))+"\n                )")]):_vm._e()])],1)],1):_vm._e(),_vm._v(" "),(_vm.organization.createdAt)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-calendar-range-outline")])],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('organization.active-since'))+"\n              "+_vm._s(_vm.utilsService.formatDateWithLocale(
                  _vm.organization.createdAt,
                  false,
                )))])],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }