<template>
  <div>
    <v-row justify="center" class="flex-md-nowrap">
      <v-col v-bind="mainColProps" class="flex-fill">
        <v-row justify="center" align="center" class="flex-column">
          <template
            v-if="
              !post.deletedAt &&
                !hasError &&
                !notFound &&
                bannedReason == null &&
                channelDeleted == null
            "
          >
            <v-col cols="12">
              <post-item
                v-if="post.parent"
                cols="12"
                large
                :post="post.parent"
                show-organization
                sub
              ></post-item>
              <video-with-meta
                v-if="post.video || $route.name === 'VideoPost'"
                :post="post"
              ></video-with-meta>
              <post-item
                v-else
                large
                cols="12"
                :post="post"
                solo
                show-organization
                video-meta
                class="pb-0 px-0"
                :class="{ 'pa-0': !post.parent }"
              ></post-item>
            </v-col>
            <div
              v-if="
                !post.channel?.organization.monetizationActive &&
                  post.channel?.organization
                    .currentUserIsAdminOfThisOrganization
              "
            >
              <monetization-alert :organization="post.channel?.organization" />
            </div>
          </template>
          <v-alert v-else type="error" class="mt-5">
            <span v-if="bannedReason !== null">{{
              $tc('moderate-form.reason.' + bannedReason)
            }}</span>
            <span v-else-if="notFound">{{
              $tc('error.post-doesnt-exist-or-deleted')
            }}</span>
            <span v-else-if="channelDeleted !== null">{{
              $tc('error.post-unavailable-channel-deleted')
            }}</span>
            <span v-else>{{ $tc('error.an-error-has-occurred') }}</span>
          </v-alert>
        </v-row>
        <v-row class="d-flex d-sm-none">
          <v-col>
            <!--            <promote-donation />-->
            <big-announcement-banner />
          </v-col>
        </v-row>
        <v-row justify="center" v-if="showFeaturedShopList">
          <v-col cols="12" class="px-sm-0 pt-0">
            <shop-featured-list />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" class="px-sm-0 pt-0">
            <comment-area
              v-if="isCommentAreaVisible && showCommentArea"
              :parent-post="post"
            ></comment-area>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4" lg="3" style="min-width: 370px">
        <live-chat-card
          v-if="post.video?.isLiveType"
          :post="post"
        ></live-chat-card>
        <list-card
          v-if="$route.query.list"
          :list-uid="$route.query.list"
        ></list-card>
        <!--        <promote-donation class="d-none d-sm-block" />-->
        <big-announcement-banner class="d-none d-sm-block" />
        <related-card :post="post"></related-card>
      </v-col>
    </v-row>
    <user-note v-if="canDo && post.uid" :post="post"></user-note>
  </div>
</template>

<script>
import PostItem from '../components/PostItem'
import { requestService } from '@/services/request.service'
import { subscriptionService } from '@/services/subscription.service'
import PostItemLoader from '@/components/PostItemLoader'
import CommentArea from '@/components/CommentArea'
import VideoWithMeta from '@/components/VideoWithMeta.vue'
import LiveChat from '@/components/LiveChat.vue'
import LiveChatCard from '@/components/video/LiveChatCard.vue'
import ListCard from '@/components/video/ListCard.vue'
import RelatedCard from '@/components/post/RelatedCard.vue'
import UserNote from '@/components/subscription/UserNote.vue'
import MonetizationAlert from '@/components/MonetizationAlert.vue'
import PromoteDonation from '@/components/post/PromoteDonation.vue'
import BigAnnouncementBanner from '@/components/home/BigAnnouncementBanner.vue'
import ShopFeaturedList from '@/components/post/ShopFeaturedList.vue'
export default {
  name: 'PostView',
  components: {
    PromoteDonation,
    MonetizationAlert,
    RelatedCard,
    ListCard,
    LiveChatCard,
    VideoWithMeta,
    CommentArea,
    PostItem,
    UserNote,
    BigAnnouncementBanner,
    ShopFeaturedList,
  },
  data() {
    return {
      post: {},
      loading: false,
      notFound: false,
      hasError: false,
      bannedReason: null,
      channelDeleted: null,
      fab: false,
      isCommentAreaVisible: true,
    }
  },
  computed: {
    plateFormSubscription() {
      return this.$store.state.account.plateFormSubscription
    },
    isVideo() {
      // return !!(this.post?.video || this.$route.meta.isVideo)
      return false
    },
    mainColProps() {
      if (this.isVideo || this.$route.name === 'VideoPost') {
        return {
          cols: 12,
          // sm: 7,
          md: 8,
          lg: 9,
        }
      }
      return {
        cols: 12,
        sm: 9,
        md: 7,
        lg: 6,
        style: {
          maxWidth: '700px',
        },
      }
    },
    showCommentArea() {
      if (!this.post?.uid) {
        return false
      }
      if (!this.post.video) {
        return true
      }
      return (
        !['live_created', 'live_scheduled', 'live'].includes(
          this.post.video.status,
        ) && this.post.video.canWatchVideo
      )
    },
    canDo() {
      return subscriptionService.canHaveAccess(this.plateFormSubscription, 1)
    },
    showFeaturedShopList() {
      if (!this.post?.uid) {
        return false
      }
      if (this.post.tags?.includes('Brigitte Macron')) {
        return true
      }
      return false
    },
  },
  methods: {
    fetchPost() {
      if (!this.post || Object.keys(this.post).length === 0) {
        this.loading = true
      } else {
        // this.$store.state.loading = true
      }
      this.notFound = false
      this.hasError = false
      requestService
        .get(`/post/${this.$route.params.slug}/details`)
        .then(response => {
          if (response.video?.channel?.status === 'deleted') {
            this.channelDeleted = true
          }
          if (response.channel?.status === 'deleted') {
            this.channelDeleted = response.channel.statusReason
          }
          if (response.bannedReason) {
            this.bannedReason = response.bannedReason
          }
          if (
            response.deletedAt ||
            (response.video && response.video.deleted)
          ) {
            this.notFound = true
            this.isCommentAreaVisible = false
            return
          }
          this.post = response
          if (response.channel && response.video?.title) {
            this.$store.dispatch('updateTitle', response.video.title)
          } else if (response.authorName) {
            this.$store.dispatch('updateTitle', response.authorName)
          }
        })
        .catch(response => {
          if (response.status === 404) {
            this.notFound = true
          } else {
            this.hasError = true
          }
        })
        .finally(() => {
          this.loading = false
          this.$store.state.loading = false
        })
    },
    handleDeletedPost() {
      this.fetchPost()
      this.showCommentArea = false
    },
    async initPost() {
      if (this.$route.params.post) {
        this.post = this.$route.params.post
      }
      await this.fetchPost()
    },
  },
  watch: {
    $route(to, from) {
      this.initPost()
      window.scrollTo(0, 0)
    },
    '$store.state.postEdited': function(newV, oldV) {
      if (newV && typeof newV === 'object' && newV.uid === this.post.uid) {
        if (newV.images && newV.images.length > 0) {
          setTimeout(() => {
            this.fetchPost()
          }, 3000)
        } else {
          this.post = newV
        }
      }
    },
  },
  created() {
    this.initPost()
  },
  mounted() {
    this.$root.$on('deletedPost', this.handleDeletedPost)
  },
  beforeDestroy() {
    this.$root.$off('deletedPost', this.handleDeletedPost)
  },
}
</script>
