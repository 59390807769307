<template>
  <v-row class="justify-center">
    <v-col cols="12" lg="11">
      <div class="custom-form">
        <h2 class="mt-5 mb-1">{{ $tc('account.account-settings') }}</h2>
        <v-tabs v-model="tab">
          <v-tab href="#infos-accounts" class="text-body-2">
            {{ $tc('account.account-information') }}
          </v-tab>
          <v-tab href="#notifications" class="text-body-2">
            {{ $tc('notification.my-notifications') }}
          </v-tab>
          <v-tab href="#subscriptions" class="text-body-2">
            {{ $tc('membership.payment') + 's' }}
          </v-tab>
          <v-tab href="#delete" class="text-body-2">
            {{ $tc('account.other-parameters') }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item id="infos-accounts">
            <!-- Account Settings FORM -->
            <!-- <div class="custom-form"> -->
            <div class="d-flex flex-column">
              <v-card flat>
                <!-- <v-card-title>{{
                    $tc('account.account-information')
                  }}</v-card-title>
                  <v-divider></v-divider> -->

                <v-card-text>
                  <v-form class="pt-4" @submit.prevent.stop="submit">
                    <v-row class="pl-lg-6">
                      <v-col cols="8" md="7" sm="6">
                        <v-text-field
                          v-model.trim="form.username"
                          @input="delayTouch($v.form.username)"
                          :label="$tc('login.username')"
                          :hint="$tc('login.username')"
                          :error-messages="usernameErrors"
                          :disabled="usrEnabled === 0"
                          v-on:keyup.enter="submit"
                        >
                          <template
                            v-if="!!plateFormSubscription"
                            v-slot:append
                          >
                            <v-icon
                              :color="
                                getSubsColor(plateFormSubscription.planLevel)
                              "
                              >mdi-seal</v-icon
                            >
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col class="pl-xl-13" cols="4" md="5" sm="6">
                        <v-subheader
                          ><a class="custom-fs" v-on:click="usrEnabled = 1">{{
                            $tc('account.change-username')
                          }}</a></v-subheader
                        >
                      </v-col>
                    </v-row>
                    <v-row class="pl-lg-6">
                      <v-col cols="8" md="7" sm="6">
                        <v-text-field
                          v-model.trim="form.email"
                          @input="delayTouch($v.form.email)"
                          :label="$tc('login.email')"
                          :hint="$tc('login.email')"
                          type="email"
                          :error-messages="emailErrors"
                          :disabled="mailEnabled === 0"
                          v-on:keyup.enter="submit"
                        ></v-text-field>
                      </v-col>
                      <v-col class="pl-xl-13" cols="4" md="5" sm="6">
                        <v-subheader
                          ><a class="custom-fs" v-on:click="mailEnabled = 1">{{
                            $tc('account.change-email')
                          }}</a></v-subheader
                        >
                      </v-col>
                    </v-row>
                    <!-- fiel for default payment credit card  -->
                    <v-row class="pl-lg-6 mb-3">
                      <credit-card-details
                        :onUserProfile="true"
                      ></credit-card-details>
                    </v-row>
                    <v-row class="pl-lg-6">
                      <credit-card-details
                        :onUserProfile="true"
                        :isConnect="true"
                      ></credit-card-details>
                    </v-row>
                    <v-row class="pl-lg-6">
                      <v-col cols="8" md="7" sm="6">
                        <v-text-field
                          v-model.trim="form.password"
                          @input="delayTouch($v.form.password)"
                          :label="$tc('login.password')"
                          :hint="$tc('login.password')"
                          type="password"
                          :error-messages="passwordErrors"
                          :disabled="pwdEnabled === 0"
                          v-on:keyup.enter="submit"
                        ></v-text-field>
                      </v-col>
                      <v-col class="pl-xl-13" cols="4" md="5" sm="6">
                        <v-subheader
                          ><a
                            class="custom-fs"
                            v-on:click="
                              pwdEnabled = 1
                              form.password = ''
                            "
                            >{{ $tc('account.change-password') }}</a
                          ></v-subheader
                        >
                      </v-col>
                    </v-row>
                    <v-row class="pl-lg-6">
                      <v-col cols="8" md="7" sm="6" :hidden="pwdEnabled === 0">
                        <v-text-field
                          v-model.trim="form.repeatPassword"
                          @input="delayTouch($v.form.repeatPassword)"
                          :label="$tc('account.repeat-passwd')"
                          :hint="$tc('account.repeat-passwd')"
                          type="password"
                          :error-messages="repeatPasswordErrors"
                          :disabled="pwdEnabled === 0"
                          v-on:keyup.enter="submit"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="pl-lg-6">
                      <v-col cols="8" md="7" sm="6">
                        <!--image avatar -->
                        <div
                          class="text-caption"
                          :class="{ 'avatar-disabled': avatarEnabled === 0 }"
                        >
                          {{ $tc('channel.profile-image') | capitalize }}
                        </div>
                        <v-hover
                          v-slot="{ hover }"
                          :disabled="avatarEnabled === 0"
                        >
                          <channel-avatar
                            :class="{
                              'avatar-disabled': avatarEnabled === 0,
                            }"
                            :thumbnails="userThumbnails"
                            :size="80"
                            :channel-name="
                              $store.state.account.user.user.username
                            "
                            :bg-color="
                              $store.state.account.user.user.avatarColor ||
                                'primary'
                            "
                            class="my-2 pl-1"
                          >
                            <v-overlay
                              absolute
                              :value="hover"
                              color="success darken-1"
                              opacity="0.8"
                            >
                              <v-btn
                                icon
                                @click="
                                  uppyAvatar.getPlugin('Dashboard').openModal()
                                "
                              >
                                <v-icon>mdi-cloud-upload-outline</v-icon>
                              </v-btn>
                            </v-overlay>
                          </channel-avatar>
                        </v-hover>
                        <div
                          v-if="getErrors(form.thumbnailKey.errors).length"
                          class="error text-caption"
                        >
                          {{ getErrors(form.thumbnailKey.errors)[0] }}
                        </div>
                        <!--image avatar -->
                      </v-col>
                      <v-col class="pl-xl-13" cols="4" md="5" sm="6">
                        <!-- code comment stay here before changing design
                    <v-subheader>
                      <a class="custom-fs mt-10" v-on:click="avatarEnabled = 1">
                        Changer ma photo de profil
                      </a>
                    </v-subheader>-->
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <!-- may use a v-for here  -->

                <v-card-text class="text-center">
                  <div v-if="error" class="error--text">
                    {{ labelApiMsg }}
                  </div>
                  <div v-else>{{ labelApiMsg }}</div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="success"
                    @click="submit"
                    type="submit"
                    :loading="loading"
                    :disabled="!canSend"
                    depressed
                    >{{ $tc('button.save') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <!-- </div> -->
          </v-tab-item>
          <v-tab-item id="notifications">
            <v-card class="text-center custom-form" flat>
              <!-- <v-card-title> {{ $tc('notification.my-notifications') }}</v-card-title>
        <v-divider></v-divider> -->
              <v-card-text>
                <div class="mb-6 mx-lg-16 mx-md-4">
                  <v-row>
                    <v-col sm="4" xs="12" offset="4">{{
                      $t('notification.mail') | capitalize
                    }}</v-col>
                    <v-col sm="4" xs="12">{{
                      $t('notification.push') | capitalize
                    }}</v-col>
                  </v-row>
                  <!-- V-FOR -->
                  <v-row
                    class="justify-center"
                    v-for="(item, key) in notificationPush"
                    :key="key"
                  >
                    <v-col class="align-center" sm="4" xs="12">
                      <v-card class="pa-2 my-1 secondary lighten-1" outlined>
                        {{ item.label }}
                      </v-card>
                    </v-col>
                    <v-col
                      sm="4"
                      xs="12"
                      class="custom-p d-flex align-center justify-center"
                    >
                      <v-switch
                        class="mt-0"
                        v-if="notificationMail[key]"
                        v-model="notificationMail[key].value"
                        :label="notificationMail[key].value ? 'On' : 'Off'"
                        hide-details
                        :disabled="notificationMail[key].disabled"
                        flat
                        @change="
                          updateNotificationMail(key, notificationMail[key])
                        "
                      ></v-switch>
                    </v-col>
                    <v-col
                      sm="4"
                      xs="12"
                      class="custom-p d-flex align-center justify-center"
                    >
                      <v-switch
                        class="mt-0"
                        v-model="item.value"
                        :label="item.value ? 'On' : 'Off'"
                        hide-details
                        :disabled="item.disabled"
                        flat
                        @change="updateNotificationPush(key, item)"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <!-- V-FOR END -->
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item id="subscriptions">
            <p class="ma-3">
              {{ $t('account.subscription-and-payment-info') }}
            </p>
            <v-card flat>
              <v-tabs v-model="paymentTab">
                <v-tab class="text-caption " href="#crowdbunker-subscriptions">
                  {{ $tc('membership.crowdbunker-subscriptions') }}
                </v-tab>
                <v-tab class="text-caption " href="#organization-subscriptions">
                  {{ $tc('membership.organization-subscriptions') }}
                </v-tab>
                <v-tab class="text-caption " href="#paiment-history">
                  {{ $tc('membership.paiment-history') }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="paymentTab">
                <v-tab-item id="crowdbunker-subscriptions">
                  <div>
                    <v-data-table
                      :headers="headers"
                      :items="PlatformSubscriptions"
                      :loading="loading"
                      :multi-sort="true"
                      :search="search"
                      :loading-text="$tc('label.loading') | capitalize"
                      :no-data-text="$tc('search.no-result')"
                      :no-results-text="$tc('search.no-result') | capitalize"
                      :items-per-page="5"
                      :page.sync="page"
                      :footer-props="{
                        itemsPerPageOptions: [5, 10, 20, 50, 100],
                        itemsPerPageText: $tc('label.items-per-page'),
                      }"
                    >
                      <template #item.thumbnail="{item}">
                        <channel-avatar
                          v-if="
                            item.subscriptionGroupType ==
                              'organization_membership_group'
                          "
                          :thumbnails="item.organization.thumbnails"
                          :size="80"
                          :channel-name="item.organization.name"
                          :bg-color="item.organization.avatarColor || 'primary'"
                          class="my-2"
                        >
                        </channel-avatar>
                        <div v-else>
                          <v-img
                            src="/images/logo-square-background.png"
                            alt="CrowdBunker"
                            style="width: 80px;"
                          >
                          </v-img>
                        </div>
                      </template>
                      <template #item.name="{item}">
                        <div
                          v-if="
                            item.subscriptionGroupType ==
                              'organization_membership_group'
                          "
                          class="subtitle-1"
                        >
                          {{ item.organization.name }}
                        </div>
                        <div
                          v-if="
                            item.subscriptionGroupType ==
                              'organization_membership_group'
                          "
                          class="text-caption"
                        >
                          @{{ item.organization.uid }}
                        </div>
                        <div
                          v-if="
                            item.subscriptionGroupType ==
                              'platform_membership_group'
                          "
                          class="text-caption"
                        >
                          Plateforme - CrowdBunker
                        </div>
                      </template>
                      <template #item.subscriptionStartDate="{item}">
                        <div class="text-subtitle-2">
                          {{ formatDate(item.subscriptionStartDate) }}
                        </div>
                      </template>
                      <template #item.subscriptionEndDate="{item}">
                        <div class="text-subtitle-2">
                          {{ formatDate(item.subscriptionEndDate) }}
                        </div>
                      </template>
                      <template #item.status="{item}">
                        <v-chip :color="getColor(item.status)" dark>
                          {{ item.status }}
                        </v-chip>
                      </template>
                      <template #item.invoices="{item}">
                        <div class="subtitle-1 text-center">
                          {{ item.invoices.length ?? 0 }}
                        </div>
                      </template>
                      <template #item.actions="{item}">
                        <div class="text-center">
                          <v-btn
                            icon
                            class="mt-2 ml-1"
                            @click="showSubscriptionDialog(item)"
                            large
                          >
                            <v-icon>mdi-eye-circle-outline</v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </v-tab-item>
                <v-tab-item id="organization-subscriptions">
                  <div>
                    <v-data-table
                      :headers="headers"
                      :items="orgSubscriptions"
                      :loading="loading"
                      :multi-sort="true"
                      :search="search"
                      :loading-text="$tc('label.loading') | capitalize"
                      :no-data-text="$tc('search.no-result')"
                      :no-results-text="$tc('search.no-result') | capitalize"
                      :items-per-page="5"
                      :page.sync="page"
                      :footer-props="{
                        itemsPerPageOptions: [5, 10, 20, 50, 100],
                        itemsPerPageText: $tc('label.items-per-page'),
                      }"
                    >
                      <template #item.thumbnail="{item}">
                        <channel-avatar
                          v-if="
                            item.subscriptionGroupType ==
                              'organization_membership_group'
                          "
                          :thumbnails="item.organization.thumbnails"
                          :size="80"
                          :channel-name="item.organization.name"
                          :bg-color="item.organization.avatarColor || 'primary'"
                          class="my-2"
                        >
                        </channel-avatar>
                        <div v-else>
                          <v-img
                            src="/images/logo-square-background.png"
                            alt="CrowdBunker"
                            style="width: 80px;"
                          >
                          </v-img>
                        </div>
                      </template>
                      <template #item.name="{item}">
                        <div
                          v-if="
                            item.subscriptionGroupType ==
                              'organization_membership_group'
                          "
                          class="subtitle-1"
                        >
                          {{ item.organization.name }}
                        </div>
                        <div
                          v-if="
                            item.subscriptionGroupType ==
                              'organization_membership_group'
                          "
                          class="text-caption"
                        >
                          @{{ item.organization.uid }}
                        </div>
                        <div
                          v-if="
                            item.subscriptionGroupType ==
                              'platform_membership_group'
                          "
                          class="text-caption"
                        >
                          Plateforme - CrowdBunker
                        </div>
                      </template>
                      <template #item.subscriptionStartDate="{item}">
                        <div class="text-subtitle-2">
                          {{ formatDate(item.subscriptionStartDate) }}
                        </div>
                      </template>
                      <template #item.subscriptionEndDate="{item}">
                        <div class="text-subtitle-2">
                          {{ formatDate(item.subscriptionEndDate) }}
                        </div>
                      </template>
                      <template #item.status="{item}">
                        <v-chip :color="getColor(item.status)" dark>
                          {{ item.status }}
                        </v-chip>
                      </template>
                      <template #item.invoices="{item}">
                        <div class="subtitle-1 text-center">
                          {{ item.invoices.length ?? 0 }}
                        </div>
                      </template>
                      <template #item.actions="{item}">
                        <div class="text-center">
                          <v-btn
                            icon
                            class="mt-2 ml-1"
                            @click="showSubscriptionDialog(item)"
                            large
                          >
                            <v-icon>mdi-eye-circle-outline</v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </v-tab-item>
                <v-tab-item id="paiment-history">
                  <div>
                    <v-data-table
                      :headers="historyHeaders"
                      :items="payments"
                      :loading="historyDataLoading"
                      :search="search"
                      :loading-text="$tc('label.loading') | capitalize"
                      :no-data-text="$tc('search.no-result')"
                      :no-results-text="$tc('search.no-result') | capitalize"
                      :items-per-page.sync="
                        historyPaginationOptions.itemsPerPage
                      "
                      :page.sync="historyPaginationOptions.page"
                      :server-items-length="
                        historyPaginationOptions.itemsLength
                      "
                      :footer-props="{
                        itemsPerPageOptions:
                          historyPaginationOptions.rowsPerPageItems,
                        itemsPerPageText: $tc('label.items-per-page'),
                      }"
                      @pagination="getAllPayments($event)"
                      @update:options="getAllPayments($event)"
                    >
                      <template #item.description="{item}">
                        <div
                          v-if="item.type == 'organization_membership'"
                          class="text-caption"
                        >
                          {{ $t('account.channel-subscription') }}
                          <strong>{{
                            item.membershipSubscription.organization.name
                          }}</strong>
                        </div>
                        <div
                          v-if="item.type == 'platform_membership'"
                          class="text-caption"
                        >
                          {{ $t('account.platform-subscription') }}
                          <strong>CrowdBunker</strong>
                        </div>
                        <div
                          v-if="
                            [
                              'organization_fund',
                              'organization_donation',
                            ].includes(item.type)
                          "
                          class="text-caption"
                        >
                          {{ $t('account.donation-to-channel') }}
                          <strong>{{
                            item.post.channel.organization.name
                          }}</strong>
                        </div>
                        <div v-if="item.type == 'fund'" class="text-caption">
                          {{ $t('account.donation-to-campaign') }}
                        </div>
                        <div v-if="item.type == 'rental'" class="text-caption">
                          {{ $t('account.property-rental') }}
                        </div>
                        <div
                          v-if="item.type == 'purchase'"
                          class="text-caption"
                        >
                          {{ $t('account.property-purchase') }}
                        </div>
                      </template>
                      <template #item.subscriptionStartDate="{item}">
                        <div class="text-caption">
                          {{ formatDate(item.subscriptionStartDate) }}
                        </div>
                      </template>
                      <template #item.createdAt="{item}">
                        <div class="text-caption">
                          {{ formatDate(item.createdAt) }}
                        </div>
                      </template>
                      <template #item.status="{item}">
                        <v-chip :color="getColor(item.status)" dark>
                          {{ item.status }}
                        </v-chip>
                      </template>
                      <template #item.amountCents="{item}">
                        <div class="text-center">
                          {{ item.amountCents / 100 }} €
                        </div>
                      </template>
                      <template #item.actions="{item}">
                        <div
                          v-if="
                            [
                              'organization_membership',
                              'platform_membership',
                            ].includes(item.type)
                          "
                          class="text-center"
                        >
                          <v-btn
                            icon
                            class="mt-2 ml-1"
                            @click="
                              showSubscriptionDialog(
                                item.membershipSubscription,
                              )
                            "
                            large
                          >
                            <v-icon>mdi-eye-circle-outline</v-icon>
                          </v-btn>
                        </div>
                        <div v-else class="text-center">
                          <v-btn
                            icon
                            class="mt-2 ml-1"
                            color="primary"
                            :loading="loading"
                            @click="retrievePaymentPDF(item)"
                            large
                          >
                            <v-icon>mdi-download-outline</v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-tab-item>
          <v-tab-item id="delete">
            <v-card class="text-left custom-form word-wrap" flat>
              <!-- <v-card-title> {{ $tc('account.other-parameters') }}</v-card-title>
        <v-divider></v-divider> -->
              <v-card-text class="word-wrap">
                <div class="text-h6 word-wrap">
                  {{ $tc('account.deletion') | capitalize }}
                </div>
                <p
                  v-html="getWarningMessageTranslated001"
                  class="text-caption word-wrap"
                ></p>
                <v-dialog
                  v-model="dialog"
                  max-width="700px"
                  :fullscreen="$vuetify.breakpoint.smAndDown"
                >
                  <v-card>
                    <v-card-title class="headline white-space-normal">
                      {{ $tc('account.confirm-deletion') }}
                    </v-card-title>
                    <v-card-text>
                      <h3 v-html="getWarningMessageTranslated002"></h3>
                      <p
                        v-if="totalOfOrganizationThatUserIsTheOnlyOwner === 0"
                        v-html="$tc('account.no-organization-will-be-detete')"
                        class="font-weight-bold mt-5"
                      ></p>
                      <p
                        v-if="totalOfOrganizationThatUserIsTheOnlyOwner === 1"
                        v-html="
                          $tc('account.you-are-the-unique-owner-singular')
                        "
                        class="font-weight-bold mt-5"
                      ></p>
                      <p
                        v-else-if="
                          totalOfOrganizationThatUserIsTheOnlyOwner > 1
                        "
                        v-html="$tc('account.you-are-the-unique-owner-plural')"
                        class="font-weight-bold mt-5"
                      ></p>
                      <div v-if="totalOfOrganizationThatUserIsTheOnlyOwner > 0">
                        <v-list dense class="mb-0 pb-0 mt-0 pt-0">
                          <v-item-group
                            v-for="organization in formattedListOfOrganizationThatUserIsTheOnlyOwner"
                            :key="organization.uid"
                          >
                            <v-list-item
                              :to="{
                                name: 'Organization',
                                params: { slug: organization.uid },
                              }"
                              target="_blank"
                              :title="organization.name"
                            >
                              <v-list-item-icon class="mr-5">
                                <channel-avatar
                                  :thumbnails="organization.thumbnails"
                                  :size="26"
                                  :channel-name="organization.name"
                                  :bg-color="
                                    organization.avatarColor || 'primary'
                                  "
                                >
                                </channel-avatar>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  organization.name
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-item-group>
                        </v-list>
                        <p
                          v-if="totalOfOrganizationThatUserIsTheOnlyOwner === 1"
                          v-html="
                            $tc(
                              'account.warning-deletion-organization-singular',
                            )
                          "
                          class="font-weight-bold mt-5"
                        ></p>
                        <p
                          v-else-if="
                            totalOfOrganizationThatUserIsTheOnlyOwner > 1
                          "
                          v-html="
                            $tc('account.warning-deletion-organization-plural')
                          "
                          class="font-weight-bold mt-5"
                        ></p>
                      </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn class="mt-0 ml-auto" text @click="dialog = false">
                        {{ $tc('button.cancel') }}
                      </v-btn>
                      <v-btn
                        class="mt-0"
                        color="error"
                        text
                        @click="triggerAccountDeletion()"
                      >
                        {{ $tc('button.confirm') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn
                  class="mt-1"
                  color="error"
                  @click="
                    () => {
                      dialog = true
                      fetchListOfOrganizationThatUserIsTheOnlyOwner()
                    }
                  "
                  depressed
                >
                  {{ $tc('button.delete-account') }}
                </v-btn>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <br />
    </v-col>
    <v-dialog
      max-width="600"
      v-model="subscriptionDialog"
      v-if="subscription"
      persistent
    >
      <v-card class="pb-3">
        <!-- <v-card-title class="headline">
          {{ "Détail de l'abonnement" }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeSubscriptionDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title> -->
        <v-tabs
          v-model="dialogTab"
          color="primary"
          dark
          slider-color="primary"
          centered
        >
          <v-tab href="#information">
            {{ $t('account.subscription-detail') }}
          </v-tab>
          <v-tab href="#invoices">
            {{ $t('account.invoices') }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="dialogTab">
          <v-tab-item id="information">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <div class="text-subtitle-2">
                    {{ $t('account.subscription-id') }}
                  </div>
                  <div class="text-body-2">
                    {{ subscription.subscriptionId }}
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="text-subtitle-2">
                    {{ $t('account.subscription-type') }}
                  </div>
                  <div class="text-body-2">
                    {{
                      subscription.subscriptionGroupType ==
                      'organization_membership_group'
                        ? 'Organisation'
                        : 'Plateforme'
                    }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="
                    subscription.subscriptionGroupType ==
                      'organization_membership_group'
                  "
                  cols="12"
                  sm="6"
                >
                  <div class="text-subtitle-2">
                    {{ 'Organisation' }}
                  </div>
                  <div class="text-body-2">
                    <v-btn
                      :to="{
                        name: 'Organization',
                        params: { slug: subscription.organization.uid },
                      }"
                      target="_blank"
                      :title="subscription.organization.name"
                      text
                      outlined
                      rounded
                      class="text-body-2"
                      >{{ subscription.organization.name }} &nbsp;
                      <v-icon class="text-subtitle-2">mdi-open-in-new</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col
                  v-if="
                    subscription.subscriptionGroupType ==
                      'platform_membership_group'
                  "
                  cols="12"
                  sm="6"
                >
                  <div class="text-subtitle-2">
                    {{ $t('account.platform') }}
                  </div>
                  <div class="text-body-2">
                    <v-btn text class="text-body-2">
                      CrowdBunker
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="text-subtitle-2">
                    {{ $t('account.status') }}
                  </div>
                  <div class="text-body-2">
                    <v-chip :color="getColor(subscription.status)" dark>{{
                      subscription.status
                    }}</v-chip>
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="text-subtitle-2">
                    {{ $t('membership.start-date') }}
                  </div>
                  <div class="text-body-2">
                    {{ formatDate(subscription.subscriptionStartDate) }}
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="text-subtitle-2">
                    {{ $t('membership.end-date') }}
                  </div>
                  <div class="text-body-2">
                    {{ formatDate(subscription.subscriptionEndDate) }}
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="text-subtitle-2">
                    {{ $t('membership.automatic-renewal') }}
                  </div>
                  <div class="text-body-2">
                    {{ subscription.autoRenew ? 'Oui' : 'Non' }}
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="text-subtitle-2">
                    {{ $t('membership.number-of-renewals') }}
                  </div>
                  <div class="text-body-2">
                    {{ subscription.invoices.length }}
                  </div>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="text-subtitle-2">
                    {{ 'Prix' }}
                  </div>
                  <div class="text-body-2">
                    {{ subscription.amountCents / 100 }}
                    {{ subscription.currency === 'eur' ? '€' : '$' }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
          <v-tab-item id="invoices">
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" style="max-width: 200px !important">
                        Identifiant
                      </th>
                      <th class="text-left">{{ $t('membership.date') }}</th>
                      <th class="text-left">{{ $t('membership.amount') }}</th>
                      <th class="text-left">{{ $t('account.status') }}</th>
                      <th class="text-left">{{ $t('account.actions') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(invoice, index) in subscription.invoices"
                      :key="invoice.stripeInvoiceId"
                    >
                      <td
                        class="text-left text-wrap"
                        style="max-width: 200px !important;"
                      >
                        <!-- {{
                          invoice.stripeInvoiceId.length > 10
                            ? invoice.stripeInvoiceId.substring(0, 10) + '...'
                            : invoice.stripeInvoiceId
                        }} -->
                        {{ invoice.stripeInvoiceId }}
                        <v-btn
                          icon
                          class="mt-2 ml-1"
                          @click="copyToClipboard(invoice.stripeInvoiceId)"
                          large
                        >
                          <v-icon class="text-subtitle-2"
                            >mdi-content-copy</v-icon
                          >
                        </v-btn>
                      </td>
                      <td class="text-left">
                        {{ formatDate(invoice.createdAt) }}
                      </td>
                      <td class="text-left">
                        {{ invoice.amountPaidCents / 100 }}
                        {{ subscription.currency === 'eur' ? '€' : '$' }}
                      </td>
                      <td class="text-left">
                        <v-chip
                          :color="getInvoiceStatusColor(invoice.status)"
                          dark
                          >{{ invoice.status }}</v-chip
                        >
                      </td>
                      <td class="text-left">
                        <v-tooltip bottom color="grey">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="d-inline-flex btn-vertical px-1 px-sm-2"
                              text
                              color="primary"
                              :loading="loading"
                              @click="
                                retrieveMembershipPDFInvoice(invoice, index)
                              "
                              v-on="on"
                            >
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('video.download') | capitalize }}</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="grey" @click="closeSubscriptionDialog">
            {{ $tc('button.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  email,
  minLength,
  helpers,
  required,
  requiredIf,
  maxLength,
  sameAs,
} from 'vuelidate/lib/validators'
import { requestService } from '@/services/request.service'
import { utilsService } from '@/services/utils.service.js'
// import { PDFService } from '@/services/pdf.service.js'
import ChannelAvatar from '@/components/ChannelAvatar'
import { uppyService } from '@/services/uppy.service'
import CreditCardDetails from '@/components/CreditCardDetails'
import { subscriptionService } from '@/services/subscription.service'

require('@uppy/core/dist/style.min.css')
require('@uppy/dashboard/dist/style.min.css')
require('@uppy/image-editor/dist/style.min.css')

const touchMap = new WeakMap()

const securePassword = helpers.regex(
  'alpha',
  /^((?=.*[a-z])(?=.*[A-Z]))(?=.*[0-9])(?=.{8,})/,
)

const usernamePattern = helpers.regex(
  'alpha',
  /^[a-z0-9_-](?!.* {2})[ \w.-]{2,24}$/i,
)

// used with notification preferences
const payload = {
  mailNotifications: {},
  pushNotifications: {},
}

window.isSending = false

export default {
  name: 'AccountSettings',
  components: {
    ChannelAvatar,
    CreditCardDetails,
  },
  data() {
    return {
      page: 1,
      headers: [
        {
          text: this.$options.filters.capitalize(this.$tc('label.picture')),
          value: 'thumbnail',
          sortable: false,
          width: '120px',
        },
        {
          text: this.$options.filters.capitalize(
            this.$tc('channel.organization', 2),
          ),
          value: 'name',
        },
        {
          text: this.$options.filters.capitalize('Date de souscription'),
          value: 'subscriptionStartDate',
        },
        {
          text: this.$options.filters.capitalize('Date de fin'),
          value: 'subscriptionEndDate',
        },
        {
          text: this.$options.filters.capitalize(this.$tc('live.status')),
          value: 'status',
        },
        {
          text: this.$options.filters.capitalize('Renouvellement(s)'),
          value: 'invoices',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '80px',
        },
      ],
      historyHeaders: [
        {
          text: 'Identifiant',
          value: 'stripePaymentIntentId',
          sortable: false,
          width: '120px',
        },
        {
          text: this.$options.filters.capitalize(this.$tc('label.description')),
          value: 'description',
        },
        {
          text: this.$options.filters.capitalize('Date'),
          value: 'createdAt',
        },
        {
          text: this.$options.filters.capitalize(this.$tc('donation.amount')),
          value: 'amountCents',
        },
        {
          text: this.$options.filters.capitalize(this.$tc('live.status')),
          value: 'status',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '80px',
        },
      ],
      historyDataLoading: false,
      historyPaginationOptions: {
        page: 1,
        itemsPerPage: 5,
        rowsPerPageItems: [5, 10, 20, 50, 100],
        itemsLength: -1,
      },
      search: '',
      tab: '',
      paymentTab: '',
      dialogTab: '',
      subscriptionDialog: false,
      orgSubscriptions: [],
      PlatformSubscriptions: [],
      payments: [],
      subscription: null,
      uppyAvatar: {},
      picturePreviewUrl: null,
      picturePath: null,
      initLoading: true,
      form: {
        username: this.$store.state.account.user.user.username,
        email: this.$store.state.account.user.user.email,
        password: '',
        repeatPassword: '',
        thumbnailKey: {
          value: '',
        },
      },
      notificationMail: {
        newLive: {
          label: this.$tc('notification.new-live'),
          value: true,
          disabled: false,
        },
        censoredVideo: {
          label: this.$tc('censored.censored-content'),
          value: true,
          disabled: false,
        },
      },
      notificationPush: {
        newLive: {
          label: this.$tc('notification.new-live'),
          value: true,
          disabled: false,
        },
        censoredVideo: {
          label: this.$tc('censored.censored-content'),
          value: true,
          disabled: false,
        },
        commentReply: {
          label: this.$tc('notification.comment-reply'),
          value: true,
          disabled: false,
        },
        publishedVideo: {
          label: this.$tc('notification.new-published-video'),
          value: true,
          disabled: false,
        },
        // scheduledLive: {
        //   label: this.$tc('notification.scheduled-live'),
        //   value: true,
        //   disabled: false,
        // },
        // liveStartingSoon: {
        //   label: this.$tc('notification.live-reminder'),
        //   value: false,
        //   disabled: false,
        // },
      },
      usrEnabled: 0,
      pwdEnabled: 0,
      mailEnabled: 0,
      avatarEnabled: 1,
      loading: false,
      labelApiMsg: '',
      canSend: true,
      error: false,
      success: false,
      dialog: false,
      totalOfOrganizationThatUserIsTheOnlyOwner: null,
      listOfOrganizationThatUserIsTheOnlyOwner: [],
      delayBeforePurgeAccount: null,
    }
  },
  validations() {
    return {
      form: {
        username: {
          minLength: minLength(4),
          maxLength: maxLength(25),
          pattern: usernamePattern,
          required,
        },
        email: {
          email,
          required,
        },
        password: {
          secure: securePassword,
          minLength: minLength(8),
          required: requiredIf(function() {
            return this.pwdEnabled === 1
          }),
        },
        repeatPassword: { sameAsPassword: sameAs('password') },
      },
    }
  },
  computed: {
    paymentMethodInfos() {
      return this.$store.state.account.paymentMethod
    },
    paymentMethodConnect() {
      return this.$store.state.account.paymentMethodConnect || null
    },
    plateFormSubscription() {
      return this.$store.state.account.plateFormSubscription
    },
    userThumbnails() {
      if (this.picturePreviewUrl) {
        return [
          {
            // url: `${this.storageBaseUrl}/${this.picturePath}`,
            url: this.picturePreviewUrl,
            name: 'medium',
          },
        ]
      } else if (this.$store.state.account.user.user.thumbnails) {
        return this.$store.state.account.user.user.thumbnails
      }
      return []
    },
    getWarningMessageTranslated001() {
      return this.$t('account.warning-before-account-deletion-001', {
        delayBeforePurgeAccount: this.delayBeforePurgeAccount,
      })
    },
    getWarningMessageTranslated002() {
      return this.$t('account.warning-before-account-deletion-002', {
        delayBeforePurgeAccount: this.delayBeforePurgeAccount,
      })
    },
    usernameErrors() {
      const errors = []

      if (!this.$v.form.username.$dirty) return errors
      !this.$v.form.username.minLength &&
        errors.push(this.$tc('login.minimum-4-characters'))
      !this.$v.form.username.maxLength &&
        errors.push(this.$tc('login.maximum-25-characters'))
      !this.$v.form.username.required &&
        errors.push(this.$tc('login.required') + '.')
      !this.$v.form.username.pattern &&
        errors.push(this.$tc('login.can-only-contain'))
      return errors
    },
    emailErrors() {
      const errors = []

      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.email &&
        errors.push(this.$tc('error.invalid-email-address'))
      !this.$v.form.email.required &&
        errors.push(this.$tc('login.required') + '.')
      return errors
    },
    passwordErrors() {
      const errors = []

      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.minLength &&
        errors.push(this.$tc('login.password-8-chars'))
      !this.$v.form.password.secure &&
        errors.push(this.$tc('login.password-must-have'))
      !this.$v.form.password.required &&
        errors.push(this.$tc('login.required') + '.')
      return errors
    },
    repeatPasswordErrors() {
      const errors = []

      if (!this.$v.form.repeatPassword.$dirty) return errors
      !this.$v.form.repeatPassword.sameAsPassword &&
        errors.push(this.$tc('login.passwords-must-match'))

      return errors
    },
    formattedListOfOrganizationThatUserIsTheOnlyOwner() {
      return this.listOfOrganizationThatUserIsTheOnlyOwner.map(item => ({
        uid: item.uid,
        thumbnails: item.thumbnails,
        name: item.name,
        avatarColor: item.avatarColor,
      }))
    },
  },
  created() {
    this.fetchDelayBeforePurgeAccount()
    this.getAllMembershipToOrganizations()
    this.getAllPlateformSubscription()
    this.getAllPayments()
  },
  methods: {
    getSubsColor(level) {
      return subscriptionService.subscriptionLevelColor(level)
    },
    async retrieveMembershipPDFInvoice(invoice) {
      try {
        // todo: replace by server side pdf generation
        // PDFService.generateInvoicePdf(invoice, index + 1, this.subscription)
        this.loading = true
        const response = await requestService.get(
          process.env.VUE_APP_API_BASE_URL +
            `/payment/membership/invoice/${invoice.id}`,
          {
            responseType: 'blob',
          },
        )
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `invoice-${invoice.id}.pdf`)
          document.body.appendChild(link)
          link.click()
        } else {
          this.$store.dispatch(
            'alert/error',
            'Impossible to get the payment invoice',
          )
        }
      } catch (error) {
        // console.log(error)
        this.$store.dispatch(
          'alert/error',
          'Impossible to generate the invoice.',
        )
      } finally {
        this.loading = false
      }
    },
    async retrievePaymentPDF(payment) {
      try {
        // todo: replace by server side pdf generation
        // PDFService.generatePaymentInvoicePdf(
        //   payment,
        //   this.$store.state.account.user.user.email,
        // )
        this.loading = true
        const response = await requestService.get(
          process.env.VUE_APP_API_BASE_URL +
            `/payment/user/invoice/${payment.id}`,
          {
            responseType: 'blob',
          },
        )
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `invoice-${payment.id}.pdf`)
          document.body.appendChild(link)
          link.click()
        } else {
          this.$store.dispatch(
            'alert/error',
            'Impossible to get the payment invoice',
          )
        }
      } catch (error) {
        // console.log(error)
        this.$store.dispatch(
          'alert/error',
          'Impossible to get the payment invoice',
        )
      } finally {
        this.loading = false
      }
    },
    copyToClipboard(stripeInvoiceId) {
      navigator.clipboard.writeText(stripeInvoiceId).then(
        () => {
          this.$store.dispatch(
            'alert/success',
            this.$tc('label.copied-content'),
          )
        },
        () => {
          this.$store.dispatch(
            'alert/error',
            this.$tc('error.impossible-copy-content'),
          )
        },
      )
    },
    showSubscriptionDialog(subs) {
      this.subscription = { ...subs, invoices: subs.invoices.reverse() }
      this.subscriptionDialog = true
    },
    closeSubscriptionDialog() {
      this.subscription = null
      this.subscriptionDialog = false
      this.dialogTab = ''
    },
    getColor(status) {
      switch (status) {
        case 'active':
        case 'succeeded':
          return 'green'
          break
        case 'canceled':
        case 'cancelled':
          return 'red'
          break
        case 'expired':
          return 'red'
          break
        case 'processing':
        case 'pending':
          return 'orange'
          break
        default:
          return 'grey'
          break
      }
    },
    getInvoiceStatusColor(status) {
      switch (status) {
        case 'paid':
          return 'green'
          break
        case 'pending':
          return 'orange'
          break
        case 'void':
          return 'red'
          break
        default:
          return 'grey'
          break
      }
    },
    formatDate(date) {
      return utilsService.formatDate(date, true)
    },
    getAllMembershipToOrganizations() {
      requestService
        .get(
          process.env.VUE_APP_API_BASE_URL +
            '/account/get-all-user-membership-to-organizations',
        )
        .then(response => {
          if (!response.success) {
            return
          }
          if (response.success) {
            this.orgSubscriptions = response.data
          }
        })
        .catch(() => {})
    },
    getAllPlateformSubscription() {
      requestService
        .get(
          process.env.VUE_APP_API_BASE_URL +
            '/account/get-user-platform-subscriptions',
        )
        .then(response => {
          if (!response.success) {
            return
          }
          if (response.success) {
            this.PlatformSubscriptions = response.data
          }
        })
        .catch(() => {})
    },
    getAllPayments(ev) {
      try {
        let sortQueryStr = ''
        if (ev && ev.sortBy && ev.sortBy.length > 0) {
          sortQueryStr = `&sortBy=${ev.sortBy}&sortDesc=${ev.sortDesc}`
        }
        this.historyDataLoading = true
        requestService
          .get(
            process.env.VUE_APP_API_BASE_URL +
              `/payment/user/all?perPage=${this.historyPaginationOptions.itemsPerPage}&page=${this.historyPaginationOptions.page}${sortQueryStr}`,
          )
          .then(response => {
            if (!response.success) {
              return
            }
            if (response.success) {
              this.payments = response.data
              this.historyPaginationOptions.itemsLength = response.total
            }
          })
          .catch(error => {
            // console.log(error)
            this.$store.dispatch(
              'alert/error',
              'Impossible to get the payment history',
            )
          })
          .finally(() => {
            this.historyDataLoading = false
          })
      } catch (error) {
        // console.log(error)
        this.$store.dispatch(
          'alert/error',
          'Impossible to get the payment history',
        )
      }
    },
    getErrors(errors) {
      return typeof errors === 'object' ? errors[Object.keys(errors)[0]] : []
    },
    instantiateUppyAvatar() {
      this.uppyAvatar = uppyService.instantiate({
        id: 'avatar',
        note: this.$tc('organization.jpg-png-or-gif') + ' : 800 x 800 px',
        minCanvasWidth: 200,
        aspectRatio: 1,
      })
      this.uppyAvatar.on('upload-success', file => {
        this.picturePath = file.meta['key']
        this.form.thumbnailKey.value = file.meta['key']
        this.picturePreviewUrl = file.preview
      })
    },
    triggerAccountDeletion() {
      requestService
        .post(`/account/trigger-account-deletion`)
        .then(() => {
          this.$store.dispatch(
            'alert/success',
            this.$options.filters.capitalize(
              this.$tc('account.the-account-has-been-successfully-deleted'),
            ),
          )
          this.$store.dispatch('account/logout')
        })
        .catch(error => {
          this.$store.dispatch(
            'alert/error',
            this.$options.filters.capitalize(
              this.$tc('account.error-could-not-delete-your-account'),
            ),
          )
          console.error(error) // Afficher l'erreur dans la console pour le débogage
        })
    },
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
    submit() {
      this.$v.$touch()
      if (
        this.$v.form.$invalid ||
        !(
          this.usrEnabled |
          this.mailEnabled |
          this.pwdEnabled |
          this.avatarEnabled
        )
      ) {
        return
      }
      this.loading = true
      this.canSend = false
      // setTimeout(() => (this.canSend = true), 60000)
      let data = {}
      if (this.usrEnabled) data.username = this.form.username
      if (this.mailEnabled) data.email = this.form.email
      if (this.pwdEnabled) data.password = this.form.password
      if (this.avatarEnabled) data.thumbnailKey = this.form.thumbnailKey.value

      requestService
        .post('/account/change-account-info', data)
        .then(response => {
          this.error = false
          this.labelApiMsg = response.success
            ? this.$t('account.change-success')
            : ''
          this.$store.state.account.user.user.thumbnails = this.userThumbnails
          setTimeout(() => (this.labelApiMsg = ''), 3600)
          if (response.userData) {
            this.$store.dispatch('account/loginSuccess', {
              data: response.userData,
            })
          }
          if (!response.emailVerified) {
            this.$store.state.account.user.user.email = this.form.email
            return this.$router.push({
              name: 'ConfirmEmail',
              query: { email: response.email, login: 1 },
            })
          }
        })
        .catch(response => {
          this.error = true
          if (response.error.code === 422) {
            this.labelApiMsg = this.$tc('account.already-exist')
          }
        })
        .finally(() => {
          this.canSend = true
          this.loading = false
        })
    },
    updateNotificationMail(key, n) {
      this.disableSwitch(n)
      payload.mailNotifications[key] = n.value
      if (!window.isSending) this.sendNotificationPrefsAsync()
    },
    updateNotificationPush(key, n) {
      this.disableSwitch(n)
      payload.pushNotifications[key] = n.value
      if (!window.isSending) this.sendNotificationPrefsAsync()
    },
    disableSwitch(input) {
      input.disabled = true
      setTimeout(() => (input.disabled = false), 1000)
    },
    sendNotificationPrefsAsync() {
      window.isSending = true
      setTimeout(this.sendNotificationPrefs, 1000)
    },
    sendNotificationPrefs() {
      requestService
        .post('/account/notification-preferences', payload)
        .then(response => {
          this.$store.dispatch(
            'alert/success',
            this.$options.filters.capitalize(
              this.$t('channel.saved-modification'),
            ),
          )
        })
        .catch(response => {
          console.error(response)
        })
        .finally(() => {
          window.isSending = false
        })
    },
    fetchDelayBeforePurgeAccount() {
      requestService
        .post('/account/get-delay-before-purge')
        .then(response => {
          // this.delayBeforePurgeAccount = response.delayBeforePurgeAccount
          this.delayBeforePurgeAccount = 14
        })
        .catch(error => {
          console.error(error)
        })
    },
    fetchListOfOrganizationThatUserIsTheOnlyOwner() {
      requestService
        .post('/account/get-list-of-organization-that-user-is-the-only-owner')
        .then(response => {
          const total = response.total
          const organizations = response.items

          this.totalOfOrganizationThatUserIsTheOnlyOwner = total
          this.listOfOrganizationThatUserIsTheOnlyOwner = organizations
        })
        .catch(error => {
          console.error(error)
        })
    },
  },
  beforeMount() {
    requestService.get('/account/notification-preferences').then(response => {
      if (response.success) {
        Object.keys(this.notificationMail).forEach(function(key) {
          if (typeof response.mailNotifications[key] !== 'undefined') {
            this.notificationMail[key].value = response.mailNotifications[key]
          }
        }, this)
        Object.keys(this.notificationPush).forEach(function(key) {
          if (typeof response.pushNotifications[key] !== 'undefined') {
            this.notificationPush[key].value = response.pushNotifications[key]
          }
        }, this)
      }
    })
  },
  beforeDestroy() {
    if (window.isSending) {
      // be sure to send prefs before leaving page
      this.sendNotificationPrefs()
    }
  },
  mounted() {
    this.instantiateUppyAvatar()
  },
}
</script>

<style scoped>
.avatar-disabled {
  opacity: 0.6;
}

@media (min-width: 640px) {
  .custom-fs {
    font-size: 1.2em;
  }
}
@media (min-width: 540px) {
  .custom-p {
    position: relative;
  }
  .column-left {
    left: 9%;
  }
  .column-right {
    left: 12%;
  }
  /*.row {*/
  /*  height: 74px;*/
  /*  max-height: 74px;*/
  /*}*/
}

@media (min-width: 1720px) {
  .column-left {
    left: 13%;
  }
  .column-right {
    left: 15.4%;
  }
}

.custom-form {
  max-width: 1024px;
  margin: auto;
}

.white-space-normal {
  overflow-wrap: anywhere;
}
</style>
