<template>
  <v-card class="anniversary-banner my-4" flat rounded="lg">
    <div id="particles-js" class="particle-container">
      <!-- Génération des particules -->
      <div
        v-for="index in particleNum"
        :key="index"
        class="balloon-container"
        :style="generateRandomPosition(index)"
      >
        <v-icon class="balloon-icon" color="primary lighten-4"
          >mdi-balloon</v-icon
        >
      </div>
    </div>
    <div class="banner-content">
      <div class="years-section">
        <v-icon class="birthday-cake-icon" color="primary"
          >mdi-cake-variant</v-icon
        >
        <div class="years-text">
          4 ans !
        </div>
      </div>
      <div class="message-content d-flex flex-column">
        <p class="text-h6 font-weight-regular mb-2">
          Depuis 4 ans, CrowdBunker défend sans relâche la liberté d'expression
          grâce à votre soutien.
        </p>
        <p class="text-body-1 mb-2">
          Pour continuer notre mission et maintenir cette plateforme
          indépendante, nous avons plus que jamais besoin de votre aide. Chaque
          don compte pour préserver cet espace de liberté.
        </p>
        <div>
          <div class="text-caption font-weight-bold mb-1">
            Objectif :
          </div>
          <v-progress-linear
            color="success"
            height="13"
            class="mb-2"
            rounded
            :value="parseFloat($store.state.realtime.campaignStagePercent || 0)"
          >
            <span
              v-if="$store.state.realtime.campaignStagePercent !== null"
              class="text-caption"
              >{{
                parseFloat(
                  $store.state.realtime.campaignStagePercent || 0,
                ).toFixed(2)
              }}%</span
            >
          </v-progress-linear>
        </div>
        <v-btn
          class="donate-btn mt-4"
          x-large
          outlined
          @click="$store.state.donationDialog = true"
        >
          Faire un don
          <v-icon right>mdi-heart</v-icon>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AnniversaryBanner',
  data() {
    return {
      particleNum: 50, // Nombre de particules à générer
    }
  },
  mounted() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    generateRandomPosition(index) {
      const randomX = Math.random() * 100 // Random horizontal position
      const randomDelay = Math.random() * 20 // Random delay
      const randomSize = Math.random() * 1.5 + 1 // Balloon size
      const randomOpacity = ((randomSize - 1) / 1.5) * 0.1 + 0.02 // Opacity
      const randomDuration = Math.random() * 10 + 15 // Animation duration (15-25s)

      return {
        left: `${randomX}%`,
        bottom: '-20%',
        animationDelay: `${randomDelay}s`,
        animationDuration: `${randomDuration}s`, // Random duration
        fontSize: `${randomSize}rem`,
        opacity: randomOpacity,
      }
    },

    handleResize() {
      const bannerContent = this.$el.querySelector('.banner-content')
      if (bannerContent) {
        if (this.$el.clientWidth > 700) {
          bannerContent.classList.add('horizontal-layout')
          bannerContent.classList.remove('vertical-layout')
        } else {
          bannerContent.classList.add('vertical-layout')
          bannerContent.classList.remove('horizontal-layout')
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
$particleWidth: 10px;
$particleNum: 100;

.anniversary-banner {
  background: linear-gradient(
    45deg,
    #001825,
    #6000dd,
    #003a61,
    #6000dd,
    #001825
  ) !important;
  background-size: 300% 300% !important;
  animation: gradient 10s ease infinite;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(112, 0, 255, 0.2) !important;
  box-shadow: 0 0 30px rgba(64, 153, 255, 0.15) !important;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 100% 50%;
    filter: brightness(1.1);
  }
  50% {
    background-position: 50% 100%;
    filter: brightness(1.2);
  }
  75% {
    background-position: 0% 50%;
    filter: brightness(1.1);
  }
  100% {
    background-position: 0% 50%;
  }
}

.particle-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  pointer-events: none;

  .balloon-container {
    position: absolute;
    animation: move-frames 20s linear infinite;
    animation-timing-function: cubic-bezier(0.4, 0.1, 0.58, 0.85);
    opacity: 0.1;
    animation-duration: inherit; // Appliquer la durée personnalisée

    .balloon-icon {
      font-size: inherit;
    }
  }
}

@keyframes move-frames {
  0% {
    transform: translate(0, 0);
  }
  //15% {
  //  transform: translate(100px, -10vh);
  //}
  25% {
    transform: translate(-100px, -50vh);
  }
  50% {
    transform: translate(100px, -80vh);
  }
  75% {
    transform: translate(-100px, -120vh);
  }
  100% {
    transform: translate(100px, -140vh);
  }
}

.banner-content {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  position: relative;
  z-index: 2;
  color: white;
  flex-wrap: wrap;
  width: 100%;
}

.vertical-layout {
  flex-direction: column;
  text-align: center;
}

.horizontal-layout {
  align-items: center;

  p {
    max-width: 1000px;
  }

  .message-content {
    padding-left: 2rem;
  }
}

.years-section {
  flex: 0 1 auto;
  margin-bottom: 1rem;
  text-align: center;
}

.message-content {
  flex: 1;
  max-width: 100%;
}

.years-text {
  font-size: 2.5rem;
  font-weight: bold;
  white-space: nowrap;
  text-shadow: 0 0 10px #fff, 0 0 20px #6000dd, 0 0 30px #6000dd,
    0 0 40px #4099ff;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.birthday-cake-icon {
  font-size: 3rem;
  margin-bottom: 5px;
  display: block;
  text-align: center;
}

.donate-btn {
  background: rgba(96, 0, 221, 0.15) !important;
  border: 2px solid #6000dd !important;
  color: white !important;
  box-shadow: 0 0 10px rgba(96, 0, 221, 0.3) !important;
  transition: all 0.3s ease !important;
  margin-top: 1rem;
  align-self: center;
}

.horizontal-layout .message-content {
  margin-right: auto;
}

.horizontal-layout .donate-btn {
  margin-left: auto;
  align-self: center;
}
</style>
