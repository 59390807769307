<template>
  <v-menu
    left
    bottom
    offset-y
    max-width="400px"
    max-height="80vh"
    v-model="menuOpen"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        color="primary"
        :content="totalNotSeen"
        offset-y="25"
        offset-x="16"
        overlap
        :value="totalNotSeen > 0"
      >
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-progress-linear
      v-show="notificationsLoading"
      absolute
      indeterminate
    ></v-progress-linear>
    <v-list>
      <v-list-item v-if="formattedNotifications.length === 0">
        <v-list-item-title>{{
          $tc('notification.you-have-no-notification')
        }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-for="(notification, index) in formattedNotifications"
        :key="index"
        :to="notification.link"
        two-line
        class="py-1"
        :class="!notification.seen ? 'secondary lighten-1' : 'secondary'"
        active-class="abc"
        exact-active-class="abc2"
        @click="handleClick"
      >
        <v-list-item-content>
          <div v-if="notification.type === 'moderation'" class="text-body-2">
            {{ $tc('notification.' + notification.textContent) }}
          </div>
          <div v-else class="text-body-2">
            {{ notification.textContent }}
          </div>
          <v-list-item-subtitle class="text--secondary"
            >{{ notification.subtitle }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="notification.subtitle2"
            class="text--secondary"
          >
            <v-icon small color="grey" class="mt-n1">mdi-chevron-right</v-icon>
            {{ notification.subtitle2 }}
          </v-list-item-subtitle>
          <div class="text-caption">{{ notification.date | capitalize }}</div>
        </v-list-item-content>
        <video-thumbnail
          v-if="notification.thumbnails && notification.thumbnails.length"
          :thumbnails="notification.thumbnails"
          style="max-width: 130px"
          class="ml-1"
        ></video-thumbnail>
      </v-list-item>
      <div
        class="text-center mt-1"
        v-intersect="{
          handler: onIntersectEnd,
          options: {
            rootMargin: '-50% 0px 200px 0px',
            threshold: [0, 1],
          },
        }"
        v-if="!endReached"
      >
        <v-btn
          v-if="formattedNotifications.length"
          color="primary"
          :loading="notificationsLoading"
          @click="fetchNotifications(true)"
          x-small
          >{{ $tc('button.load-more') }}
        </v-btn>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import { requestService } from '@/services/request.service'
import VideoThumbnail from '@/components/VideoThumbnail'
import { utilsService } from '@/services/utils.service'

export default {
  name: 'NotificationList',
  components: { VideoThumbnail },
  data() {
    return {
      notificationsLoading: false,
      notifications: [],
      hasError: false,
      queryLast: null,
      endReached: false,
      totalNotSeen: 0,
      menuOpen: false,
      fetchTimeout: null,
    }
  },
  computed: {
    formattedNotifications() {
      return this.notifications
        .filter(
          notification =>
            notification.contextPost !== null ||
            notification.contextSubscription !== null,
        )
        .map(notification => {
          const formatted = {
            contextPost: notification.contextPost ?? null,
            type: notification.type,
            textContent: notification.textContent,
            seen: notification.seen,
            date: utilsService.formatDateRelative(notification.updatedAt),
            subtitle: null,
            subtitle2: notification.contextPost?.textContent,
          }

          if (notification.contextPost) {
            formatted.link = {
              name: 'Post',
              params: { slug: notification.contextPost.uid },
            }
          } else if (notification.contextSubscription) {
            if (notification.contextSubscription === 'platform') {
              formatted.link = {
                name: 'PlateformSubscription',
              }
            } else if (notification.contextSubscription === 'organization') {
              if (notification.contextOrganization?.uid) {
                formatted.link = {
                  name: 'Organization',
                  params: { slug: notification.contextOrganization.uid },
                }
              }
            }
          }
          if (notification.parentContextPost) {
            const parent = notification.parentContextPost
            if (parent.video) {
              formatted.subtitle = parent.video.title
              formatted.thumbnails = parent.video.thumbnails
            } else {
              formatted.subtitle = parent.textContent
              formatted.thumbnails = []
            }
          } else if (notification.contextPost?.video) {
            formatted.subtitle = notification.contextPost.video.title
            formatted.thumbnails = notification.contextPost.video.thumbnails
            formatted.link = {
              name: 'VideoPost',
              params: { slug: notification.contextPost.uid },
            }
          }
          return formatted
        })
    },
  },
  methods: {
    async fetchNotifications(next = false) {
      if (document.hidden) {
        this.fetchTimeout = setTimeout(() => {
          this.fetchNotifications()
        }, 120000)
        return
      }
      this.notificationsLoading = true
      this.hasError = false
      requestService
        .get(`/notification/`, {
          params: {
            before: next ? this.queryLast : null,
            after:
              !next && this.notifications.length
                ? this.notifications[0].ulid
                : null,
          },
          apx: true,
        })
        .then(response => {
          /*if (next && this.notifications.length && this.queryLast !== null) {
            this.notifications = this.notifications.concat(response.items)
          } else if (!next && this.notifications.length) {
            this.notifications = response.items.concat(this.notifications)
          } else {
            this.notifications = response.items
          }*/
          this.notifications.push(...response.items)

          this.queryLast = response.last
          if (response.last === null) {
            this.endReached = true
          }
          this.totalNotSeen = response.notSeenCount
          setTimeout(() => {
            if (this.menuOpen && this.totalNotSeen > 0) {
              this.seen()
            }
          }, 500)
          clearTimeout(this.fetchTimeout)
          this.fetchTimeout = setTimeout(() => {
            this.fetchNotifications()
          }, 120000)
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.notificationsLoading = false
        })
    },
    seen() {
      if (this.notifications.length === 0) {
        return
      }
      this.$store.dispatch('account/fetchPlateformSubscription')
      this.$store.dispatch('account/fetchMemberOrganizations')
      requestService
        .post('/notification/seen', {
          last: this.notifications[0].ulid,
        })
        .then(response => {
          if (response.notSeenCount !== undefined) {
            this.totalNotSeen = response.notSeenCount
          }
        })
    },
    onIntersectEnd() {
      if (this.notificationsLoading || this.endReached || this.hasError) {
        return
      }
      this.fetchNotifications()
    },
    handleClick() {
      this.$root.$emit('reload')
    },
  },
  watch: {
    menuOpen(value) {
      if (value) {
        this.fetchNotifications()
      }
    },
  },
  mounted() {
    this.fetchNotifications()
  },
  beforeDestroy() {
    clearTimeout(this.fetchTimeout)
  },
}
</script>

<style scoped></style>
