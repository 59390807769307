
import { defineComponent } from 'vue'
import LabelChip from '@/components/label/LabelChip.vue'
import {
  subscriptionLevelColor,
  getUserLevel,
} from '@/services/subscription.service'
import AppTooltip from '@/components/AppTooltip.vue'

export default defineComponent({
  name: 'ContributorFeature',
  components: { AppTooltip, LabelChip },
  props: {
    levelNeeded: {
      type: [Number, String],
    },
    allowed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      levelRequired:
        typeof this.levelNeeded === 'string'
          ? parseInt(this.levelNeeded)
          : this.levelNeeded,
    }
  },
  computed: {
    isAllowed() {
      const allowed =
        getUserLevel() >= this.levelRequired ||
        this.$store.state.account.user.user.isSuperUser
      this.$emit('update:allowed', allowed)
      return allowed
    },
    color() {
      return subscriptionLevelColor(this.levelRequired)
    },
    name() {
      return this.$tc(`membership.level.${this.levelRequired}`)
    },
  },
})
