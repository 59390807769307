<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" class="pa-0 pa-sm-3">
        <v-card color="transparent" class="elevation-0">
          <div class="responsive-video">
            <v-skeleton-loader
              class="video-skeleton"
              v-if="showVideoSkeleton"
              type="image, image, image"
              height="100%"
            ></v-skeleton-loader>
            <div
              v-else-if="video && video.deleted"
              class="d-flex justify-center align-center fill-height flex-column text-center"
            >
              <h1>{{ $tc('error.this-video-has-been-deleted') }}</h1>
              <h3 v-if="video.deletedReason">
                {{ video.deletedReason }}
              </h3>
            </div>
            <div
              v-else-if="
                channelDeleted ||
                  (channel.status && channel.status !== 'active')
              "
              class="d-flex justify-center align-center fill-height flex-column text-center"
            >
              <h1 v-if="channel.status === 'disabled'">
                {{ $tc('error.this-channel-is-disabled') }}
              </h1>
              <h1 v-else-if="channelDeleted">
                {{ $tc('error.this-channel-has-been-deleted') }}
              </h1>
              <h3 v-if="channel.statusReason">
                {{ channel.statusReason }}
              </h3>
            </div>
            <div
              v-else-if="videoError"
              class="d-flex justify-center align-center fill-height flex-column"
            >
              <h1>{{ $tc('error.an-error-has-occurred') }}</h1>
              <h3 v-if="videoErrorMessage">{{ videoErrorMessage }}</h3>
            </div>
            <template v-else-if="videoAvailable">
              <player-wrapper
                :video.sync="videoPlayed"
                :post="post"
                :liveFinished.sync="liveFinished"
                :live="video.isLiveType"
                @videoError="handleVideoError"
                @reload="reloadPlayer"
                @becomeMember="becomeMember"
                :key="playerKey"
                :forceVideoLoad="forceVideoLoad"
              ></player-wrapper>
            </template>
            <div
              v-else-if="['processing', 'created'].includes(video.status)"
              class="d-flex justify-center align-center fill-height flex-column"
            >
              <h1>
                {{ $tc('video.this-video-is-being') }}
                {{
                  video.platform === 'internal'
                    ? $tc('video.transcoded')
                    : $tc('video.imported')
                }}...
              </h1>
              <h3>{{ $tc('video.will-be-available-shortly') }}</h3>
              <v-progress-circular
                class="mt-3"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <div
              v-else-if="notFound"
              class="d-flex justify-center align-center fill-height flex-column"
            >
              <h1>{{ $tc('error.this-video-does-not-exist') }}</h1>
              <h3>{{ $tc('video.publish-or-sync-free') }}</h3>
            </div>
            <div
              v-else
              class="d-flex justify-center align-center fill-height flex-column"
            >
              <h1>{{ $tc('error.an-error-has-occurred') }}</h1>
              <h3 v-if="videoErrorMessage">{{ videoErrorMessage }}</h3>
            </div>
          </div>
          <v-skeleton-loader
            v-if="loading && video?.title === undefined"
            type="card-heading"
            class="title-skeleton"
            boilerplate
          ></v-skeleton-loader>
          <v-card-title class="py-2 pb-0 px-sm-0" v-if="video && video.title">
            <div>
              <span :class="{ 'text--disabled': !active }" class="text-break">
                {{ video.title }}
              </span>
              <div v-if="finished" class="text-caption">
                {{ $tc('label.live-ended') | capitalize }}
              </div>
              <div
                v-else-if="video.isLiveActive"
                class="text-caption success--text"
              >
                {{ $tc('label.on-the-air') | capitalize }}
              </div>
              <template v-if="active">
                <v-tooltip bottom v-if="isCensored" max-width="300px">
                  <template v-slot:activator="{ on }">
                    <v-chip
                      label
                      small
                      class="ml-2 text-uppercase"
                      outlined
                      v-on="on"
                      @click.prevent
                    >
                      <v-icon left small>mdi-alert-octagon</v-icon>
                      {{ $tc('video.censorship-detected') }}
                    </v-chip>
                  </template>
                  <div>
                    <div
                      v-html="$t('video.original-platform-blocked-video')"
                    ></div>
                    <div v-if="video.externalStatusMessage">
                      <u>{{ $tc('video.reason-invoked') }}</u> :
                      {{ video.externalStatusMessage }}
                    </div>
                  </div>
                </v-tooltip>
                <v-chip
                  label
                  small
                  class="ml-2"
                  outlined
                  v-if="video.status === 'processing'"
                >
                  <v-icon left small>mdi-cloud-sync</v-icon>
                  {{ $tc('video.video-being-converted') }}
                </v-chip>
              </template>
            </div>
          </v-card-title>
          <v-card-title
            v-if="active"
            class="py-0 px-sm-0 caption text--secondary subtitle-details"
          >
            <span
              v-if="
                ['live_created', 'live_scheduled', 'live'].includes(
                  video.status,
                ) && video.viewersCount
              "
              >{{ video.viewersCount }}
              {{ $tc('video.spectator', video.viewersCount) }}
              {{
                ['live_created', 'live_scheduled'].includes(video.status)
                  ? ' ' + $tc('live.waiting')
                  : ''
              }}</span
            >
            <span
              v-else-if="
                !['live_created', 'live_scheduled', 'live'].includes(
                  video.status,
                ) &&
                  video.viewCount &&
                  video.canWatchVideo
              "
              >{{ formatShortNumber(video.viewCount, true) }}
              {{ ' ' + $tc('video.view', video.viewCount) }}</span
            >
            <span v-if="visibility">{{ visibility }}</span>
            <span
              v-if="video.publishedAt"
              class="published-at"
              :title="
                `${$t('video.imported-to-crowdbunker-the')} ${formatDate(
                  new Date(this.video.createdAt),
                )}`
              "
            >
              <template
                v-if="
                  video.liveStreamingDetails !== null &&
                    video.liveStreamingDetails.actualStartTime
                "
              >
                {{ $tc('video.broadcast-on') }}
                {{ formatPlatformName(video.platform) }}
                {{
                  formatDateRelative(
                    new Date(video.liveStreamingDetails.actualStartTime),
                  )
                }}
              </template>
              <template
                v-else-if="
                  video &&
                    ['live_created', 'live_scheduled'].includes(video.status)
                "
              >
                {{ formattedScheduledTime }}
              </template>
              <template v-else>
                <span v-if="isVideoFromExternalPlatform">
                  {{ $tc('video.published-from') }}
                </span>
                <span>
                  {{ $tc('video.publish-on') }}
                </span>
                {{ formatPlatformName(video.platform) }}
                {{ formatDateRelative(new Date(post.publishedAt)) }}
              </template>
            </span>
            <span class="error--text" v-if="video.watchingMaxDate">
              Vous pouvez visionner cette vidéo jusqu'au
              {{ formatDate(new Date(video.watchingMaxDate)) }}</span
            >
            <div class="d-flex ml-auto mt-1" style="max-width: 100%">
              <v-slide-group>
                <post-voter :post="post"></post-voter>
                <add-to-list :post="post"></add-to-list>
                <retrieve-video
                  v-if="canRetrieve"
                  :video="video"
                ></retrieve-video>
                <retrieve-audio
                  v-if="canRetrieve && plateformSubscription?.planLevel >= 1"
                  :video="video"
                ></retrieve-audio>
                <!-- <retrieve-sequence
                  v-if="
                    isLoggedIn &&
                      video.isDownloadable &&
                      plateformSubscription?.planLevel >= 1
                  "
                  :video="video"
                ></retrieve-sequence> -->
                <share-video :post="post"></share-video>
                <!--                <reaction-button></reaction-button>-->
                <post-donation
                  v-if="
                    isLoggedIn &&
                      post &&
                      post.channel.organization.monetizationActive
                  "
                  :post="post"
                  :isLive="video.isLiveType"
                ></post-donation>
                <post-menu :post="post" in-video></post-menu>
              </v-slide-group>
            </div>
          </v-card-title>
          <!--          <v-divider class="mt-1 mb-2"></v-divider>-->
          <v-card-title
            v-if="channel && active && videoAvailable"
            class="pt-0 mt-2 px-sm-0"
          >
            <router-link
              :to="channelLink"
              class="text-decoration-none"
              :title="`@${video.channel.organization.uid}`"
            >
              <v-sheet
                class="d-flex align-center pa-1 channel-block"
                rounded
                v-ripple
              >
                <channel-avatar
                  :thumbnails="video.channel.organization.thumbnails"
                  :channel-name="video.channel.organization.name"
                  :size="48"
                  :bg-color="
                    video.channel.organization.avatarColor
                      ? video.channel.organization.avatarColor
                      : 'primary'
                  "
                ></channel-avatar>
                <div class="ml-2">
                  <div class="channel-name">
                    {{ video.channel.organization.name }}
                    <v-icon
                      v-if="!!post.channel?.organization?.ownerMembershipLevel"
                      :color="
                        getColor(
                          post.channel?.organization?.ownerMembershipLevel,
                        )
                      "
                      size="20"
                      class="ml-1"
                      >mdi-seal</v-icon
                    >
                  </div>
                  <div class="text-caption text--secondary">
                    {{ video.channel.name }}
                  </div>
                  <div class="text-caption text--secondary subscriber-count">
                    {{
                      formatShortNumber(
                        video.channel.organization.subscribersCount,
                      )
                    }}
                    {{
                      $tc(
                        'organization.subscriber',
                        Math.max(
                          1,
                          video.channel.organization.subscribersCount,
                        ),
                      )
                    }}
                  </div>
                </div>
              </v-sheet>
            </router-link>
            <div
              class="d-inline-flex ml-auto overflow-x-auto overflow-y-hidden"
              style="max-width: 100%;"
              v-if="active && videoAvailable"
            >
              <membership-button
                v-if="
                  isLoggedIn &&
                    video.channel.organization &&
                    video.channel.organization.membershipGroups &&
                    video.channel.organization.membershipGroups.length > 0
                "
                :organization="video.channel.organization"
              ></membership-button>
              <subscription-button
                v-if="video.channel.organization"
                :organization="video.channel.organization"
              ></subscription-button>
            </div>
          </v-card-title>
          <v-card-text v-if="video.title && active" class="px-sm-0 pb-0">
            <div>
              <v-icon small color="success" class="mb-1">
                mdi-shield-check
              </v-icon>
              {{ $tc('video.video-protected-against-censorship') }}
              <template v-if="video.platform === 'youtube'">
                {{ $tc('video.automatically-imported-replicated') }}
                <span v-if="!video.censored">
                  {{ $tc('video.blockage-automatically-take-over') }}
                </span>
              </template>
            </div>
            <div
              v-if="post.shortSummary"
              class="mt-2 warning--text text--lighten-5"
            >
              <v-icon small color="warning lighten-5" class="mb-1">
                mdi-creation
              </v-icon>
              {{ post.shortSummary }}
            </div>
            <v-tabs v-model="descriptionTab" background-color="transparent">
              <v-tab key="0">
                Description
              </v-tab>
              <v-tab key="1" v-if="!this.video.isLiveType">
                <v-icon small color="warning lighten-5" class="mr-1">
                  mdi-creation
                </v-icon>
                Résumé
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="descriptionTab"
              background-color="transparent"
              class="transparent"
            >
              <v-tab-item key="0">
                <div
                  class="mt-3 px-2 description"
                  :class="{
                    'description-condensed': descriptionCondensed,
                    'overflow-scroll': descriptionOverflowScroll,
                  }"
                  v-html="description"
                ></div>
                <div
                  class="d-flex align-center justify-space-between flex-wrap"
                >
                  <v-btn
                    v-if="description.length > 30"
                    text
                    class="mt-2"
                    @click="toggleDescription"
                  >
                    {{
                      descriptionCondensed
                        ? $tc('button.more')
                        : $tc('button.less')
                    }}
                  </v-btn>
                  <div
                    v-if="
                      video && ![null, undefined].includes(video.lastRevision)
                    "
                    class="d-inline-flex align-center"
                  >
                    <span class="text-caption font-italic"
                      >{{
                        $tc('video.video-updated-by-author-at') | capitalize
                      }}
                      {{ reviewedAt }}</span
                    >
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item key="1">
                <div class="px-4 overflow-y-auto">
                  <v-alert
                    v-if="getUserLevel() < 1"
                    type="info"
                    class="mt-2"
                    border="left"
                    outlined
                    elevation="0"
                    color="warning"
                  >
                    {{ $tc('video.become-contributor-to-read-summary') }}
                    <router-link
                      :to="{ name: 'PlateformSubscription' }"
                      class="success--text"
                    >
                      {{ $tc('donation.become-contributor') }}.
                    </router-link>
                  </v-alert>
                  <div
                    v-else-if="longSummary === null"
                    class="text-caption mt-1"
                  >
                    {{ $tc('video.no-summary-available') }}
                  </div>
                  <div v-if="longSummary">
                    <div class="text-caption mt-1">
                      {{ $tc('video.summary-generated-automatically') }}
                    </div>
                    <div class="long-summary mt-2" v-html="longSummary"></div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'
import { utilsService } from '@/services/utils.service'
import ChannelAvatar from '@/components/ChannelAvatar'
import SubscriptionButton from '@/components/SubscriptionButton'
import ShareVideo from '@/components/ShareVideo'
import RetrieveVideo from '@/components/video/RetrieveVideo.vue'
import PlayerWrapper from '@/components/PlayerWrapper'
import PostMenu from '@/components/PostMenu'
import AddToList from '@/components/list/AddToList.vue'
import PostDonation from '@/components/PostDonation'
import MembershipButton from '@/components/MembershipButton'
import PostVoter from '@/components/post/PostVoter.vue'
import { subscriptionService } from '@/services/subscription.service'
import RetrieveAudio from '@/components/subscription/RetrieveAudio.vue'
// import RetrieveSequence from '@/components/subscription/RetrieveSequence.vue'
import { markdown } from '@/services/markdown.service'
import { getUserLevel } from '@/services/subscription.service'

export default {
  name: 'VideoWithMeta',
  components: {
    PostVoter,
    AddToList,
    PostMenu,
    PlayerWrapper,
    ShareVideo,
    RetrieveVideo,
    SubscriptionButton,
    ChannelAvatar,
    PostDonation,
    MembershipButton,
    RetrieveAudio,
    // RetrieveSequence,
  },
  props: {
    post: { type: Object, default: () => {} },
  },
  data() {
    return {
      loading: true,
      descriptionCondensed: true,
      descriptionOverflowScroll: false,
      channelThumbnailError: false,
      externalPlayability: {}, // updated when error is detected during loading
      videoError: false,
      videoErrorMessage: null,
      liveFinished: false,
      playerKey: 0,
      forceVideoLoad: false,
      videoPlayed: {},
      notFound: false,
      campaignEnded: new Date() > this.$store.state.realtime.campaignStartDate,
      video: {},
      utilsService: utilsService,
      formattedScheduledTime: '',
      updateInterval: null,
      descriptionTab: 0,
    }
  },
  metaInfo() {
    if (this.video?.description === undefined) {
      return {}
    }
    const description = this.video.description ? this.video.description : ''
    return {
      title: `${this.video.title} - CrowdBunker`,
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content: description.substring(0, 150),
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content: description.substring(0, 150),
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: `${this.video.title} - CrowdBunker`,
        },
        {
          property: 'twitter:title',
          vmid: 'twitter:title',
          content: `${this.video.title} - CrowdBunker`,
        },
        { property: 'og:image', vmid: 'og:image', content: this.thumbnail },
        {
          property: 'twitter:image',
          vmid: 'twitter:image',
          content: this.thumbnail,
        },
        {
          property: 'og:url',
          vmid: 'og:url',
          content: this.videoUrl,
        },
        {
          property: 'twitter:url',
          vmid: 'twitter:url',
          content: this.videoUrl,
        },
        {
          property: 'og:video',
          vmid: 'og:video',
          content: this.embedURL,
        },
        {
          property: 'og:video:secure_url',
          vmid: 'og:video:secure_url',
          content: this.embedURL,
        },
        {
          property: 'og:video:type',
          vmid: 'og:video:type',
          content: 'video/mp4',
        },
        {
          property: 'og:video:series',
          vmid: 'og:video:series',
          content: `@${this.video.channel?.organization.name}`,
        },
        {
          property: 'twitter:card',
          vmid: 'twitter:card',
          content: `player`,
        },
        {
          property: 'twitter:player',
          vmid: 'twitter:player',
          content: this.embedURL,
        },
        {
          property: 'og:video:duration',
          vmid: 'og:video:duration',
          content: this.video.duration,
        },
        {
          property: 'og:video:width',
          content: '1920',
        },
        {
          property: 'og:video:height',
          content: '1080',
        },
        {
          property: 'twitter:player:width',
          content: '1920',
        },
        {
          property: 'twitter:player:height',
          content: '1080',
        },
      ],
    }
  },
  computed: {
    plateformSubscription() {
      return this.$store.state.account.plateFormSubscription
    },
    thumbnail() {
      if (!Array.isArray(this.video.thumbnails)) {
        return ''
      }

      const thumbnail = this.video.thumbnails.slice().sort((a, b) => {
        return b.height - a.height
      })[0]
      // const thumbnail = this.video.thumbnails.find(
      //   thumbnail => thumbnail.name === 'maxres',
      // )
      return thumbnail ? thumbnail.url : ''
    },
    channelThumbnail() {
      return this.channel.thumbnails?.find(
        thumbnail => thumbnail.name === 'default',
      )
    },
    description() {
      if (
        this.video.description === undefined ||
        this.video.description === null
      ) {
        return ''
      }
      return this.video.description
        .replace(/[\u00A0-\u9999<>&]/g, i => {
          return '&#' + i.charCodeAt(0) + ';'
        })
        .replace(
          /(https?:\/\/\S+)/gim,
          '<a rel="nofollow" target="_blank" href="$1">$1</a>',
        )
        .replace(
          /^(((\d{1,2}):)?(\d{1,2}):(\d{2}))\s+(.+)$/gm,
          (match, p1, p2, p3, p4, p5, p6) => {
            let seconds = parseInt(p4) * 60 + parseInt(p5)
            if (p3) {
              seconds += parseInt(p3) * 3600
            }
            const url =
              window.location.origin +
              this.$router.resolve({
                name: 'VideoPost',
                params: { slug: this.post.uid, post: this.post },
                query: { t: seconds },
              }).href
            return `<a class="timecode" data-time="${seconds}" href="${url}">${p1}</a> ${p6}`
          },
        )
    },
    longSummary() {
      if (!this.post.longSummary) {
        return null
      }
      return markdown(this.post.longSummary)
    },
    videoAvailable() {
      return !!(
        this.video.externallyPlayable ||
        this.video.dashManifest ||
        this.video.hlsManifest ||
        (this.video.price === 'paid' && !this.video.canWatchVideo) ||
        (this.video.visibility === 'membership' && !this.video.canWatchVideo)
      )
    },
    isCensored() {
      return (
        !this.video.externallyPlayable &&
        this.video.externalStatus === 'ERROR' &&
        this.video.censored
      )
    },
    publishedAt() {
      return new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(this.video.publishedAt))
    },
    reviewedAt() {
      return new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(this.video.lastRevision.createdAt))
    },
    channel() {
      return this.video.channel || {}
    },
    active() {
      return (
        this.video !== null &&
        this.video !== {} &&
        this.channel.status === 'active' &&
        !this.channelDeleted
      )
    },
    isLoggedIn() {
      return this.$store.state.account.status.loggedIn
    },
    channelLink() {
      if (this.video.channel?.organization) {
        return {
          name: 'Organization',
          params: {
            slug: this.video.channel.organization.uid,
            noBanner: !this.video.channel.organization.hasBanner,
          },
        }
      }
      return {
        name: 'Channel',
        params: {
          slug: this.video.channel?.id,
          noBanner: !this.video.channel?.hasBanner,
        },
      }
    },
    channelDeleted() {
      if (!this.post || !this.post.channel) {
        return false
      }
      return (
        this.post.channel.status === 'deleted' ||
        this.post.channel.organization.status === 'deleted'
      )
    },
    finished() {
      if (
        this.video === null ||
        !this.video.isLiveType ||
        this.video.liveStreamingDetails === null
      ) {
        return false
      }
      return (
        this.liveFinished || !!this.video.liveStreamingDetails.actualEndTime
      )
    },
    visibility() {
      switch (this.video.visibility) {
        case 'unlisted':
          return this.$options.filters.capitalize(this.$tc('live.unlisted'))
        case 'private':
          return this.$options.filters.capitalize(this.$tc('live.private'))
        case 'membership':
          return this.$options.filters.capitalize(
            this.$tc('live.membership-group'),
          )
        default:
          return null
      }
    },
    videoUrl() {
      return (
        window.location.origin +
        this.$router.resolve({
          name: 'VideoPost',
          params: { slug: this.post.uid, post: this.post },
        }).href
      )
    },
    embedURL() {
      return (
        window.location.origin +
        this.$router.resolve({
          name: 'EmbedVideo',
          params: { slug: this.post.uid, post: this.post },
        }).href
      )
    },
    showVideoSkeleton() {
      return (
        this.loading &&
        (this.video?.hlsManifest?.url === undefined ||
          this.video?.hlsManifest?.url === '')
      )
    },
    canRetrieve() {
      return (
        this.isLoggedIn &&
        this.video.isDownloadable &&
        this.video.visibility === 'public'
      )
    },
    isVideoFromExternalPlatform() {
      return this.video.platform !== 'internal' && !!this.video.externalUrl
    },
  },
  methods: {
    getColor(level) {
      return subscriptionService.subscriptionLevelColor(level)
    },
    updateFormattedScheduledTime() {
      if (
        this.video &&
        ['live_created', 'live_scheduled'].includes(this.video.status) &&
        this.video.liveStreamingDetails
      ) {
        this.formattedScheduledTime = this.utilsService.formatScheduledTime(
          this.video.liveStreamingDetails.scheduledStartTime,
        )
      }
    },
    initVideoData() {
      if (!this.post.video) {
        return
      }
      this.video = this.post.video
      this.video.channel = this.post.channel
      this.videoPlayed = this.video

      this.loading =
        (this.video?.hlsManifest?.url === undefined ||
          this.video?.hlsManifest?.url === '') &&
        this.video.publishedAt === undefined
      this.updateFormattedScheduledTime()
    },
    getVideo() {
      this.loading = true
      let url = `/video/${this.$route.params.slug}/details`
      if (this.$route.name === 'VideoPost') {
        url = `/post/${this.$route.params.slug}/details`
      }
      requestService
        .get(url)
        .then(response => {
          if (response.success === false && response.message) {
            this.videoError = true
            this.videoErrorMessage = response.message
            return
          }
          if (this.$route.name === 'VideoPost') {
            this.post = response
            this.video = response.video || {}
            this.video.channel = response.channel
          } else {
            this.video = response
          }
          this.videoPlayed = this.video
          this.$nextTick(() => {
            if (
              !this.videoAvailable &&
              ['processing', 'created'].includes(this.video.status)
            ) {
              setTimeout(this.getVideo, 10000)
            }
          })
          if (response.title) {
            this.$store.dispatch('updateTitle', response.title)
          }
          if (this.video.isLiveType) {
            this.$root.$emit('leftMenu', false)
          }
        })
        .catch(response => {
          if (
            (typeof response.error === 'string' ||
              response.error.status >= 500) &&
            this.video.hlsManifest === undefined
          ) {
            this.videoError = true
          } else if (response.error.status === 404) {
            this.notFound = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    toggleDescription() {
      this.descriptionCondensed = !this.descriptionCondensed

      if (!this.descriptionCondensed) {
        setTimeout(
          () =>
            (this.descriptionOverflowScroll = !this.descriptionOverflowScroll),
          520,
        )
      } else {
        this.descriptionOverflowScroll = !this.descriptionOverflowScroll
      }
    },
    handleVideoError(message) {
      this.videoError = true
      this.videoErrorMessage = message
    },
    formatDate(date) {
      return utilsService.formatDate(date, true)
    },
    formatDateRelative(date) {
      return utilsService.formatDateRelative(date)
    },
    formatPlatformName(name) {
      return utilsService.formatPlatform(name)['text']
    },
    formatShortNumber(views, long) {
      return utilsService.formatNumber(views, long)
    },
    formatDuration(seconds) {
      if (typeof seconds !== 'number') {
        return null
      }
      return new Date(1000 * seconds)
        .toISOString()
        .substr(11, 8)
        .replace(/^0(0:)?0?/, '')
    },
    reloadPlayer() {
      this.forceVideoLoad = true
      this.playerKey++
    },
    becomeMember() {
      document.getElementById('membership-button').click()
    },
    getUserLevel,
  },
  watch: {
    post(post) {
      this.initVideoData()
      // this.reloadPlayer()
      // this.initVideoData()
    },
    'post.video.liveStreamingDetails'() {
      this.updateFormattedScheduledTime()
    },
    description() {
      this.$nextTick(() => {
        document.querySelectorAll('.timecode').forEach(item => {
          item.addEventListener('click', event => {
            event.preventDefault()
            if (this.$route.query.t === event.target.dataset.time) {
              return
            }
            this.$router.replace({
              name: 'VideoPost',
              params: {
                slug: this.post.uid,
                post: this.post,
                preventReload: true,
              },
              query: { t: event.target.dataset.time },
            })
          })
        })
      })
    },
  },
  created() {
    this.initVideoData()
    if (this.$route.params.post?.video !== undefined) {
      this.post = this.$route.params.post
      this.video = this.$route.params.post.video
      this.videoPlayed = this.video
    }
  },
  mounted() {
    this.updateInterval = setInterval(this.updateFormattedScheduledTime, 60000)
  },
  beforeDestroy() {
    clearInterval(this.updateInterval)
  },
  beforeRouteUpdate(to, from, next) {
    if (!to.params.preventReload) {
      this.$root.$emit('reload')
    }
    next()
  },
}
</script>

<style lang="scss" scoped>
.description {
  white-space: pre-wrap;
  word-wrap: break-word;
  max-height: 800px;
  transition: max-height 0.1s ease-in-out;
  overflow: hidden;

  &.description-condensed {
    max-height: 20px;
  }

  &.overflow-scroll {
    overflow: auto;
  }
}

.channel-block {
  cursor: pointer;
  background-color: transparent;

  &:hover {
    background-color: var(--v-background-lighten1);
  }

  .channel-name {
    font-size: 1rem;
    line-height: 0.9rem;
  }

  .subscriber-count {
    line-height: 0.8rem;
  }
}

.theme--dark {
  .description {
    color: #fff;
  }
}

.subtitle-details {
  span:not(:first-child):not(.d-flex):before {
    content: ' \002022 ';
    margin-left: 3px;
  }
}

::v-deep {
  .v-skeleton-loader__image {
    height: 100%;
  }
  .title-skeleton {
    & > div {
      background-color: transparent !important;
      & > div {
        margin-left: 0;
      }
    }
  }
}

.chat-card {
  height: calc(100vh - 145px);
  min-height: 330px;
}

.responsive-video {
  //max-height: calc(100vh - 70px);
  min-height: 330px;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .responsive-video {
    min-height: 200px;
    aspect-ratio: 16/9;
  }
}

.video-skeleton {
  aspect-ratio: 16/9;
  max-height: calc(100vh - 70px);
  min-height: 330px;
  width: 100%;
  min-width: 100%;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .video-skeleton {
    min-height: 200px;
  }
}

::v-deep {
  .long-summary {
    word-wrap: break-word;
    max-height: 800px;
    overflow: auto;
    color: white;
    ul {
      //margin-top: -12px;
      margin-bottom: 16px;
    }
  }
}
</style>
