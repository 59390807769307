<template>
  <v-hover v-slot="{ hover }">
    <div>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
        :size="70"
        absolute
      ></v-progress-linear>
      <v-responsive v-if="videoError" :aspect-ratio="16 / 9">
        <div class="d-flex justify-center align-center" style="height: 100%">
          <div v-if="videoErrorMessage">{{ videoErrorMessage }}</div>
          <div v-else>{{ $tc('error.an-error-has-occurred') }}</div>
        </div>
      </v-responsive>
      <v-responsive
        v-else-if="large && play && videoDetailed !== null"
        :aspect-ratio="16 / 9"
      >
        <player-wrapper
          :video="videoDetailed"
          :live="video.isLiveType"
          @videoError="handleVideoError"
          in-post
          autoplay
        ></player-wrapper>
      </v-responsive>
      <div v-else style="position: relative">
        <video-thumbnail
          :thumbnails="video.thumbnails"
          :publishedAt="publishedAt"
          :in-second-duration="video.duration"
          :hover="hover && !videoProcessing"
          :censored="video.censored"
          :isMember="!!memberOrganization"
          :isOwner="!!post.isOwner"
          width="100%"
          @click.native="startPlay"
          :large="large"
          :live="video.isLiveActive"
          :live-type="video.isLiveType"
          :in-second-last-stop="video.lastStop"
          :video="video"
        ></video-thumbnail>
        <div
          v-if="videoProcessing"
          class="video-processing d-flex justify-center align-center"
        >
          <div>
            <div>{{ $tc('video.video-being-converted') + '...' }}</div>
            <div class="d-flex justify-center">
              <v-progress-circular
                class="mt-3"
                indeterminate
                color="white"
              ></v-progress-circular>
            </div>
          </div>
        </div>
      </div>

      <v-card-title
        class="subtitle-2 text-break pb-0 pt-1 px-0"
        v-if="!fromList"
      >
        <v-list-item three-line class="px-0 list-text-block">
          <v-list-item-content class="py-0">
            <v-list-item-subtitle :title="video.title">
              {{ video.title }}
              <v-icon
                v-if="video && ![null, undefined].includes(video.lastRevision)"
                x-small
                color="grey"
                >mdi-pencil</v-icon
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-title>
    </div>
  </v-hover>
</template>

<script>
import VideoThumbnail from './VideoThumbnail'
import { utilsService } from '@/services/utils.service'
import { requestService } from '@/services/request.service'
import PlayerWrapper from '@/components/PlayerWrapper'

export default {
  name: 'VideoInset',
  components: { PlayerWrapper, VideoThumbnail },
  props: {
    video: Object,
    post: Object,
    large: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    fromList: {
      type: Boolean,
      default: false,
    },
    noInnerPlayer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      play: false,
      videoDetailed: null,
      loading: false,
      videoError: false,
      videoErrorMessage: null,
      timeout: null,
    }
  },
  computed: {
    publishedAt() {
      return this.post.publishedAt ? `${this.post.publishedAt}` : null
    },
    channelName() {
      if (this.showOrganization && this.video.channel.organization) {
        return this.video.channel.organization.name
      }
      return this.video.channel.name
    },
    channelTooltip() {
      if (this.showOrganization && this.video.channel.organization) {
        return `${this.channelName} (${this.video.channel.name})`
      }
      return this.video.channel.name
    },
    channelThumbnails() {
      if (this.showOrganization && this.video.channel.organization) {
        return this.video.channel.organization.thumbnails
      }
      return this.video.channel.thumbnails
    },
    channelLink() {
      if (this.showOrganization && this.video.channel.organization) {
        return {
          name: 'Organization',
          params: { slug: this.video.channel.organization.uid },
        }
      }
      return {
        name: 'Channel',
        params: { slug: this.video.channel.id },
      }
    },
    videoProcessing() {
      return (
        this.hidden && ['created', 'processing'].includes(this.video.status)
      )
    },
    memberOrganization() {
      return this.$store.state.account.memberOrganizations.find(
        elt => elt.organizationUid === this.post?.channel?.organization?.uid,
      )
    },
  },
  methods: {
    formatDate(date, hour = false) {
      return utilsService.formatDate(date, hour)
    },
    formatDateRelative(date) {
      return utilsService.formatDateRelative(date)
    },
    getPlatformIcon(post) {
      if (post.platform) {
        switch (post.platform) {
          case 'youtube':
            return {
              iconName: 'mdi-youtube',
              color: '#f00',
            }
          case 'internal':
            return null
        }
      }
    },
    handleVideoError(message) {
      this.videoError = true
      this.videoErrorMessage = message
    },
    startPlay() {
      if (!this.large || this.noInnerPlayer) {
        return
      }
      this.play = true
      if (this.videoDetailed === null) {
        this.fetchVideoDetails()
      }
    },
    fetchVideoDetails() {
      this.loading = true
      clearTimeout(this.$store.state.timeout)
      requestService
        .get(`/post/${this.post.uid}/details`)
        .then(response => {
          if (response.success === false && response.message) {
            this.videoError = true
            this.videoErrorMessage = response.message
            return
          }
          this.videoDetailed = response.video
          this.$emit('update:video', response.video)
          this.$nextTick(() => {
            if (this.hidden && this.videoProcessing) {
              this.$store.state.timeout = setTimeout(
                this.fetchVideoDetails,
                10000,
              )
            }
          })
        })
        .catch(() => {
          this.videoError = true
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  mounted() {
    if (this.video.dashManifest && this.video.dashManifest.url) {
      this.videoDetailed = this.video
    }
    if (this.hidden && this.videoProcessing) {
      this.$store.state.timeout = setTimeout(this.fetchVideoDetails, 10000)
    }
    if (this.video.status === 'error') {
      this.videoError = true
      this.videoErrorMessage = this.video.processingError
    }
  },
  beforeDestroy() {
    clearTimeout(this.$store.state.timeout)
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .list-text-block {
    min-height: 32px !important;
  }
  .v-list-item:hover::before {
    opacity: 0 !important;
  }
}

.censored {
  border: 1px solid;
  border-color: var(--v-primary-base);
}

.channel-block-list-item {
  max-width: 100%;

  .channel-block {
    &:hover {
      background-color: var(--v-background-lighten1);
    }
  }
}

.video-processing {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
</style>
