<template>
  <div>
    <v-card-title class="text-overline pb-2 text-break"
      >Exprimez vos convictions</v-card-title
    >
    <v-container class="merchandising-list pt-0 px-0 px-sm-4" fluid>
      <v-card class="pa-1" flat color="transparent">
        <v-slide-group>
          <v-slide-item
            v-for="product in products"
            :key="product.title"
            class="d-flex flex-column align-center mx-2 py-1 merchandising-item overflow-hidden"
          >
            <v-hover>
              <template v-slot="{ hover }">
                <v-card
                  :color="hover ? 'secondary lighten-1' : 'transparent'"
                  flat
                  class="px-1"
                  :href="product.url"
                  target="_blank"
                  rel="noopener nofollow"
                >
                  <v-img
                    :src="product.image"
                    class="merchandising-image rounded-0"
                    :alt="product.title"
                    contain
                  ></v-img>
                  <v-list-item
                    three-line
                    class="px-0 pt-1"
                    style="min-height: 0"
                  >
                    <v-list-item-content class="py-0">
                      <v-list-item-subtitle
                        class="text-decoration-none text--secondary text-center font-weight-light"
                        style="font-size: 0.8rem"
                      >
                        {{ product.title }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </template>
            </v-hover>
          </v-slide-item>
        </v-slide-group>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'ShopFeaturedList',
  data() {
    return {
      products: [
        {
          title: 'Sticker Brigitte 14-39 (x10)',
          image: 'https://static.crowdbunker.com/sticker-14-39-badge%20300.png',
          url: 'https://go.cbk.re/qwr',
        },
        {
          title: 'T-Shirt Man of the Year (noir)',
          image:
            'https://static.crowdbunker.com/Time%20Man%20of%20the%20Year%20300.jpg',
          url: 'https://go.cbk.re/keN',
        },
        {
          title: 'T-Shirt Man of the Year (blanc)',
          image:
            'https://static.crowdbunker.com/Time%20Man%20of%20the%20Year%20300.jpg',
          url: 'https://go.cbk.re/Brn',
        },
        {
          title: 'Sweat Man of the Year',
          image:
            'https://static.crowdbunker.com/sweat-man-of-the-year%20300.jpg',
          url: 'https://go.cbk.re/ItU',
        },
        {
          title: 'Tote bag Man of the Year',
          image: 'https://static.crowdbunker.com/totebag-300.jpg',
          url: 'https://go.cbk.re/MyS',
        },
      ],
    }
  },
}
</script>

<style scoped>
.merchandising-image {
  width: 100px;
  height: 130px;
}
.text-decoration-none {
  text-decoration: none;
}
.merchandising-item {
  width: 106px;
}
</style>
