<template>
  <v-container>
    <div v-if="noAliasForPostForNow" class="my-2">
      <template v-if="!small">
        <v-btn
          @click="showAliasDialog = true"
          depressed
          :disabled="aliases.length >= 1"
          color="info"
          small
        >
          {{ $tc('button.add-alias') | capitalize }}
        </v-btn>
      </template>
      <template v-else>
        <v-btn
          small
          rounded
          color="accent"
          @click="showAliasDialog = true"
          :disabled="onlyOneAliasAllowed"
          class="ml-3"
          depressed
        >
          {{ $tc('button.add-alias') | capitalize }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-chip
        v-for="alias in aliases"
        :key="alias"
        class="ma-1"
        :color="small ? 'info' : 'grey darken-3'"
        text-color="white"
      >
        @{{ alias }}
        <v-icon
          @click="$emit('remove-alias', alias)"
          small
          class="ml-1"
          color="white"
          >mdi-close-circle</v-icon
        >
      </v-chip>
    </div>
    <v-dialog v-model="showAliasDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ $tc('button.add-alias') }}
        </v-card-title>
        <v-card-text>
          <v-form ref="aliasFormModel">
            <text-field
              label="alias"
              :field="aliasForm.alias"
              prefix="@"
              @input="checkAliasAvailability"
              :counter="25"
              :rules="aliasForm.alias.rules"
            >
              <template #append>
                <v-progress-circular
                  indeterminate
                  size="20"
                  width="2"
                  color="white"
                  v-if="UIDCheckLoading"
                ></v-progress-circular>
                <v-icon v-else :color="aliasAvailable ? 'success' : 'error'">
                  {{ aliasAvailable ? 'mdi-check-bold' : 'mdi-close-thick' }}
                </v-icon>
              </template>
            </text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mt-1" text @click="cancelAliasDialog">
            {{ $tc('button.cancel') }}
          </v-btn>
          <v-btn
            class="mt-1"
            color="primary"
            text
            @click="addAlias"
            :disabled="!aliasAvailable"
          >
            {{ $tc('label.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { requestService } from '@/services/request.service.js'
import TextField from '@/components/form/TextField'
import Validation from '@/components/mixins/Validation'
export default {
  name: 'Aliases',
  components: {
    TextField,
  },
  mixins: [Validation],
  props: {
    aliases: {
      type: Array,
      default: () => [],
    },
    fromOrganization: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAliasDialog: false,
      aliasForm: {
        alias: {
          value: '',
          maxLength: 25,
          minLength: 3,
          rules: [
            v =>
              /^[a-z0-9_-]+$/i.test(v) ||
              this.$tc('organization.must-not-contain-letters'),
            v =>
              /^((?!Crowd.?Bunker|admin([^a-z]|$)).)*$/i.test(v) ||
              this.$tc('organization.must-not-contain-words'),
            v =>
              /^[a-z0-9]+.*[a-z0-9]+$/i.test(v) ||
              this.$tc('organization.must-not-start-or-end-with'),
            v =>
              /^((?![_-]{2,}).)*$/i.test(v) ||
              this.$tc('organization.must-not-contain-more-than'),
          ],
        },
      },
      aliasAvailable: false,
      UIDCheckLoading: false,
    }
  },
  computed: {
    onlyOneAliasAllowed() {
      return this.aliases.length >= 1
    },
    noAliasForPostForNow() {
      if (!this.fromOrganization) {
        return false
      } else return true
    },
  },
  methods: {
    addAlias() {
      // aliases.indexOf(alias)
      const validation = this.$refs.aliasFormModel.validate()
      if (
        this.aliasForm.alias.value.length < 3 ||
        this.aliasForm.alias.value.length > 25 ||
        !validation
      ) {
        this.aliasAvailable = false
        return
      }
      if (this.aliases.includes(this.aliasForm.alias.value)) {
        this.aliasAvailable = false
        return
      }
      this.$emit('add-alias', this.aliasForm.alias.value)
      this.cancelAliasDialog()
    },
    checkAliasAvailability() {
      this.aliasAvailable = false
      this.formValidation(this.aliasForm)
      const validation = this.$refs.aliasFormModel.validate()
      if (
        this.aliasForm.alias.errors ||
        this.aliasForm.alias.value.length < 3 ||
        this.aliasForm.alias.value.length > 25 ||
        !validation
      ) {
        this.aliasAvailable = false
        return
      }
      if (this.aliases.includes(this.aliasForm.alias.value)) {
        this.aliasAvailable = false
        return
      }
      this.UIDCheckLoading = true
      clearTimeout(this.UIDCheckTimeout)
      this.UIDCheckTimeout = setTimeout(() => {
        requestService
          .get(
            `/${
              this.fromOrganization ? 'organization' : 'post'
            }/check-alias-availability/${this.aliasForm.alias.value}`,
          )
          .then(response => {
            this.aliasAvailable = response.available
          })
          .finally(() => {
            this.UIDCheckLoading = false
          })
      }, 300)
    },
    cancelAliasDialog() {
      this.showAliasDialog = false
      this.aliasForm.alias.value = null
      this.aliasAvailable = false
      this.aliasForm.alias.errors = []
    },
  },
}
</script>

<style scoped></style>
