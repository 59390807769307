<template>
  <v-row class="justify-center">
    <v-col cols="12" class="px-sm-5">
      <v-row v-if="hasError && posts.length === 0 && !postsLoading">
        <v-col>
          <div class="text-h5 text-center primary--text">
            {{ $tc('error.an-error-has-occurred') }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <big-announcement-banner />
        </v-col>
      </v-row>
      <v-row v-if="featuredPosts.length || featuredPostsLoading">
        <v-col>
          <div class="overline">
            {{ $tc('label.featured-posts') }}
          </div>
          <v-row>
            <post-item
              v-for="post in featuredPosts"
              :key="post.uid"
              :post="post"
              cols="12"
              sm="6"
              md="4"
              xl="3"
              show-organization
            ></post-item>
            <template v-if="featuredPostsLoading">
              <post-item-loader
                v-for="item in 2"
                :key="item"
                cols="12"
                sm="6"
                md="4"
                xl="3"
              ></post-item-loader>
            </template>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!--          <div class="overline">{{ $tc('home.in-the-spotlight') }}</div>-->
          <v-row class="mb-1">
            <v-col
              v-if="!hasError && $store.state.account.status.loggedIn"
              cols="12"
              class="pb-0 d-flex align-center"
            >
              <section-select v-model="sectionSelected"></section-select>
              <v-chip-group
                v-if="sectionSelected !== 'discover'"
                v-model="tagSelected"
                active-class="success--text"
                class="ml-1"
              >
                <v-chip color="accent" class="ml-1" value="most_recent">{{
                  $t('home.most-recent')
                }}</v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <post-grid
            :posts="posts"
            :loading="postsLoading"
            simplified
          ></post-grid>
          <v-col
            cols="12"
            sm="6"
            md="4"
            xl="3"
            v-intersect="{
              handler: onIntersectEnd,
              options: {
                rootMargin: '-50% 0px 500px 0px',
                threshold: [0, 1],
              },
            }"
            style="min-height: 50px"
          >
            <div style="min-height: 50px"></div>
          </v-col>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="!endReached && !hasError">
        <v-btn
          color="primary"
          :loading="postsLoading"
          @click="fetchPosts"
          depressed
          rounded
          >{{ $tc('button.see-more') }}
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue'
import { requestService } from '@/services/request.service.js'
import PostItem from '@/components/PostItem'
import PostItemLoader from '@/components/PostItemLoader'
import PostGrid from '@/components/post/PostGrid.vue'
import SectionSelect from '@/components/home/SectionSelect.vue'
import BigAnnouncementBanner from '@/components/home/BigAnnouncementBanner.vue'

export default Vue.extend({
  name: 'Home',
  components: {
    SectionSelect,
    PostGrid,
    PostItemLoader,
    PostItem,
    BigAnnouncementBanner,
  },
  data() {
    return {
      hasError: false,
      posts: [],
      censoredVideos: [],
      postsLoading: false,
      censoredVideosLoading: false,
      queryLast: null,
      endReached: false,
      censoredLimitByBreakpoint: {
        xl: 6,
        lg: 4,
        md: 3,
        sm: 2,
        xs: 2,
      },
      featuredPostsLoading: false,
      featuredPosts: [],
      trendingPostsLoading: false,
      trendingPosts: [],
      showCensoredVideos: false,
      fundItems: [],
      fundItemsLoading: false,
      sessionId: null,
      recommendationsPage: 0,
      sectionSelected: 'for-you',
      tagSelected: null,
    }
  },
  methods: {
    formatDate(date, hour = false) {
      return new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: hour ? 'numeric' : undefined,
        minute: hour ? 'numeric' : undefined,
      }).format(date)
    },
    async fetchPosts(replace = false) {
      this.postsLoading = true
      this.hasError = false
      if (replace) {
        this.recommendationsPage = 0
        this.$store.state.loading = true
      }
      const urlForSection = {
        'for-you': '/post/home/recommendations',
        popular: '/post/popular',
        discover: '/post/discover',
      }
      const defaultUrl = this.$store.state.account.status.loggedIn
        ? urlForSection['for-you']
        : urlForSection['popular']
      const url = urlForSection[this.sectionSelected] || defaultUrl
      requestService
        .get(url, {
          params: {
            // after: this.queryLast,
            sid: this.sessionId || null,
            page: this.recommendationsPage + 1,
            tag: this.tagSelected || null,
          },
        })
        .then(response => {
          if (this.posts.length && this.recommendationsPage > 0 && !replace) {
            this.posts.push(...response.posts)
          } else {
            this.posts = response.posts
          }

          this.endReached = response.posts.length === 0
          // if (response.last !== null) {
          //   this.queryLast = response.last
          // }
          // else {
          //   this.endReached = true
          // }
          if (response.sid && this.sessionId !== response.sid) {
            this.sessionId = response.sid
            sessionStorage.setItem('sessionId', response.sid)
          }
          this.recommendationsPage = response.page
          this.$store.state.lastVideos = this.posts
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.postsLoading = false
          this.$store.state.loading = false
        })
    },
    async fetchCensoredVideos() {
      this.censoredVideosLoading = true
      // this.hasError = false
      let limit = this.censoredLimitByBreakpoint[this.$vuetify.breakpoint.name]
      limit = Math.min(limit, 6)
      requestService
        .get(process.env.VUE_APP_API_BASE_URL + '/post/censored', {
          params: {
            limit: limit,
          },
        })
        .then(response => {
          const posts = response.posts.slice(0, 6)
          this.censoredVideos = posts
          this.$store.state.lastCensoredVideos = posts
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.censoredVideosLoading = false
        })
    },
    async fetchFeaturedPosts() {
      this.featuredPostsLoading = true
      requestService
        .get(process.env.VUE_APP_API_BASE_URL + '/post/featured', {
          params: {
            limit: 4,
          },
        })
        .then(response => {
          const posts = response.posts.slice(0, 6)
          this.featuredPosts = posts
          this.$store.state.lastFeaturedPosts = posts
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.featuredPostsLoading = false
        })
    },
    async fetchTrendingPosts() {
      this.trendingPostsLoading = true
      requestService
        .get(process.env.VUE_APP_API_BASE_URL + '/post/trending', {
          params: {
            limit: 8,
          },
        })
        .then(response => {
          const posts = response.posts.slice(0, 8)
          this.trendingPosts = posts
          this.$store.state.lastTrendingPosts = posts
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.trendingPostsLoading = false
        })
    },
    async fetchFundItems() {
      this.fundItemsLoading = true
      requestService
        .get(process.env.VUE_APP_API_BASE_URL + '/fund/all', {
          params: {
            limit: 4,
          },
        })
        .then(response => {
          this.fundItems = response.funds.slice(0, 4)
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.fundItemsLoading = false
        })
    },
    onIntersectEnd() {
      if (this.postsLoading || this.endReached || this.hasError) {
        return
      }
      this.fetchPosts()
    },
    loadSavedVideos() {
      // setTimeout(() => {
      const savedVideosList = this.$store.state.lastVideos
      if (savedVideosList.length) {
        this.posts = savedVideosList
      }
      if (this.$store.state.lastVideos.length) {
        this.censoredVideos = this.$store.state.lastCensoredVideos
      }
      if (this.$store.state.lastTrendingPosts.length) {
        this.trendingPosts = this.$store.state.lastTrendingPosts
      }
      // }, 60)
    },
  },
  watch: {
    sectionSelected(value) {
      this.fetchPosts(true)
      this.$store.state.preferredHomeSection = value
    },
    tagSelected() {
      this.fetchPosts(true)
    },
    '$store.state.account.user.user'(newValue, oldValue) {
      if (newValue?.username === oldValue?.username) {
        return
      }
      this.fetchPosts(true)
    },
  },
  mounted() {
    this.$nextTick(this.loadSavedVideos)
    if (this.sessionId === null) {
      this.sessionId = sessionStorage.getItem('sessionId')
    }
  },
  created() {
    if (
      this.$store.state.preferredHomeSection &&
      this.$store.state.preferredHomeSection !== this.sectionSelected
    ) {
      this.sectionSelected = this.$store.state.preferredHomeSection
    } else {
      this.$nextTick(this.fetchPosts)
    }
    // if (this.showCensoredVideos) {
    //   this.$nextTick(this.fetchCensoredVideos)
    // }
    // this.$nextTick(this.fetchFeaturedPosts)
    // this.$nextTick(this.fetchTrendingPosts)
  },
})
</script>

<style lang="scss" scoped>
.home {
  //position: absolute;
  width: 100%;
}

::v-deep {
  .v-list-item:hover::before {
    opacity: 0 !important;
  }

  .v-skeleton-loader__image {
    height: 100%;
  }

  .v-skeleton-loader__list-item-avatar {
    height: 40px;

    .v-skeleton-loader__avatar {
      height: 30px !important;
      width: 30px !important;
    }
  }
}
</style>
