<template>
  <v-speed-dial
    v-model="fab"
    absolute
    direction="top"
    elevation="5"
    bottom
    right
    fixed
    mode="mode"
    origin="origin"
    transition="slide-y-reverse-transition"
    value="value"
  >
    <template v-slot:activator>
      <app-tooltip>
        <template v-slot:activator="{ on, attrs }">
          <v-fab-transition>
            <v-btn
              v-model="fab"
              color="primary"
              dark
              :large="$vuetify.breakpoint.mdAndUp"
              fab
              v-on="on"
              v-bind="attrs"
            >
              <v-icon v-if="fab">
                mdi-notebook-minus
              </v-icon>
              <v-icon v-else>
                mdi-notebook-plus
              </v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        <span>{{ $tc('note.add-note') }}</span>
      </app-tooltip>
    </template>
    <v-card
      color="background"
      :ripple="false"
      :link="false"
      @click.stop="test($event)"
    >
      <v-toolbar dark color="secondary" flat dense>
        <v-toolbar-title>
          {{ $tc('note.my-notes') }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" icon dark @click.stop="cancel()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <v-card-title class="pa-0 pt-1">
        <v-text-field placeholder="Search" solo hide-details dense>
          <template #prepend-inner>
            <v-btn
              icon
              @click="saveNote"
              :loading="loading"
              :disabled="form.textContent.value.length === 0"
            >
              <v-icon small>mdi-magnify</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-card-title> -->
      <v-card-text
        class="overflow-auto"
        color="secondary"
        style="max-height: 400px !important;"
      >
        <template v-if="loading">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="5"
          ></v-progress-linear>
        </template>
        <template v-else-if="!loading && notes.length">
          <v-sheet
            v-for="note of notes"
            :key="note.id"
            class="my-2 pa-2"
            color="secondary lighten-3"
            rounded
          >
            <p class="text-subtitle-2 font-weight-medium font-italic mb-0">
              {{ formatDate(note.createdAt) }}
            </p>
            <p class="mb-0">
              {{ note.content }}
            </p>
          </v-sheet>
        </template>
        <template v-else>
          <p class="text-center">
            {{ $tc('note.no-note-on-this-post') }}
          </p>
        </template>
      </v-card-text>
      <v-card-actions>
        <textarea-field
          solo-inverted
          flat
          :placeholder="'Écrivez une note...'"
          background-color="secondary lighten-2"
          auto-grow
          :counter="200"
          :field="form.textContent"
          rows="1"
          dense
          @keydown.enter.prevent.stop="submit"
          class="text-field"
          ref="textField"
        >
          <template #append>
            <v-btn
              small
              icon
              @click="saveNote"
              :loading="submitLoading"
              :disabled="form.textContent.value.length === 0"
            >
              <v-icon small>mdi-send</v-icon>
            </v-btn>
          </template>
          <template #append-outer> </template>
        </textarea-field>
        <p v-if="error" class="error--text">
          {{ error }}
        </p>
      </v-card-actions>
    </v-card>
  </v-speed-dial>
</template>
<script>
import AppTooltip from '@/components/AppTooltip.vue'
import TextareaField from '@/components/form/TextareaField'
import { requestService } from '@/services/request.service'
import { utilsService } from '@/services/utils.service'
import Validation from '@/components/mixins/Validation'

export default {
  name: 'UserNote',
  components: {
    AppTooltip,
    TextareaField,
  },
  mixins: [Validation],
  props: {
    post: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      notes: [],
      fab: false,
      loading: false,
      submitLoading: false,
      form: {
        textContent: {
          value: '',
          maxLength: 200,
          // required: true,
        },
      },
      error: null,
    }
  },
  methods: {
    formatDate(date) {
      return new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(date))
    },
    test(event) {
      console.log(event.preventDefault())
    },
    cancel() {
      this.fab = false
      this.form.textContent.value = ''
    },
    retrieveNotes() {
      this.loading = true
      requestService
        .get(`/post/${this.post.uid}/notes`)
        .then(res => {
          if (!res.success) {
            this.$store.dispatch(
              'alert/error',
              this.$tc('note.unable-to-get-notes'),
            )
            return
          }
          this.notes = res.notes
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch(
            'alert/error',
            this.$tc('error.an-error-has-occurred'),
          )
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveNote() {
      this.error = null
      this.formValidation(this.form, true)
      if (this.errorsNbr > 0 || this.loading) {
        return
      }
      this.submitLoading = true
      requestService
        .post(`/post/${this.post.uid}/notes/add`, {
          note: this.form.textContent.value,
        })
        .then(res => {
          if (!res.success) {
            this.$store.dispatch(
              'alert/error',
              this.$tc('error.unable-to-save-note'),
            )
            return
          }
          this.form.textContent.value = ''
          this.notes.unshift(res.note)

          this.$store.dispatch('alert/success', this.$tc('note.note-saved'))
        })
        .catch(err => {
          console.error(err)
          this.$store.dispatch(
            'alert/error',
            this.$tc('error.an-error-has-occurred'),
          )
        })
        .finally(() => {
          this.submitLoading = false
        })
    },
    // async saveNote() {
    //   this.loading = true
    //   try {
    //     await this.$store.dispatch('user/saveNote', {
    //       userId: this.user.id,
    //       note: this.note,
    //     })
    //     this.$emit('note-saved')
    //   } catch (e) {
    //     console.error(e)
    //   } finally {
    //     this.loading = false
    //   }
    // },
  },
  mounted() {
    this.retrieveNotes()
  },
}
</script>
<style scoped lang="scss">
::v-deep {
  .v-speed-dial__list {
    left: auto;
    right: 0px;
    width: 350px;
  }

  @media (max-width: 400px) {
    .v-speed-dial__list {
      width: 300px !important;
    }
  }
}
@media (max-width: 959px) {
  .v-speed-dial {
    bottom: 70px !important;
  }
}
</style>
