import i18n from '../plugins/i18n'

function formatPlatform(name) {
  const formatted = {
    youtube: {
      text: 'YouTube',
    },
    internal: {
      text: 'CrowdBunker',
    },
  }
  return formatted[name] !== undefined
    ? formatted[name]
    : {
        text: name,
      }
}

function formatDateWithLocale(date, includeHour = true) {
  if (date === undefined) {
    return
  }
  if (typeof date.getMonth !== 'function') {
    date = new Date(date)
  }

  const lang = i18n.locale

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: includeHour ? '2-digit' : undefined,
    minute: includeHour ? '2-digit' : undefined,
  }

  return new Intl.DateTimeFormat(lang, options).format(date)
}

function formatDate(date, hour = false) {
  if (date === undefined) {
    return
  }
  if (typeof date.getMonth !== 'function') {
    date = new Date(date)
  }
  return new Intl.DateTimeFormat('fr', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: hour ? 'numeric' : undefined,
    minute: hour ? 'numeric' : undefined,
  }).format(date)
}

function formatDateRelative(date) {
  if (date === undefined) {
    return ''
  }
  if (typeof date.getMonth !== 'function') {
    date = new Date(date)
  }
  const delta = Math.round((+new Date() - date) / 1000)

  const minute = 60,
    hour = minute * 60,
    day = hour * 24,
    week = day * 7,
    month = day * 30,
    year = day * 365

  let fuzzy

  if (delta < 30) {
    fuzzy = i18n.t('time.just-now')
  } else if (delta < minute) {
    fuzzy = i18n.t('time.a-few-seconds-ago')
  } else if (delta < 2 * minute) {
    fuzzy = i18n.t('time.a-minute-ago')
  } else if (delta < hour) {
    fuzzy = i18n.t('time.time-minutes-ago', {
      minutes: Math.floor(delta / minute),
    })
  } else if (Math.floor(delta / hour) === 1) {
    fuzzy = i18n.t('time.an-hour-ago')
  } else if (delta < day) {
    fuzzy = i18n.t('time.time-hours-ago', {
      hours: Math.floor(delta / hour),
    })
  } else if (delta < day * 2) {
    fuzzy = i18n.t('time.a-day-ago')
  } else if (delta < week) {
    fuzzy = i18n.t('time.time-days-ago', {
      days: Math.floor(delta / day),
    })
  } else if (delta < week * 2) {
    fuzzy = i18n.t('time.a-week-ago')
  } else if (delta < month) {
    fuzzy = i18n.t('time.time-weeks-ago', {
      weeks: Math.floor(delta / week),
    })
  } else if (delta < year) {
    fuzzy = i18n.t('time.time-months-ago', {
      months: Math.round(delta / month),
    })
  } else if (delta < year * 2) {
    fuzzy = i18n.t('time.a-year-ago')
  } else {
    fuzzy = i18n.t('time.time-years-ago', {
      years: Math.round(delta / year),
    })
  }

  return fuzzy
}

function formatScheduledTime(scheduledStartTime) {
  const now = new Date()
  const scheduledTime = new Date(scheduledStartTime)
  const timeDiff = scheduledTime - now
  const hoursDiff = Math.floor(timeDiff / 3600000)
  const minutesDiff = Math.round((timeDiff % 3600000) / 60000)

  if (!scheduledStartTime || scheduledTime < now) {
    return i18n.t('live.scheduled-imminent')
  }

  if (scheduledTime.toDateString() !== now.toDateString()) {
    const formattedDate = utilsService.formatDate(scheduledStartTime, false)
    const timeString = scheduledTime.toTimeString().substr(0, 5)
    return i18n.t('live.scheduled-on', {
      date: formattedDate,
      time: timeString,
    })
  }

  if (hoursDiff >= 1) {
    const timeString = scheduledTime.toTimeString().substr(0, 5)
    return i18n.t('live.scheduled-at', { time: timeString })
  } else if (minutesDiff > 0) {
    return i18n.t('live.scheduled-in-minutes', { minutes: minutesDiff })
  }

  return i18n.t('live.scheduled-now')
}

function formatDateRelativeRemaining(date) {
  if (date === undefined) {
    return ''
  }
  if (typeof date.getMonth !== 'function') {
    date = new Date(date)
  }
  const delta = Math.round((date - new Date()) / 1000)

  // If the date is in the past
  if (delta < 0) {
    return i18n.t('time.completed')
  }

  const minute = 60,
    hour = minute * 60,
    day = hour * 24,
    week = day * 7,
    month = day * 30,
    year = day * 365

  let fuzzy

  if (delta < 30) {
    fuzzy = i18n.t('time.a-few-seconds-remaining')
  } else if (delta < minute) {
    fuzzy = i18n.t('time.time-seconds-remaining', {
      seconds: delta,
    })
  } else if (delta < 2 * minute) {
    fuzzy = i18n.t('time.a-minute-remaining')
  } else if (delta < hour) {
    fuzzy = i18n.t('time.time-minutes-remaining', {
      minutes: Math.floor(delta / minute),
    })
  } else if (Math.floor(delta / hour) === 1) {
    fuzzy = i18n.t('time.an-hour-remaining')
  } else if (delta < day) {
    fuzzy = i18n.t('time.time-hours-remaining', {
      hours: Math.floor(delta / hour),
    })
  } else if (delta < day * 2) {
    fuzzy = i18n.t('time.a-day-remaining')
  } else if (delta < week) {
    fuzzy = i18n.t('time.time-days-remaining', {
      days: Math.floor(delta / day),
    })
  } else if (delta < week * 2) {
    fuzzy = i18n.t('time.a-week-remaining')
  } else if (delta < month) {
    fuzzy = i18n.t('time.time-weeks-remaining', {
      weeks: Math.floor(delta / week),
    })
  } else {
    fuzzy = i18n.t('time.more-than-a-month-remaining')
  }

  return fuzzy
}

function truncate(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

function formatNumber(number, long = false) {
  // Determine the sign
  const sign = number < 0 ? '-' : ''

  // Use the absolute value for formatting
  const absNumber = Math.abs(number)

  if (long) {
    return sign + new Intl.NumberFormat('fr-FR').format(absNumber)
  }

  if (absNumber < 1000) {
    return sign + absNumber
  } else if (absNumber < 1000000) {
    return sign + (absNumber / 1000).toFixed(1) + ' k'
  } else if (absNumber < 1000000000) {
    return sign + (absNumber / 1000000).toFixed(1) + ' m'
  }
}

function formatDuration(seconds) {
  return new Date(1000 * seconds)
    .toISOString()
    .substr(11, 8)
    .replace(/^0(0:)?0?/, '')
}

function formatCurrency(amount, fractionDigits = 0, currency = 'EUR') {
  let result = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: currency,
    // maximumFractionDigits: fractionDigits, NOT SUPPORTED IN SOME BROWSERS
  }).format(amount)

  if (fractionDigits === 0) {
    result = result.replace(/,[0-9]+/gm, '')
  }

  return result
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const utilsService = {
  formatPlatform,
  formatDate,
  formatDateRelative,
  truncate,
  formatNumber,
  formatDuration,
  formatCurrency,
  formatDateRelativeRemaining,
  capitalizeFirstLetter,
  formatScheduledTime,
  formatDateWithLocale,
}

export {
  formatPlatform,
  formatDate,
  formatDateRelative,
  truncate,
  formatNumber,
  formatDuration,
  formatCurrency,
  formatDateRelativeRemaining,
  capitalizeFirstLetter,
  formatDateWithLocale,
}
