<template>
  <v-card style="overflow:hidden">
    <v-card-title class="d-flex d-sm-none">
      <v-btn icon dark @click="cancel">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        small
        :loading="submitLoading"
        :disabled="importVideo && form.video.videoKey.value === null"
        @click="submit"
        depressed
        >{{ $tc('button.publish') }}
      </v-btn>
    </v-card-title>
    <template v-if="internalChannels.length">
      <v-card-text class="mt-sm-4">
        <div v-if="currentInternalChannel" class="d-flex">
          <channel-avatar
            :thumbnails="currentInternalChannel.thumbnails"
            :size="45"
            :channel-name="currentInternalChannel.name"
            bg-color="primary"
            :object="currentInternalChannel"
          ></channel-avatar>
          <v-select
            :label="
              `${$tc('button.publish')} ${$t('chat.as')}` | capitalize_first
            "
            :items="internalChannels"
            v-model="currentInternalChannel"
            class="ml-4 flex-grow-1 text-truncate"
            :item-text="formatItemText"
            item-value="uniqueKey"
            return-object
            :error-messages="this.getErrors(form.channel.errors)"
          ></v-select>
          <v-btn
            v-if="importVideo"
            class="ml-6"
            color="primary"
            outlined
            icon
            @click="cancelUpload"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div v-if="!currentInternalChannel.monetizationActive">
          <monetization-alert
            :organization="currentInternalChannel.organization"
          />
        </div>
      </v-card-text>
      <v-fade-transition leave-absolute>
        <template v-if="!importVideo">
          <v-card-text class="py-0">
            <v-row class="mt-1">
              <v-col cols="12" class="py-0">
                <textarea-field
                  solo-inverted
                  flat
                  :placeholder="$tc('login.express-yourself-freely')"
                  background-color="secondary lighten-2"
                  style="font-size: 20px"
                  :hide-details="
                    form.textContent.value.length < 4800 &&
                      !form.textContent.errors
                  "
                  :rows="
                    Math.max(
                      5,
                      form.textContent.value.split(`\n`).length + 1 || 0,
                    )
                  "
                  :counter="
                    form.textContent.value.length > 4800 ? 5000 : undefined
                  "
                  :field="form.textContent"
                  autofocus
                  no-resize
                >
                </textarea-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-2">
                <v-select
                  v-model="form.visibility.value"
                  :items="visibilityChoices"
                  :label="$tc('live.visibility') | capitalize"
                ></v-select>
              </v-col>
              <!-- Aliases disabled -->
              <!-- <v-col cols="12" class="py-0">
                <aliases
                  :aliases="form.aliases.value"
                  :fromOrganization="false"
                  :small="true"
                  @add-alias="addAlias"
                  @remove-alias="removeAlias"
                ></aliases>
              </v-col> -->
            </v-row>
          </v-card-text>
        </template>
      </v-fade-transition>
      <v-expand-transition leave-absolute>
        <template v-if="importVideo">
          <v-card-text>
            <!-- No Alias -->
             <!-- :aliases.sync="form.aliases.value" -->
            <create-video
              :title.sync="form.video.title"
              :visibility.sync="form.visibility"
              :description.sync="form.video.description"
              :videoKey.sync="form.video.videoKey.value"
              :thumbnailKey.sync="form.video.thumbnailKey.value"
              :posterKey.sync="form.video.posterKey.value"
              :language.sync="form.language"
              :categoriesList="categories"
              :selected-categories.sync="form.categories"
              :video-form.sync="form.video"
              :channel="currentInternalChannel"
              :published-at.sync="form.publishedAt.value"
              :retrievable.sync="form.video.retrievable"
              :visibility-choices="visibilityChoices"
              :captions.sync="form.video.captions.value"
              @updateVisibility="updateVisibilityChoices"
              :submitted="submitted"
              @error="error = $event"
              @uploaded="submit"
              :platform.sync="form.video.platform.value"
              :externalVideoUrl.sync="form.video.externalUrl"
              @checkExternalVideoUrl="checkExternalVideoUrl"
            ></create-video>
          </v-card-text>
        </template>
        <template v-else-if="importImages">
          <v-card-text class="mt-3">
            <import-images
              @cancelImport="cancelUpload"
              :files.sync="images"
            ></import-images>
          </v-card-text>
        </template>
        <template v-else-if="snippetData || snippetLoading">
          <v-card-text class="mt-3">
            <snippet
              :link-metadata="snippetData"
              horizontal
              preview
              @cancel="cancelSnippet"
            ></snippet>
          </v-card-text>
        </template>
      </v-expand-transition>
      <v-card-text v-if="error" class="error--text">{{ error }}</v-card-text>
      <v-card-text
        class="d-flex justify-end mt-1"
        v-if="!upload && snippetData === null && !snippetLoading"
      >
        <v-btn
          class="ml-3"
          color="primary"
          outlined
          icon
          @click="startImportImages"
        >
          <v-icon>mdi-image</v-icon>
        </v-btn>
        <v-btn
          class="ml-3"
          color="primary"
          outlined
          icon
          @click="startImportVideo"
        >
          <v-icon>mdi-video</v-icon>
        </v-btn>
      </v-card-text>
      <v-divider class="d-none d-sm-block"></v-divider>
      <v-card-actions v-if="$vuetify.breakpoint.smAndUp || importVideo">
        <v-btn text class="ml-auto" @click="cancel">{{
          $tc('button.cancel')
        }}</v-btn>
        <v-btn
          text
          color="success"
          :loading="submitLoading"
          :disabled="
            (importVideo &&
              form.video.videoKey.value === null &&
              !!form.video.externalUrl.value === false) ||
              (importVideo &&
                !form.video.externalUrl.valid &&
                !!form.video.videoKey.value === false) ||
              submitted
          "
          @click="submit"
          >{{
            submitted &&
            importVideo &&
            form.video.videoKey.value === null &&
            !isExternalVideo
              ? $tc('button.publish-at-end-of-upload')
              : $tc('button.publish')
          }}
        </v-btn>
      </v-card-actions>
    </template>
    <template v-else-if="datasLoading">
      <v-card-text class="d-flex justify-center py-10">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </template>
    <template v-else>
      <v-card-text class="d-flex justify-center py-10 flex-column">
        <p class="text-body-1 text-center">
          {{ $tc('channel.create-crowdbunker-channel-post') }}
        </p>
        <div class="text-center">
          <div>
            <v-btn color="primary" @click="$root.$emit('createChannel')">
              <v-icon left>mdi-plus</v-icon>
              {{ $tc('button.create-channel') }}
            </v-btn>
          </div>
          <div>
            <v-btn text @click="cancel" class="mt-2">
              {{ $tc('button.cancel') }}
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import { requestService } from '@/services/request.service'
import ChannelAvatar from '@/components/ChannelAvatar'
import TextareaField from '@/components/form/TextareaField'
import Validation from '@/components/mixins/Validation'
import CreateVideo from '@/components/CreateVideo'
import { uppyService } from '@/services/uppy.service'
import ImportImages from '@/components/ImportImages'
import Snippet from '@/components/Snippet'
import organization from '@/views/Organization.vue'
import MonetizationAlert from '@/components/MonetizationAlert.vue'
// import Aliases from '@/components/Aliases'
// import { capitalize } from 'vuetify-loader/lib/util'

const textContent = {
  value: '',
  maxLength: 5000,
  minLength: 3,
  required: true,
}

const visibility = {
  value: 'public',
}

export default {
  name: 'PostMaker',
  components: {
    MonetizationAlert,
    Snippet,
    ImportImages,
    CreateVideo,
    TextareaField,
    ChannelAvatar,
    // Aliases,
  },
  mixins: [Validation],
  props: {
    value: { type: Boolean, default: false },
    modal: { type: Boolean, default: false },
  },
  data() {
    return {
      importVideo: false,
      importImages: false,
      upload: false,
      internalChannels: [],
      currentInternalChannel: null,
      datasLoading: false,
      visibilityChoices: [
        {
          text: this.$options.filters.capitalize(this.$tc('live.public')),
          value: 'public',
        },
        {
          text: this.$options.filters.capitalize(this.$tc('live.unlisted')),
          value: 'unlisted',
        },
      ],
      form: {
        textContent: textContent,
        channel: {
          value: null,
          required: true,
        },
        visibility: visibility,
        video: {
          description: textContent,
          platform: {
            value: '',
          },
          externalUrl: {
            value: '',
            valid: false,
            errors: [],
            loading: false,
          },
          channel: {
            value: null,
          },
          videoKey: {
            value: '',
          },
          thumbnailKey: {
            value: '',
          },
          posterKey: {
            value: '',
          },
          title: {
            value: '',
            required_if: ['this.importVideo', true],
            maxLength: 100,
            minLength: 3,
            regex: [/^(?!.*\.mp4$).*/i],
            regexMessage: [
              this.$t('error.should-not-end-with', { value: '.mp4' }),
            ],
          },
          retrievable: {
            value: true,
          },
          visibility: visibility,
          price: {
            value: 'free',
          },
          sellPrice: {
            value: '5',
            min: 2,
            max: 200,
            regex: /^\d+(\.\d{1,2})?$/,
          },
          rentPrice: {
            value: '',
            min: 2,
            max: 20,
            regex: /^\d+(\.\d{1,2})?$/,
          },
          presaleMode: {
            value: false,
          },
          releaseDate: {
            value: '',
          },
          captions: {
            value: [],
          },
          inSecondDuration: {
            value: '',
          },
        },
        linkMetadata: {
          value: '',
        },
        language: {
          value: '',
        },
        categories: {
          value: '',
        },
        publishedAt: {
          value: '',
        },
        // No aliases for now
        // aliases: {
        //   value: [],
        // },
      },
      submitLoading: false,
      error: null,
      images: [],
      checkLinksTimeout: null,
      blacklistedLinks: [],
      snippetData: null,
      snippetLoading: false,
      lastLinksCount: 0,
      lastLink: null,
      categoriesList: [],
      allowedChannelsForCategories: [],
      submitted: false,
    }
  },
  computed: {
    organization() {
      return organization
    },
    categories() {
      if (
        this.allowedChannelsForCategories.includes(
          this.currentInternalChannel?.id,
        )
      ) {
        return [
          {
            text: this.$options.filters.capitalize(this.$t('label.default')),
            value: '',
          },
        ].concat(
          this.categoriesList.map(category => ({
            text: this.$t('categories.' + category.slug),
            value: category.slug,
          })),
        )
      }
      return []
    },
    isExternalVideo() {
      if (
        this.form.video.platform.value?.trim() &&
        this.form.video.externalUrl.value?.trim()
      ) {
        return true
      }
      return false
    },
  },
  methods: {
    // addAlias(alias) {
    //   this.form.aliases.value.push(alias)
    // },
    // removeAlias(alias) {
    //   this.form.aliases.value.splice(this.form.aliases.value.indexOf(alias), 1)
    // },
    checkExternalVideoUrl() {
      this.form.video.externalUrl.errors = []
      //check if this.form.video.externalUrl.value exist and is valid url through regex
      if (
        !this.form.video.externalUrl.value ||
        !this.form.video.externalUrl.value.match(
          /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/,
        )
      ) {
        this.form.video.externalUrl.valid = false
        this.form.video.externalUrl.errors = [
          this.$t('error.invalid-url-format'),
        ]
        return
      }

      this.form.video.externalUrl.loading = true
      requestService
        .get(
          `/video/external-url/verify?externalUrl=${encodeURIComponent(
            this.form.video.externalUrl.value,
          )}&platform=${this.form.video.platform.value}`,
        )
        .then(res => {
          if (res && res.success) {
            this.form.video.externalUrl.valid = true
            this.form.video.externalUrl.errors = []
            this.form.video.inSecondDuration.value = Math.floor(res.duration)
          } else {
            this.form.video.externalUrl.valid = false
            this.$store.dispatch(
              'alert/error',
              res.message ?? this.$tc('error.content-not-found'),
            )
          }
        })
        .catch(err => {
          this.form.video.externalUrl.valid = false
          this.$store.dispatch(
            'alert/error',
            err.message ?? this.$tc('error.content-not-found'),
          )
        })
        .finally(() => {
          this.form.video.externalUrl.loading = false
        })
    },
    formatItemText(item) {
      const isMobile = this.$vuetify.breakpoint.smAndDown
      if (isMobile) {
        return `${item.name} (${item.organization.name})`
      } else {
        return `${item.name} (${this.$options.filters.capitalize(
          this.$tc('channel.organization'),
        )} ${item.organization.name})`
      }
    },
    async fetchInternalChannels() {
      this.datasLoading = true
      requestService
        .get('/channel/mine/internal')
        .then(response => {
          this.internalChannels = response.items
          this.$store.commit('account/internalChannels', response.items)
          this.autoSelectChannel()
          this.categoriesList = Array.isArray(response.categories)
            ? response.categories
            : []
          this.allowedChannelsForCategories = Array.isArray(
            response.allowedChannelsForCategories,
          )
            ? response.allowedChannelsForCategories
            : []
        })
        .finally(() => (this.datasLoading = false))
    },
    submit() {
      if (
        this.importVideo &&
        this.form.video.videoKey.value === null &&
        !this.isExternalVideo
      ) {
        this.submitted = true
        return
      }
      this.error = null
      if (this.images.length) {
        this.form.images = {
          value: [],
        }
        for (const image of this.images) {
          this.form.images.value.push(image.key)
        }
        this.form.textContent.required = false
      } else {
        this.form.textContent.required = true
      }
      this.formValidation(this.form, true)
      if (this.errorsNbr > 0) {
        return
      }

      const submittedForm = JSON.parse(JSON.stringify(this.form))
      if (!this.importVideo) {
        submittedForm.video.title.value = ''
        submittedForm.video.videoKey.value = ''
        submittedForm.video.description.value = ''
      } else {
        submittedForm.textContent.value = ''
      }
      submittedForm.categories.value = [submittedForm.categories.value]
      this.submitLoading = true
      const url = '/post/create'
      requestService
        .post(url, this.formatDataToSend(submittedForm))
        .then(response => {
          if (response.uid) {
            this.$store.dispatch(
              'alert/success',
              this.$tc('label.successful-publication'),
            )
            this.form.textContent.value = ''
            this.$emit('edited', response)
            this.$root.$emit('postCreated', response)
            this.$emit('input', false)
          }
        })
        .catch(response => {
          console.log('error response', response)
          this.submitLoading = false
          const { error, validationFailed } = response
          if (validationFailed) {
            const errorMessage = response.error.errors.errors[0]
            if (
              errorMessage.includes(
                'Une suspension de publication est en cours',
              ) ||
              errorMessage.includes(
                'Ein Veröffentlichungsverbot gilt bis zum',
              ) ||
              errorMessage.includes('A posting ban is in effect until')
            ) {
              this.error = errorMessage
            } else {
              this.handleServerErrors(error, this.form)
            }
          } else if (error.detail) {
            this.error = error.detail
          }
        })
        .finally(() => (this.submitLoading = false))
    },
    autoSelectChannel() {
      if (this.internalChannels.length) {
        this.currentInternalChannel = this.internalChannels[0]
        if (
          typeof this.currentInternalChannel === 'object' &&
          this.currentInternalChannel.uniqueKey
        ) {
          this.form.channel.value = this.currentInternalChannel.uniqueKey
          this.form.video.channel.value = this.currentInternalChannel.uniqueKey
        }
      }
    },
    fillVisibilityChoices(val) {
      if (val !== 'free') {
        this.visibilityChoices = [
          {
            text: this.$options.filters.capitalize(this.$tc('live.public')),
            value: 'public',
          },
          {
            text: this.$options.filters.capitalize(this.$tc('live.unlisted')),
            value: 'unlisted',
          },
        ]
        this.form.video.visibility.value = 'public'
        return
      }
      const membershipGroups =
        this.currentInternalChannel.organization.membershipGroups ?? []

      if (membershipGroups.length) {
        for (const group of membershipGroups) {
          this.visibilityChoices.push({
            text: this.$options.filters.capitalize(
              this.$tc('live.membership-group') + (group.membershipLevel ?? ''),
            ),
            value: 'membership', // dynamic value: 'membership:' + group.id,
          })
        }
      }
    },
    startImportVideo() {
      this.upload = true
      this.importVideo = true
    },
    startImportImages() {
      this.upload = true
      this.importImages = true
    },
    cancel() {
      this.$emit('input', false)
      this.$root.$emit('cancelVideoUpload')
      this.$emit('rerender')
      this.form.textContent.value = ''
    },
    cancelUpload() {
      this.importVideo = false
      this.importImages = false
      this.upload = false
      this.images = []
      this.$root.$emit('cancelVideoUpload')
    },
    removeSnippet() {
      this.lastLink = null
      this.snippetData = null
      this.form.linkMetadata.value = ''
    },
    checkLinks() {
      if (this.importVideo || this.importImages) {
        return
      }
      const links = this.form.textContent.value.match(
        /(https?:\/\/[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gm,
      )
      if (links === null) {
        this.removeSnippet()
        return
      }
      let url = null
      for (const link of links) {
        if (!this.blacklistedLinks.includes(link)) {
          url = link
          break
        }
      }
      if (url === null || url === this.lastLink) {
        if (url === null && this.snippetData !== null) {
          this.removeSnippet()
        }
        return
      }
      this.removeSnippet()

      this.lastLinksCount = links.length
      this.snippetLoading = true
      this.lastLink = url
      requestService
        .get(`/snippet/link?url=${url}`)
        .then(response => {
          if (response.success) {
            this.snippetData = response.data
            this.form.linkMetadata.value = response.data.id
          } else {
            this.blacklistedLinks.push(url)
            this.removeSnippet()
          }
          this.snippetLoading = false
        })
        .catch(() => (this.snippetLoading = false))
    },
    cancelSnippet() {
      if (this.snippetData !== null) {
        this.blacklistedLinks.push(this.lastLink)
        this.removeSnippet()
      }
    },
    updateVisibilityChoices(val) {
      this.fillVisibilityChoices(val)
    },
  },
  watch: {
    currentInternalChannel(value) {
      if (typeof value === 'object' && value.uniqueKey) {
        this.form.channel.value = value.uniqueKey
        this.form.video.channel.value = value.uniqueKey
      }
      this.fillVisibilityChoices('free')
    },
    'form.textContent.value'() {
      clearTimeout(this.checkLinksTimeout)
      this.checkLinksTimeout = setTimeout(this.checkLinks, 300)
    },
    $route() {
      this.cancel()
    },
  },
  mounted() {
    if (this.$store.state.account.internalChannels !== undefined) {
      this.internalChannels = this.$store.state.account.internalChannels
    }
    this.autoSelectChannel()
    this.fetchInternalChannels()
    this.$root.$on('channelCreated', () => {
      this.$emit('rerender')
    })
  },
}
</script>

<style scoped></style>
