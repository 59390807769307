<template>
  <div class="mb-10 ml-0">
    <v-row>
      <v-col>
        <div class="text-subtitle-1 mb-4 text-capitalize">
          {{ $t('label.description') }}
        </div>
        <div>{{ organization.description }}</div>
      </v-col>
    </v-row>

    <v-divider class="my-5"></v-divider>

    <v-row>
      <v-col>
        <div class="text-subtitle-1 mb-4">
          {{ $t('channel.channel-information') }}
        </div>
        <v-list dense rounded>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-web</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <a :href="'/@' + organization.uid"
                  >crowdbunker.com/@{{ organization.uid }}</a
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="organization.subscribersCount">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ formatShortNumber(organization.subscribersCount) }}
                {{
                  $tc('organization.subscriber', organization.subscribersCount)
                }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="organization.totalNumberOfVideos > 0">
            <v-list-item-icon>
              <v-icon>mdi-video-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ formatShortNumber(organization.totalNumberOfVideos) }}
                {{ $tc('video.video', organization.totalNumberOfVideos) }}
                <span v-if="organization.totalNumberOfVideosCensored > 0">
                  ({{
                    formatShortNumber(organization.totalNumberOfVideosCensored)
                  }}
                  {{
                    $tc(
                      'censored.censored',
                      organization.totalNumberOfVideosCensored,
                    )
                  }}
                  )</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--<v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-eye-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>33 627 524 vues</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->

          <v-list-item v-if="organization.createdAt">
            <v-list-item-icon>
              <v-icon>mdi-calendar-range-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >{{ $t('organization.active-since') }}
                {{
                  utilsService.formatDateWithLocale(
                    organization.createdAt,
                    false,
                  )
                }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { utilsService } from '@/services/utils.service'
export default {
  name: 'AboutOrganization',
  props: {
    organizationUid: {
      type: String,
      default: null,
      required: false,
    },
    organization: {
      type: Object,
      value: null,
      required: true,
    },
  },
  data() {
    return {
      utilsService: utilsService,
    }
  },
  methods: {
    formatShortNumber(views) {
      return utilsService.formatNumber(views)
    },
  },
}
</script>
<style scoped></style>
