<template>
  <div class="px-1">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
      slider-color="primary"
    >
      <v-tab
        href="#upload-video"
        :disabled="
          !!externalVideoUrl.value && externalVideoUrl.valid && !!platform
        "
      >
        Téléverser une vidéo
      </v-tab>
      <v-tab
        href="#external-video"
        :disabled="videoUploading || videoUploadedSuccess"
      >
        Utiliser une vidéo externe
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item id="upload-video">
        <div class="py-3">
          <v-card
            outlined
            width="100%"
            class="mb-2"
            v-if="videoFileName !== null"
          >
            <v-card-text class="d-flex py-2 align-center">
              <v-progress-circular
                :size="50"
                color="primary"
                :value="uploadVideoProgress"
                class="flex-shrink-0"
                >{{ uploadVideoProgress }}%
              </v-progress-circular>
              <div class="ml-4 flex-grow-1 overflow-hidden">
                <div class="text-truncate">{{ videoFileName }}</div>
                <div class="text-caption" v-if="videoUploading">
                  {{ $tc('video.upload-in-progress') }}
                </div>
                <div
                  class="text-caption success--text"
                  v-else-if="videoUploadedSuccess"
                >
                  {{ $tc('video.successful-upload') }}
                </div>
                <div class="text-caption error--text" v-else>
                  {{ $tc('video.canceled-upload') }}
                </div>
              </div>
              <div class="ml-2 flex-shrink-0">
                <v-btn
                  fab
                  color="red"
                  x-small
                  v-if="videoUploadedSuccess"
                  :title="$tc('label.delete') | capitalize"
                  @click="deleteVideoUpload"
                  depressed
                  class="ml-2"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  fab
                  color="red"
                  x-small
                  :disabled="!videoUploading"
                  :title="$tc('video.cancel-upload')"
                  @click="cancelVideoUpload"
                  depressed
                >
                  <v-icon>mdi-stop</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
          <div
            v-show="!videoUploading && !videoUploadedSuccess"
            id="video-drag-drop"
          ></div>
        </div>
      </v-tab-item>
      <v-tab-item id="external-video">
        <div class="py-3">
          <!-- Show video source platforms (in button form) to allow the user to choose one  -->
          <h2>
            Plateforme Externe :
          </h2>
          <platform-picker
            :value="platform"
            :externalVideoUrl="externalVideoUrl"
            @update:value="$emit('update:platform', $event)"
            @update:externalVideoUrl="$emit('update:externalVideoUrl', $event)"
          ></platform-picker>
          <!-- verification btn to check in the backend if url really exist and is valid -->
          <v-btn
            :disabled="externalVideoUrl.valid || !platform"
            :loading="externalVideoUrl.loading"
            color="primary"
            @click="$emit('checkExternalVideoUrl')"
          >
            Vérifier
          </v-btn>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <div
      v-if="
        videoUploading ||
          videoUploadedSuccess ||
          (externalVideoUrl.value && externalVideoUrl.valid && platform)
      "
    >
      <v-row class="mt-1">
        <v-col cols="12" class="py-0">
          <text-field
            :label="$tc('video.video-title') | capitalize"
            :field="title"
            :autofocus="title.value.length === 0"
            @input="$emit('update:title', title)"
            :counter="title.value.length > 80 ? 100 : undefined"
          ></text-field>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <v-select
            v-model="visibility.value"
            :items="visibilityChoices"
            :label="$tc('live.visibility') | capitalize"
            @input="$emit('update:visibility', visibility)"
          ></v-select>
        </v-col>
        <v-col cols="12" class="py-0">
          <div class="text-caption">
            {{ $tc('video.optional-thumbnail') | capitalize }}
          </div>
          <v-hover v-slot="{ hover }">
            <v-img
              :src="thumbnailPreviewUrl"
              width="150px"
              :aspect-ratio="1280 / 720"
              class="my-2 hand-cursor"
              @click.native="uppyThumbnail.getPlugin('Dashboard').openModal()"
            >
              <v-overlay
                absolute
                :value="hover"
                color="success darken-1"
                opacity="0.8"
              >
                <div
                  v-if="uppyThumbnail !== null"
                  class="text-button d-flex align-center"
                >
                  <v-icon left>mdi-cloud-upload-outline</v-icon>
                  {{ $tc('button.import') }}
                </div>
              </v-overlay>
              <div
                v-if="thumbnailPreviewUrl === null"
                class="d-flex secondary lighten-1 fill-height justify-center align-center"
                style="width: 100%"
              >
                <span v-show="!hover"
                  >{{ ($tc('label.recommended') + ' :') | capitalize }}<br />
                  1280 x 720 px</span
                >
              </div>
            </v-img>
          </v-hover>
        </v-col>
        <!-- Disable aliases for now -->
        <!-- <v-col cols="12" class="py-0">
          <aliases
            :aliases="aliases"
            :fromOrganization="false"
            :small="true"
            @add-alias="addAlias"
            @remove-alias="removeAlias"
          ></aliases>
        </v-col> -->
        <v-col cols="12" class="py-0">
          <textarea-field
            :label="$tc('label.description') | capitalize"
            :hide-details="
              description.value.length < 4800 && !description.errors
            "
            auto-grow
            :counter="description.value.length > 4800 ? 5000 : undefined"
            :field="description"
            @input="$emit('update:description', description)"
            rows="4"
            class="description"
          >
          </textarea-field>
        </v-col>
        <v-col cols="12">
          <a
            href="#"
            class="white--text text-caption"
            @click.prevent="showMoreParameters = !showMoreParameters"
            >{{ 'Paramètres avancés' }} {{ showMoreParameters ? '▲' : '▼' }}</a
          >
        </v-col>
        <v-col cols="12" v-show="showMoreParameters">
          <v-row>
            <v-col cols="12" class="pt-0">
              <import-subtitle
                :captions.sync="captions"
                @delete="deleteSubtitle"
              ></import-subtitle>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="py-0">
              <v-select
                v-model="language.value"
                :items="locales"
                :label="$tc('label.language') | capitalize"
                @input="$emit('update:language', language)"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="categoriesList.length">
            <v-col cols="12" sm="6" class="py-0">
              <v-select
                v-model="selectedCategories.value"
                :items="categoriesList"
                :label="$tc('label.category') | capitalize"
                @input="$emit('update:selectedCategories', selectedCategories)"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="selectedCategories.value === 'films-and-documentaries'">
            <v-col cols="12" class="py-0">
              <div class="text-caption">
                {{ $tc('label.poster') | capitalize }}
              </div>
              <v-hover v-slot="{ hover }">
                <v-img
                  :src="posterPreviewUrl"
                  width="130px"
                  :aspect-ratio="1200 / 1500"
                  class="my-2"
                >
                  <v-overlay
                    absolute
                    :value="hover"
                    color="success darken-1"
                    opacity="0.8"
                  >
                    <v-btn
                      v-if="uppyPoster !== null"
                      text
                      @click="uppyPoster.getPlugin('Dashboard').openModal()"
                    >
                      <v-icon left>mdi-cloud-upload-outline</v-icon>
                      {{ $tc('button.import') }}
                    </v-btn>
                  </v-overlay>
                  <div
                    v-if="posterPreviewUrl === null"
                    class="d-flex secondary lighten-1 fill-height justify-center align-center"
                    style="width: 100%"
                  >
                    <span v-show="!hover"
                      >{{ ($tc('label.recommended') + ' :') | capitalize
                      }}<br />
                      1200 x 1500 px</span
                    >
                  </div>
                </v-img>
              </v-hover>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="py-0">
              <v-select
                v-model="publishedAtChoiceSelected"
                :items="publishedAtChoices"
                :label="$tc('label.release-date') | capitalize"
                @change="formatPublishedAt"
              ></v-select>
            </v-col>
          </v-row>
          <v-row
            v-if="
              ['definePast', 'defineFuture'].includes(publishedAtChoiceSelected)
            "
            class="d-flex"
          >
            <v-col cols="12" sm="6" class="py-0">
              <date-picker
                :label="`${$t('video.release-date')}` | capitalize"
                :field="publishedAtDate"
                @change="formatPublishedAt"
                :max="
                  publishedAtChoiceSelected === 'definePast'
                    ? maxPublishedAtDate
                    : ''
                "
                :min="
                  publishedAtChoiceSelected === 'defineFuture'
                    ? maxPublishedAtDate
                    : ''
                "
              ></date-picker>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <time-picker
                :label="
                  `${$t('video.release-hour')} (${$t('label.optional')})`
                    | capitalize
                "
                :field="publishedAtTime"
                @change="formatPublishedAt"
                :max="
                  publishedAtChoiceSelected === 'definePast'
                    ? maxPublishedAtTime
                    : ''
                "
                :min="
                  publishedAtChoiceSelected === 'defineFuture'
                    ? maxPublishedAtTime
                    : ''
                "
              ></time-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="py-0">
              <v-select
                v-model="videoForm.price.value"
                :items="priceChoices"
                :label="$tc('video.price') | capitalize"
                :hint="!channel.organization.monetizationActive ? 'hint' : ''"
                :persistent-hint="!channel.organization.monetizationActive"
                :disabled="!channel.organization.monetizationActive"
              >
                <template #message
                  ><span style="pointer-events: all!important;">
                    Vous devez d'abord
                    <router-link
                      :to="{
                        name: 'Monetization',
                        params: { id: channel.organization.id },
                      }"
                      target="_blank"
                      >activer la monétisation</router-link
                    >
                    pour vendre des vidéos.
                  </span></template
                >
              </v-select>
            </v-col>
          </v-row>
          <template v-if="videoForm.price.value !== 'free'">
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <text-field
                  :label="$t('video.selling-price') | capitalize"
                  :field="videoForm.sellPrice"
                  type="decimal"
                  prefix="€"
                ></text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <text-field
                  :label="$t('video.rental-price') | capitalize"
                  :field="videoForm.rentPrice"
                  type="decimal"
                  prefix="€"
                  hint="Permet d'accéder 48h à la vidéo. Laissez ce champ vide pour désactiver la location"
                ></text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <v-switch
                  v-model="videoForm.presaleMode.value"
                  label="Activer le mode prévente"
                  hint="Permet de pré-vendre la vidéo avant qu'elle soit disponible, jusqu'à la date ci-dessous ou que vous désactiviez ce mode."
                  persistent-hint
                ></v-switch>
              </v-col>
            </v-row>
            <v-row v-if="videoForm.presaleMode.value" class="d-flex">
              <v-col cols="12" sm="6" class="py-0">
                <date-picker
                  :label="
                    `${$t('video.release-date')} (${$t('label.optional')})`
                      | capitalize
                  "
                  :field="releaseDate"
                  @change="formatReleaseDate"
                ></date-picker>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <time-picker
                  :label="
                    `${$t('video.release-hour')} (${$t('label.optional')})`
                      | capitalize
                  "
                  :field="releaseTime"
                  @change="formatReleaseDate"
                ></time-picker>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-switch
                v-model="retrievable.value"
                :label="$tc('label.retrievable') | capitalize"
                :disabled="videoForm.price.value !== 'free'"
              ></v-switch>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { uppyService } from '@/services/uppy.service'
import TextField from '@/components/form/TextField'
import TextareaField from '@/components/form/TextareaField'
import Validation from '@/components/mixins/Validation'
import DatePicker from '@/components/form/DatePicker'
import TimePicker from '@/components/form/TimePicker'
import ImportSubtitle from '@/components/ImportSubtitle'
// import Aliases from '@/components/Aliases'
import PlatformPicker from '@/components/label/PlatformPicker.vue'

require('@uppy/core/dist/style.min.css')
require('@uppy/drag-drop/dist/style.min.css')

export default {
  name: 'CreateVideo',
  components: {
    TimePicker,
    DatePicker,
    TextareaField,
    TextField,
    ImportSubtitle,
    // Aliases,
    PlatformPicker,
  },
  mixins: [Validation],
  props: {
    title: { type: Object },
    visibility: { type: Object },
    description: { type: Object },
    videoKey: { type: String, default: null },
    thumbnailKey: { type: String, default: null },
    posterKey: { type: String, default: null },
    language: { type: Object },
    categoriesList: { type: Array },
    selectedCategories: { type: Object },
    videoForm: { type: Object },
    channel: { type: Object },
    publishedAt: { type: String, default: null },
    retrievable: { type: Object, default: () => ({ value: false }) },
    visibilityChoices: { type: Array, default: () => [] },
    captions: { type: Array, default: () => [] },
    // No Alias for now
    // aliases: { type: Array, default: () => [] },
    submitted: { type: Boolean, default: false },
    platform: { type: String, default: null },
    externalVideoUrl: {
      type: Object,
      default: () => ({
        value: null,
        valid: false,
        errors: [],
        loading: false,
      }),
    },
  },
  data() {
    return {
      tab: 'upload-video',
      useExternalVideo: false,
      showSubtitleDragAndDrop: false,
      uppyVideo: null,
      uppyThumbnail: null,
      uppyPoster: null,
      videoPreviewUrl: null,
      videoUploadedKey: null,
      uploadedThumbnailKey: null,
      uploadedPosterKey: null,
      uploadVideoProgress: 0,
      videoFileName: null,
      videoUploading: false,
      videoUploadedSuccess: false,
      // videoForm: {
      //   title: {
      //     value: '',
      //   },
      //   visibility: {
      //     value: 'public',
      //   },
      //   description: {
      //     value: '',
      //   },
      //   category: {
      //     value: null,
      //   },
      // },
      thumbnailPreviewUrl: null,
      posterPreviewUrl: null,
      showMoreParameters: false,
      locales: [
        {
          value: '',
          text: this.$options.filters.capitalize(this.$t('label.default')),
        },
        ...this.$store.state.locales,
      ],
      releaseDate: {
        value: null,
      },
      releaseTime: {
        value: null,
      },
      publishedAtChoices: [
        {
          text: this.$options.filters.capitalize(this.$tc('label.now')),
          value: 'now',
        },
        {
          text: this.$options.filters.capitalize(this.$tc('label.past')),
          value: 'definePast',
        },
        {
          text: this.$options.filters.capitalize(this.$tc('label.future')),
          value: 'defineFuture',
        },
      ],
      publishedAtChoiceSelected: 'now',
      publishedAtDate: {
        value: null,
      },
      publishedAtTime: {
        value: null,
      },
    }
  },
  computed: {
    priceChoices() {
      return [
        {
          text: this.$options.filters.capitalize(this.$t('video.free')),
          value: 'free',
        },
        {
          text: this.$options.filters.capitalize(this.$t('video.paid')),
          value: 'paid',
        },
      ]
    },
    maxPublishedAtTime() {
      const today = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000,
      )
        .toISOString()
        .substring(0, 10)
      if (this.publishedAtDate.value === today) {
        return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substring(11, 16)
      }
      return ''
    },
    maxPublishedAtDate() {
      return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10)
    },
  },
  methods: {
    // addAlias(alias) {
    //   this.$emit('update:aliases', [...this.aliases, alias])
    // },
    // removeAlias(alias) {
    //   const tmpAliases = [...this.aliases]
    //   tmpAliases.splice(tmpAliases.indexOf(alias), 1)
    //   this.$emit('update:aliases', tmpAliases)
    // },
    instantiateUppyVideo() {
      this.uppyVideo = uppyService.instantiate({
        id: 'video',
        note: this.$tc('video.maximum-10-go'),
        useDashboard: false,
        useImageEditor: false,
        useDragDrop: true,
        allowedFileTypes: ['video/*'],
        maxFileSize: 10 * 1024 * 1024 * 1024, // max 10 GO
        minFileSize: 100000,
        target: '#video-drag-drop',
        autoProceed: true,
      })
      this.uppyVideo.on('upload-success', file => {
        if (file.s3Multipart) {
          this.videoUploadedKey = file.s3Multipart.key
        } else {
          this.videoUploadedKey = file.meta['key']
        }
        this.$emit('update:videoKey', this.videoUploadedKey)
        this.videoPreviewUrl = file.preview
        this.videoUploading = false
        this.videoUploadedSuccess = true
        if (this.submitted) {
          this.$emit('uploaded')
        }
      })
      this.uppyVideo.on('file-added', file => {
        this.videoFileName = file.data.name
        this.$emit('update:videoKey', null)
        this.$emit('error', null)
      })
      this.uppyVideo.on('upload', () => {
        this.videoUploading = true
      })
      this.uppyVideo.on('progress', data => {
        this.uploadVideoProgress = data
      })
      this.uppyVideo.on('error', error => {
        console.log('error', error)
        this.videoUploading = false
        this.uploadVideoProgress = 0
      })
      this.uppyVideo.on('upload-error', (file, response) => {
        this.videoUploading = false
        console.log('error with file:', file.id)
        const { error } = response
        console.log('error message:', error, response)
        if (error.detail) {
          this.$emit('error', error.detail)
        }
        this.cancelVideoUpload()
      })
    },
    cancelVideoUpload() {
      if (this.uppyVideo === null || this.uppyVideo.cancelAll === undefined) {
        return
      }
      this.uppyVideo.cancelAll()
      this.uploadVideoProgress = 0
      this.videoUploading = false
    },
    deleteVideoUpload() {
      this.$emit('update:videoKey', null)
      this.videoFileName = null
      this.videoUploadedSuccess = false
      this.cancelVideoUpload()
      this.$emit('error', null)
    },
    instantiateUppyThumbnail() {
      this.uppyThumbnail = uppyService.instantiate({
        id: 'thumbnail',
        note: this.$t('upload.image-recommendation', {
          width: 1280,
          height: 720,
        }),
        minCanvasWidth: 200,
        aspectRatio: 1280 / 720,
        thumbnailWidth: 575,
        autoOpenFileEditor: false,
      })
      this.uppyThumbnail.on('file-added', async file => {
        let imgDimension = await getImgDimension(file)
        if (imgDimension.width < 720 || imgDimension.height < 480) {
          this.uppyThumbnail.info(
            this.$t('error.image-minimum-dimension', {
              width: 720,
              height: 480,
            }),
            'error',
            10000,
          )
          setTimeout(() => {
            this.uppyThumbnail.removeFile(file.id)
          }, 20)
        }
        // aspect ratio should be approximately 16:9
        else if (
          imgDimension.width / imgDimension.height < 1.7 ||
          imgDimension.width / imgDimension.height > 1.84
        ) {
          this.uppyThumbnail.getPlugin('Dashboard').openFileEditor(file)
        }
      })

      function getImgDimension(imgFile) {
        let url = URL.createObjectURL(imgFile.data)
        let img = new Image()
        img.src = url
        return new Promise((resolve, reject) => {
          img.onload = () => {
            resolve({ width: img.width, height: img.height })
          }
        })
      }

      this.uppyThumbnail.on('upload-success', file => {
        this.uploadedThumbnailKey = file.meta['key']
        this.$emit('update:thumbnailKey', file.meta['key'])
        this.thumbnailPreviewUrl = file.preview
      })
    },
    instantiateUppyPoster() {
      this.uppyPoster = uppyService.instantiate({
        id: 'thumbnail',
        note: this.$t('upload.image-recommendation', {
          width: 1200,
          height: 1500,
        }),
        minCanvasWidth: 200,
        aspectRatio: 4 / 5,
        thumbnailWidth: 450,
      })
      this.uppyPoster.on('upload-success', file => {
        this.uploadedPosterKey = file.meta['key']
        this.$emit('update:posterKey', file.meta['key'])
        this.posterPreviewUrl = file.preview
      })
    },
    formatReleaseDate() {
      if (!this.releaseDate.value) {
        this.videoForm.releaseDate.value = ''
        return
      }
      this.videoForm.releaseDate.value = `${this.releaseDate.value}T${this
        .releaseTime.value || '00:00'}:00`
    },
    formatPublishedAt() {
      if (
        this.publishedAtChoiceSelected === 'now' ||
        !this.publishedAtDate.value
      ) {
        this.$emit('update:publishedAt', '')
        return
      }
      const publishedAt = `${this.publishedAtDate.value}T${this.publishedAtTime
        .value || '00:00'}:00`
      this.$emit('update:publishedAt', publishedAt)
    },
    deleteSubtitle(index) {
      this.captions.splice(index, 1)
      this.$emit('update:captions', this.captions)
    },
  },
  watch: {
    tab(newTab, lastTab) {
      if (newTab === 'upload-video' && lastTab === 'external-video') {
        this.$emit('update:externalVideoUrl', {
          value: null,
          valid: false,
          errors: [],
          loading: false,
        })
        this.$emit('update:platform', '')
      }
    },
    'selectedCategories.value'() {
      if (
        this.selectedCategories.value === 'films-and-documentaries' &&
        this.uppyPoster === null
      ) {
        this.instantiateUppyPoster()
      }
    },
    channel(channel) {
      if (!channel.organization.monetizationActive) {
        this.videoForm.price.value = 'free'
      }
    },
    'publishedAtDate.value'(newDate) {
      const today = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000,
      )
        .toISOString()
        .substring(0, 10)

      if (newDate === today) {
        this.publishedAtTime.value = ''
      }
    },
    'videoForm.price.value'(value) {
      if (value !== 'free') {
        this.$emit('update:retrievable', { value: false })
      }
      this.$emit('updateVisibility', value)
    },
  },
  mounted() {
    this.instantiateUppyVideo()
    this.instantiateUppyThumbnail()
    this.$root.$on('cancelVideoUpload', () => {
      this.cancelVideoUpload()
    })
  },
}
</script>

<style lang="scss" scoped>
#video-drag-drop {
  color: #adadad;
}

::v-deep {
  .description textarea {
    line-height: 20px;
  }

  .uppy-DragDrop-container {
    background-color: var(--v-secondary-lighten2);
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    color: rgba(255, 255, 255, 0.5);
  }
}

.hand-cursor {
  cursor: pointer;
}
</style>
